import Logo from "../img/Logo1.png";

const HomeFooter = () => {
  return (
    <footer className="section footerwrap">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 footer">
            <a href="/dashboard">
              <img src={Logo} alt=".." />
            </a>

            <ul className="ft-social-icons" style={{ marginLeft: "25px" }}>
              <li>
                <a href="/dashboard">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="/">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href="/">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="/">
                  <i className="fa-brands fa-pinterest-p"></i>
                </a>
              </li>
              <li>
                <a href="/">
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-5 footer">
            <h3>TRENDING CERTIFICATION COURSES</h3>
            <ul className="ft-menu">
              <li>
                <a href="/">DevOps Certification Training Course</a>
              </li>
              <li>
                <a href="/">
                  AWS Certification Training Course for Solutions Architect
                </a>
              </li>
              <li>
                <a href="/">Big Data Hadoop Certification Training Course</a>
              </li>
              <li>
                <a href="/">Table au Certification Training Course</a>
              </li>
              <li>
                <a href="/">Data Science with Python Certification Course</a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 footer">
            <h3>COMPANY</h3>
            <ul className="ft-menu">
              <li>
                <a href="/">About us</a>
              </li>
              <li>
                <a href="/">News & Media</a>
              </li>
              <li>
                <a href="/">Contact us</a>
              </li>
              <li>
                <a href="/">Blog</a>
              </li>
              <li>
                <a href="/">Locations</a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <p className="copyright">
          &copy; <script>document.write(new Date().getFullYear())</script>{" "}
          Tecdemy. All rights Reserved. <a href="/">Terms & Conditions</a>{" "}
          <a href="/">Legal & Privacy</a>
        </p>
      </div>
    </footer>
  );
};

function Homepagefooter() {
  return (
    <div>
      <HomeFooter />
    </div>
  );
}
export default Homepagefooter;
