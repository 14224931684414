import {

  Box,
  Button,
  Container,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Logo from "../../../img/logo.png";
import { IoSearch } from "react-icons/io5";
import { MdOutlineLocationOn } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import SideMenu from "./SideMenu";

const SideBar = ({ children }) => {
  const [state, setState] = useState("");
  return (
    <Container maxW="100% " padding="0px" zIndex={20}>
      <TopMenu state={state} setState={setState} />
      <Container
        maxW="100%"
        padding="0px"
        display="flex"
        flexDirection={{ base: "column", md: "row", lg: "row" }}
        zIndex={0}
      >
        <SideMenu state={state} />
        {children}
      </Container>
    </Container>
  );
};

export default SideBar;

const TopMenu = ({ state, setState }) => {
  return (
    <Container maxW="100%" bgColor="white" padding="0px">
      {/* <Box
        width="100%"
        display={{ base: "", sm: "flex", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Image src={Logo} height="90px" width="250px" />
        <Button colorScheme="purple" variant="outline" marginRight="20px">
          Login with Tecdemy
        </Button>
      </Box> */}
      <Box
        width="100%"
        bgColor="#6D31EDFF"
        height={{ base: "150px", md: "100px" }}
        display="flex"
        flexDirection={{ base: "column", sm: "column", md: "row" }}
        padding="20px"
        paddingLeft="40px"
        alignItems="center"
        gap={{ base: "20px", sm: "30px", md: "40px", lg: "50px", xl: "70px" }}
        zIndex={20}
      >
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
        >
          <Text
            fontSize={{
              base: "30px",
              sm: "30px",
              md: "35px",
              lg: "40px",
              xl: "45px",
            }}
            fontWeight="700"
            color="white"
            marginRight="30px"
          >
            Billing Operations
          </Text>
          <Text fontSize="14px" color="white">
            Career Through Tecdemy 
          </Text>
        </Box>
      </Box>
      <Box width="100%">
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
            xl: "repeat(6, 1fr)",
          }}
          flexWrap="wrap"
        >
          <GridItem w="100%" h="10">
            <Button
              width="100%"
              colorScheme={state == "billing" ? "purple" : "white"}
              color={state == "billing" ? "white" : "black"}
              borderRadius="0px"
              onClick={() => setState("billing")}
            >
              Billing Ops
            </Button>
            {/* <Accordion defaultIndex={[0]} allowMultiple zIndex={15}>
              <AccordionItem zIndex={15}>
                <Text
                  bgColor="#6D31ED66"
                  color="black"
                  margin="0px"
                  textDecoration="none"
                  padding="0px"
                >
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Billing Ops
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </Text>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                  zIndex={15}
                >
                  Pricing
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Process Payment
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Test Transfer
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Orders and Credits
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Subscribers
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Bookings
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Time Bookings
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Cancelled Bookings
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Refunds
                  <IoIosArrowForward />
                </AccordionPanel>
              </AccordionItem>
            </Accordion> */}
          </GridItem>

          <GridItem w="100%" h="10">
            <Button
              width="100%"
              colorScheme={state == "booking" ? "purple" : "white"}
              color={state == "booking" ? "white" : "black"}
              borderRadius="0px"
              onClick={() => setState("booking")}
            >
              Booking Ops
            </Button>
          </GridItem>

          <GridItem w="100%" h="10">
            <Button
              width="100%"
              colorScheme={state == "meeting" ? "purple" : "white"}
              color={state == "meeting" ? "white" : "black"}
              borderRadius="0px"
              onClick={() => setState("meeting")}
            >
              Meeting Ops
            </Button>
          </GridItem>
          <GridItem w="100%" h="10">
            <Button
              width="100%"
              colorScheme={state == "subscribe" ? "purple" : "white"}
              color={state == "subscribe" ? "white" : "black"}
              borderRadius="0px"
              onClick={() => setState("subscribe")}
            >
              Subscriber Ops
            </Button>
          </GridItem>
          <GridItem w="100%" h="10">
            <a href="/hiring">
              <Button
                width="100%"
                colorScheme={state == "placement" ? "purple" : "white"}
                color={state == "placement" ? "white" : "black"}
                borderRadius="0px"
                onClick={() => setState("placement")}
              >
                Jobs and Placement Ops
              </Button>
            </a>
          </GridItem>
          <GridItem w="100%" h="10">
            <Button
              width="100%"
              colorScheme={state == "platform" ? "purple" : "white"}
              color={state == "platform" ? "white" : "black"}
              borderRadius="0px"
              onClick={() => setState("platform")}
            >
              Platform Ops
            </Button>
          </GridItem>
        </Grid>
      </Box>
    </Container>
  );
};
