// auth.js
import * as CryptoJS from "crypto-js";
import { setID } from "../siteConfig";

export const setUserData = (userToken, userData, navigate) => {
  setID('userId', userData?.id)
  if (userToken && userToken != null) {
    var usertoken = encrypt(JSON.stringify(userToken));
    localStorage.setItem("userToken", usertoken);
  }
  if (userData && userData != null) {
    var userdata = encrypt(JSON.stringify(userData));
    localStorage.setItem("userData", userdata);
  }
  if (userData && userData != null && userToken && userToken != null) {
    // var usertoken = encrypt(JSON.stringify(userToken))
    // var userdata = encrypt(JSON.stringify(userData))
    localStorage.setItem("userToken", usertoken);
    localStorage.setItem("userData", userdata);
  }
  setTimeout(() => {
    navigate("/dashboard");

  }, 500);

};

export const clearUserData = () => {
  localStorage.clear();
};

export const getUserData = () => {
  const userToken = localStorage.getItem("userToken");
  const userData = localStorage.getItem("userData");
  // if(userToken &&userToken!=null){
  //   var usertoken = decrypt(userToken)
  //   return { usertoken };
  // }if(userData && userData!=null){
  //   var userdata  =JSON.parse( decrypt(userData))
  //   return { userdata };
  // }
  if (userData && userData != null && userToken && userToken != null) {
    var usertoken = JSON.parse(decrypt(userToken));
    var userdata = JSON.parse(decrypt(userData));
    return { usertoken, userdata };
  } else {
    return false;
  }
};

export const encrypt = (plainText = "") => {
  const cipherText = CryptoJS.AES.encrypt(
    plainText,
    process.env.REACT_APP_App_key
  ).toString();
  return cipherText;
};

export const decrypt = (cipherText = "") => {
  const bytes = CryptoJS.AES.decrypt(cipherText, process.env.REACT_APP_App_key);
  const plainText = bytes.toString(CryptoJS.enc.Utf8);
  return plainText;
};

// export const encrypt = ( plainText="" ) => {
//   let encJson = CryptoJS.AES.encrypt(JSON.stringify(plainText), process.env.REACT_APP_App_key).toString()
//   let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
//   return encData
// }

// export const decrypt = ( cipherText="" ) => {
//   let decData = CryptoJS.enc.Base64.parse(cipherText).toString(CryptoJS.enc.Utf8)
//   const plainText = CryptoJS.AES.decrypt(decData, process.env.REACT_APP_App_key ).toString(CryptoJS.enc.Utf8)
//   return plainText
// }

// export function Encrypt(word, key = 'share') {
//   let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString()
//   let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
//   return encData
// }
// export function Decrypt(word, key = 'share') {
//   let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8)
//   let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8)
//   return JSON.parse(bytes)
// }
