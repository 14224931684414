import React from 'react'
import './JobUserProfile.css'
import img14 from '../../img/img14 jobuserprofile.jpg'
import B1 from '../../img/B1.jpg'
import C1 from '../../img/C1.jpg'
import H1 from '../../img/H1.jpg'
import I1 from '../../img/I1.jpg'
import { FaCopy } from "react-icons/fa6";
import { MdOutlineEdit } from "react-icons/md";
import { RiBarChartFill } from "react-icons/ri";
import { CiUser } from "react-icons/ci";
import { CiStar } from "react-icons/ci";
import { CiSearch } from "react-icons/ci";




function JobUserProfile() {
  return (
    <>
        <div className='main-div-jobuserpro'>
            <div className='jobuserpro-div1'>
                <div style={{background:"#F5F1FEFF", width:"100%", height:"50px", borderRadius:"8px 8px 0px 0px"}}></div>
                <div className='jobuserpro-div1-prodiv'>
                    <img src={img14} width="100px" height="100px" style={{background: "#D3C1FAFF",opacity: "1", overflow: "hidden", borderRadius: "70px"}}></img>
                    <input type='text' className='jobuserpro-div1-username' readOnly value="Jay Rutherford"/>
                    <input type='text' className='jobuserpro-div1-designation' readOnly value="UI / UX Designer"/>
                    <input type='text' className='jobuserpro-div1-about' readOnly value="Incididunt dolore ut aliquip culpa  mollit dolore sint esse non c"/>
                    <div className='jobuserpro-div1-skilldiv'>
                        <input type='text' className='jobuserpro-div1-skill' readOnly value="UX Research"/>
                        <input type='text' className='jobuserpro-div1-skill' readOnly value="UI Design"/>
                        <input type='text' className='jobuserpro-div1-skill' readOnly value="Web Design"/>
                    </div>
                </div>
                <h3 className='jobuserpro-div1-h1'>Portfolio Link</h3>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
                    <div className='jobuserpro-div1-websitelinkdiv'>https://www.profilelink.com <span><FaCopy fill='#171A1FFF' style={{width:"10px", height:"90%"}}/></span></div>
                </div>
                <h3 className='jobuserpro-div1-h1'>Connections</h3>
                <div style={{display:"flex", alignItems:"center", width:"100%", paddingLeft:"30px"}}>
                    <span><img src={B1} style={{width:"30px", height:"30px" , borderRadius:"20px"}}/></span>
                    <span><img src={C1} style={{width:"30px", height:"30px" , borderRadius:"20px"}}/></span>
                    <span><img src={H1} style={{width:"30px", height:"30px" , borderRadius:"20px"}}/></span>
                    <span><img src={I1} style={{width:"30px", height:"30px" , borderRadius:"20px"}}/></span>
                    <span style={{fontFamily: "Manrope", fontSize: '14px', fontWeight: "400", color: "#9095A1FF", paddingLeft:"5px"}}>-50 Connections</span>
                </div>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
                    <button className='jobuserpro-div1-editbtn'><span><MdOutlineEdit fill='#FFFFFFFF' width="10px" height="10px"/></span>Edit</button>
                </div>
            </div>
            <div className='jobuserpro-div2'>
                <div style={{display:"flex", justifyContent:"space-between", flexWrap:"wrap", paddingLeft:"20px", paddingRight:"20px", paddingTop:"20px"}}><span style={{fontFamily: "Manrope", fontSize: "16px", fontWeight: "700", color: "#171A1FFF"}}>Analytics this month</span><span style={{fontFamily: "Manrope", fontSize: "16px", fontWeight: "400", color: "#9095A1FF"}}>Private to you</span></div>
                <div className='jobuserpro-div2-analyticsdiv'>
                    <div className='jobuserpro-div2-analyticsdiv1'>
                        <h4 className='jobuserpro-div2-analytics-h1 ' style={{fontFamily:"Manrope",fontSize:"14px", fontWeight: "700", color: "#171A1FFF", padding:"5px"}}>Profile views</h4>
                        <RiBarChartFill fill=' #6D31EDFF' style={{width:"100%", height:"70%"}}/>
                    </div>
                    <div className='jobuserpro-div2-analyticsdiv1'>
                        <CiUser fill='#6D31EDFF' style={{width:"20px", height:"20px", margin:"5px"}}/>
                        <h4 className='jobuserpro-div2-analytics-h1 ' style={{fontFamily:"Manrope",fontSize:"14px", fontWeight: "700", color: "#171A1FFF", padding:"5px"}}>200 viewrs</h4>
                        <h4 className='jobuserpro-div2-analytics-h1 ' style={{fontFamily:"Manrope",fontSize:"14px", fontWeight: "400", color: " #6F7787FF", padding:"5px"}}>Hiring Managers</h4>
                    </div>
                    <div className='jobuserpro-div2-analyticsdiv1'>
                        <CiStar fill='#FF56A5FF' style={{width:"20px", height:"20px", margin:"5px"}}/>
                        <h4 className='jobuserpro-div2-analytics-h1 ' style={{fontFamily:"Manrope",fontSize:"14px", fontWeight: "700", color: "#171A1FFF", padding:"5px"}}>100 Impressions</h4>
                        <h4 className='jobuserpro-div2-analytics-h1 ' style={{fontFamily:"Manrope",fontSize:"14px", fontWeight: "400", color: " #6F7787FF", padding:"5px"}}>Saved your profile</h4>
                    </div>
                    <div className='jobuserpro-div2-analyticsdiv1'>
                        <CiSearch fill='#7D6600FF' style={{width:"20px", height:"20px", margin:"5px"}}/>
                        <h4 className='jobuserpro-div2-analytics-h1 ' style={{fontFamily:"Manrope",fontSize:"14px", fontWeight: "700", color: "#171A1FFF", padding:"5px"}}>70 Searchs</h4>
                        <h4 className='jobuserpro-div2-analytics-h1 ' style={{fontFamily:"Manrope",fontSize:"14px", fontWeight: "400", color: " #6F7787FF", padding:"5px"}}>Amet eiusmod</h4>
                    </div>
                    
                </div>
            </div>
            <div className='jobuserpro-div3'>
            <div style={{display:"flex", flexWrap:"wrap", paddingLeft:"20px", paddingRight:"20px", paddingTop:"20px"}}><span style={{fontFamily: "Manrope", fontSize: "16px", fontWeight: "700", color: "#171A1FFF"}}>About</span><span style={{fontFamily: "Manrope", fontSize: "14px", fontWeight: "700", color: "#15ABFFFF", background:"#F3F4F6FF", padding:"2px", marginLeft:"10px"}}>Open to work</span></div>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
                    <textarea style={{width:"96.5%" , height:"150px",backgroundColor:"#FFFFFFFF",marginTop:"20px",  borderRadius: '3px' ,borderWidth: '0.5px', borderColor: '#9095A1FF', outline: "none", padding:"10px", fontSize:"14px"}} placeholder="Enter about your profile"></textarea>
                </div>
            </div>
            <div className='jobuserpro-div4'>
                <h4 className='jobuserpro-div4-h1'>Suggested mentors matching you're skills'</h4>
                <div className='jobuserpro-div4-carddiv'>
                    <div className='jobuserpro-div4-card1'>
                        <img src={H1} width="37px" height="37px" style={{background: "#B9E5FFFF", borderRadius: "32px"}}></img>
                        <div className='jobuserpro-div4-card1-namediv'>
                            <h4 className='jobuserpro-div4-card1-name'>John Hill</h4>
                            <h4 className='jobuserpro-div4-card1-position'>Financial Planner</h4>
                        </div>
                    </div>
                    <div className='jobuserpro-div4-card1'>
                        <img src={I1} width="37px" height="37px" style={{background: "#B9E5FFFF", borderRadius: "32px"}}></img>
                        <div className='jobuserpro-div4-card1-namediv'>
                            <h4 className='jobuserpro-div4-card1-name'>Elizabeth Jones</h4>
                            <h4 className='jobuserpro-div4-card1-position'>Copywriter</h4>
                        </div>
                    </div>
                    <div className='jobuserpro-div4-card1'>
                        <img src={C1} width="37px" height="37px" style={{background: "#B9E5FFFF", borderRadius: "32px"}}></img>
                        <div className='jobuserpro-div4-card1-namediv'>
                            <h4 className='jobuserpro-div4-card1-name'>John King</h4>
                            <h4 className='jobuserpro-div4-card1-position'>Political Scientist</h4>
                        </div>
                    </div>
                    <div className='jobuserpro-div4-card1 active'>
                        <img src={B1} width="37px" height="37px" style={{background: "#B9E5FFFF", borderRadius: "32px"}}></img>
                        <div className='jobuserpro-div4-card1-namediv'>
                            <h4 className='jobuserpro-div4-card1-name'>Jessica Hill</h4>
                            <h4 className='jobuserpro-div4-card1-position'>Medical Assistant</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default JobUserProfile