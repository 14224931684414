import {
  Container,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { LuClipboardSignature } from "react-icons/lu";
import { CiCalendar } from "react-icons/ci";
import { chakra } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import "./Bookings.css";
import SearchBar from "../../../components/SearchBar";
import GridExample from "./FilterTable";

const Bookings = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("");
  const [data1, setData1] = useState("");

  const handleFilter = () => {
    let filteredData = [...data1];

    if (searchTerm !== "") {
      filteredData = filteredData.filter(
        (item) =>
          item.goal.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.date.includes(searchTerm) ||
          item.flow.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filterType !== "") {
      filteredData.sort((a, b) => a[filterType].localeCompare(b[filterType]));
    }

    setData1(filteredData);
  };

  const data = Array.from({ length: 115 }, (_, index) => ({
    time: "7:06AM",
    id: 12345678987654,
    description: "React Native - Mobile Development (mm)",
    user: "Kavya098765",
    amount: "$300 USD",
    date: "4/24/2024",
  }));

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage * perPage);
  };

  const [offset, setOffset] = useState(0);
  const [perPage] = useState(7);
  const [pageCount, setPageCount] = useState(Math.ceil(data.length / perPage));

  const paginatedData = data.slice(offset, offset + perPage);

  return (
    <Container maxW="100%" height="100vh" bgColor="white" padding="20px">
      {/* <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      > */}
      {/* <TableContainer>
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filterType={filterType}
          setFilterType={setFilterType}
          filterOptions={[
            { label: "Booking Id" },
            { label: "Course/Program Id" },
            { label: "Student Id" },
            { label: "Price" },
            { label: "Date&Time" },
            // Add more options as needed
          ]}
          onFilter={handleFilter}
        />
        <Table variant="simple">
          <TableCaption>
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </TableCaption>
          <Thead>
            <Tr bgColor="#6D31EDFF" color="white" borderRadius="10px">
              <Th color="white">Booking ID</Th>
              <Th color="white">Course/Program ID</Th>
              <Th color="white">Student ID</Th>
              <Th color="white">Price</Th>
              <Th color="white">Date & Time</Th>
              <Th>
                <CiCalendar size="20px" color="white" />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData.map((item, index) => (
              <motion.tr
                key={index}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, ease: "easeIn" }}
              >
                <Td>{item.id}</Td>
                <Td>{item.description}</Td>
                <Td>{item.user}</Td>
                <Td color="#7D6600FF">{item.amount}</Td>
                <Td>
                  {item.date}, {item.time}
                </Td>
                <Td>
                  <LuClipboardSignature size="20px" />
                </Td>
              </motion.tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer> */}
      <GridExample />
      {/* </motion.div> */}
    </Container>
  );
};

export default Bookings;
