import React, { useState } from "react";
import { Box, Container, GridItem, SimpleGrid } from "@chakra-ui/react";
import CandidateList from "./CandidateList";
import CandidateDetail from "./CandidateDetail";
import candidates from "./CandidateData";

const Hiring = () => {
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const handleSelectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
  };

  return (
    <Container maxW="80%" py={6}>
      <SimpleGrid
        columns={[1, null, 2]}
        spacing={10}
        templateColumns={["1fr", null, "40% 55%"]}
      >
        <GridItem>
          <Box overscrollBehaviorY="contain" height="600px" overflowX="auto">
            <CandidateList
              candidates={candidates}
              onSelectCandidate={handleSelectCandidate}
            />
          </Box>
        </GridItem>
        <GridItem>
          <Box overscrollBehaviorY="contain" height="600px" overflowX="auto">
            <CandidateDetail candidate={selectedCandidate} />
          </Box>
        </GridItem>
      </SimpleGrid>
    </Container>
  );
};

export default Hiring;
