import React from "react";
import { getUserData } from "../../middleware/auth";
import {
  Stack,
  Button,
  Box,
  Container,
  Flex,
  Input,
  Avatar,
  Wrap,
  WrapItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/react";
import { TbPhoneCall } from "react-icons/tb";
import Men1 from "../../img/Male 1 G.svg";
import Men2 from "../../img/Male 2 E.svg";
import Men3 from "../../img/Male 3 E.svg";
import Men4 from "../../img/Male 4 E.svg";
import Men5 from "../../img/Male 5 E.svg";
import Men6 from "../../img/Male 6 G.svg";
import Men7 from "../../img/Male 7 G.svg";
import Female1 from "../../img/Female 1 E.svg";
import Female2 from "../../img/Female 2 G.svg";
import Female3 from "../../img/Female 3 G.svg";
import Female4 from "../../img/Female 4 G.svg";
import UserRatings from "./userRatings";



const Mentors = () => {
  // const userData = getUserData().userdata;
  // const userDataStringified = JSON.parse(userData);
  return (
    <Container maxW="100%" bgColor="white">
      <a
        style={{ marginTop: "10px" }}
        className="nav-link  hide-arrow user-dropdown"
        href=".."
      >
        <div className="avatar">
          <Avatar
            src={Men1}
            className="w-px-50 rounded-circle"
            style={{ height: "60px", marginLeft: "20px", marginBottom: "15px" }}
          />
        </div>

        <div
          className="user-details"
          style={{ marginTop: "12px", marginLeft: "20px" }}
        >
          <p className="user-name">
            {getUserData().userdata.first_name +
              " " +
              getUserData().userdata.last_name}
          </p>
          <span className="user-role">
            {/* {getUserData().userdata.roles} */}
            <p style={{ color: "blueviolet" }}>Learner</p>
            <span style={{ fontSize: "10px" }}>React.js Stage 4</span>
          </span>
        </div>
      </a>
      <div className="Header">
        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab
              _selected={{
                borderBottom: "4px solid #6534e4",
                color: "#6534e4",
                fontWeight: "700",
                borderRadius: "0px",
              }}
              _focus={{ boxShadow: "md" }}
              borderColor="white"
              borderTop="3px solid transparent"
              fontWeight="500"
            >
              MENTORS
            </Tab>
            <Tab
              _selected={{
                borderBottom: "4px solid #6534e4",
                color: "#6534e4",
                fontWeight: "700",
                borderRadius: "0px",
              }}
              _focus={{ boxShadow: "md" }}
              borderColor="white"
              borderTop="3px solid transparent"
              fontWeight="500"
            >
              TRAINER
            </Tab>
            <Tab
              _selected={{
                borderBottom: "4px solid #6534e4",
                color: "#6534e4",
                fontWeight: "700",
                borderRadius: "0px",
              }}
              _focus={{ boxShadow: "md" }}
              borderColor="white"
              borderTop="3px solid transparent"
              fontWeight="500"
            >
              COACH
            </Tab>
            <Tab
              _selected={{
                borderBottom: "4px solid #6534e4",
                color: "#6534e4",
                fontWeight: "700",
                borderRadius: "0px",
              }}
              _focus={{ boxShadow: "md" }}
              borderColor="white"
              borderTop="3px solid transparent"
              fontWeight="500"
            >
              GURU
            </Tab>
          </TabList>
          {/* //Main Box For Both Right and Left  */}
          <TabPanels>
            {/* //First Box Panel */}
            <TabPanel>
              <Wrap>
                {/* //Content in First Box */}
                   <Flex>
                <WrapItem>
                  <Box w="670px" p="10px" flexDirection="column" bg="white">
                    <Box fontWeight="500">
                      <p>Available Mentors</p>
                    </Box>

                    <Wrap align="start" marginTop="15px">
                      <WrapItem>
                        <Avatar name="Dan Abrahmov" src={Men2} />
                      </WrapItem>
                      <WrapItem>
                        <Avatar name="Kola Tioluwani" src={Men3} />
                      </WrapItem>
                      <WrapItem>
                        <Avatar name="Kent Dodds" src={Female1} />
                      </WrapItem>
                      <WrapItem>
                        <Avatar name="Ryan Florence" src={Female2} />
                      </WrapItem>
                      <WrapItem>
                        <Avatar name="Prosper Otemuyiwa" src={Men4} />
                      </WrapItem>
                      <WrapItem>
                        <Avatar name="Christian Nwamba" src={Female4} />
                      </WrapItem>
                      <WrapItem>
                        <Avatar name="Segun Adebayo" src={Men5} />
                      </WrapItem>
                      <WrapItem>
                        <Avatar name="Ryan Florence" src={Female1} />
                      </WrapItem>
                      <WrapItem>
                        <Avatar name="Prosper Otemuyiwa" src={Men6} />
                      </WrapItem>
                      <WrapItem>
                        <Avatar name="Christian Nwamba" src={Female2} />
                      </WrapItem>
                      <WrapItem>
                        <Avatar name="Segun Adebayo" src={Men7} />
                      </WrapItem>
                    </Wrap>

                    {/* //This Flex box for request the Mentors */}

                    <Flex mt="12">
                      <Avatar src={Men7} />
                      <Box display="flex" alignItems="center">
                        <Flex flexDirection="column">
                          <p
                            className="user-name"
                            style={{ marginLeft: "10px" }}
                          >
                            {getUserData().userdata.first_name +
                              " " +
                              getUserData().userdata.last_name}
                          </p>
                          <span
                            className="user-role"
                            style={{ color: "blueviolet", marginLeft: "10px" }}
                          >
                            Expert(React)
                          </span>
                        </Flex>

                        <Button colorScheme="purple" marginLeft="350px">
                          Request
                        </Button>
                        <Box p="10px">
                          <TbPhoneCall boxSize="40px" />
                        </Box>
                      </Box>
                    </Flex>

                    {/* //This Box For Message Or Talk With Mentors */}

                    <Box display="flex" alignItems="center" marginTop="30px">
                      <Avatar src={Female1} />

                      <Box flexDirection="column">
                        <Container
                          w="500px"
                          borderRadius="10px"
                          marginTop="25px"
                          marginLeft="0px"
                          bg="#D3C1FAFF"
                          h="127px"
                        >
                          <span>
                            Hello saw, your request i. Please tell, how can I
                            help you.
                          </span>
                          <span>Available tomorrow at 2:00 IST</span>
                        </Container>
                        <Box>
                          <span style={{ color: "grey", marginLeft: "10px" }}>
                            2 mins ago
                          </span>
                        </Box>
                      </Box>
                    </Box>

                    <Box bg="#F3F4F6FF" w="50%" ml="335px" h="50px" mt="5px">
                      Yes I need your help on Logics
                    </Box>
                    <Box bg="#F3F4F6FF" w="30%" ml="490px" h="50px" mt="15px">
                      Thank you , will join.
                    </Box>
                    <Box bg="#F3F4F6FF" w="20%" ml="535px" h="50px" mt="15px">
                      <i class="far fa-face-grin-beam"></i>
                    </Box>
                    <Box>
                      <span style={{ color: "grey", marginLeft: "533px" }}>
                        Just Now
                      </span>
                    </Box>
                    <Stack spacing={4}>
                      {/* for input field at last of 1st box */}
                      <InputGroup size="md" mt="30px">
                        <InputLeftAddon color="blue">
                          <i class="fas fa-angle-right"></i>
                        </InputLeftAddon>
                        <Input bg="#F3F4F6FF" placeholder="Type a message" />
                        <InputRightAddon>
                          <i class="fas fa-location-arrow"></i>
                        </InputRightAddon>
                      </InputGroup>
                    </Stack>
                  </Box>
                </WrapItem>

                {/* //for right side Content Box */}
                <WrapItem>
                  <Box w="670px" p="10px" flexDirection="column" bg="white">
                    <Input
                      w="492px"
                      ml="75px"
                      marginBottom="20px"
                      placeholder="Search Mentors by categories"
                    />
                    <Container>
                      <UserRatings />
                    </Container>

                    {/* //Paginations // */}

                    <Box mt="10px">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                          <li class="page-item disabled">
                            <a
                              class="page-link"
                              href=".."
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="..">
                              1
                            </a>
                          </li>
                          <li class="page-item active">
                            <a class="page-link" href="..">
                              2 <span class="sr-only">(current)</span>
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="..">
                              3
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="..">
                              ...
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="..">
                              8
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="..">
                              9
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="..">
                              10
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href=".." aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </Box>

                    <Box bg="#FFFFFFFF" w="80%" p={4}>
                      <Flex mt="12">
                        <Avatar src={Men6} />
                        <Box display="flex" alignItems="center">
                          <Flex flexDirection="column">
                            <p className="user-name" marginLeft="10px">
                              UserName{" "}
                              <span style={{ fontWeight: "lighter" }}>
                                {" "}
                                12:00 PM
                              </span>
                            </p>
                            <span
                              className="user-role"
                              style={{ color: "blueviolet", marginLeft: "0px" }}
                            >
                              Nisi quis voluptate esse pariatela
                            </span>
                          </Flex>
                        </Box>
                      </Flex>
                      <Flex mt="12">
                        <Avatar src={Men7} />
                        <Box display="flex" alignItems="center">
                          <Flex flexDirection="column">
                            <p className="user-name" marginLeft="10px">
                              UserName
                              <span style={{ fontWeight: "lighter" }}>
                                {" "}
                                12:00 PM
                              </span>
                            </p>
                            <span
                              className="user-role"
                              style={{ color: "blueviolet", marginLeft: "0px" }}
                            >
                              Nisi quis voluptate esse pariatela
                            </span>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                </WrapItem>
                </Flex>
              </Wrap>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <div></div>
      </div>
    </Container>
  );
};

export default Mentors;
