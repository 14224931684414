import React, { useDebugValue, useEffect, useState } from 'react'
import img1 from '../img/Ellipse 121.png'
// import img2 from '../img/Ellipse 122.png'
import img3 from '../img/user-image-table.png'
import img4 from '../img/Edit.png'
import img5 from '../img/Vector 108.png'
import img6 from '../img/YouTube.png'
import img7 from '../img/Twitter.png'
import img8 from '../img/Arrow 1.png'

import { getID, setID } from "../siteConfig";
import { decrypt, encrypt } from "../middleware/auth";


import {
    Container,
    Image,
    Box,
    Center,
    HStack,
    Button,
    Text,
    Input,
    Textarea,
    Link,
    Badge,
    Grid,
    GridItem
} from '@chakra-ui/react'
import CanvasJSReact from "@canvasjs/react-charts";
import { FACEBOOK_ICON, INSTAGRAM_ICON, LINKEDIN_ICON, TWIITTERX_ICON, YOUTUBE_ICON } from './Config'
import Styles from "./EditProfile.module.css"
import TextArea from 'antd/es/input/TextArea'
import { CallAPI, post } from '../middleware/api'
import endpoints from '../middleware/endpoint'
import { toast } from 'react-toastify'
import { BOLoading } from './elements/elements'
import messageService from './MessageService/Index'
const CanvasJSChart = CanvasJSReact.CanvasJSChart;


export default function Editprofile2() {

    const [readOnly, setReadOnly] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [files, setFiles] = useState([]);
    const formData = new FormData();
    const [showForm, setShowForm] = useState({});
    const [socialLinks, setSocialLinks] = useState({});
    const [loading, setLoading] = useState({})
    const [maxDate, setMaxDate] = useState("");

    useEffect(() => {
        try {
            if (getID('userData')) {
                const userDetails = JSON.parse(decrypt(getID('userData')))
                setUserDetails(userDetails);
                setSocialLinks(userDetails?.socialMediaLinks)
            }
        } catch (error) {
            console.error(error);
        }

    }, [])

    useEffect(() => {
        // Get today's date
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
        const year = today.getFullYear();
        const todayDate = `${year}-${month}-${day}`;

        // Set the max date
        setMaxDate(todayDate);
    }, []);

    const handleActiveEditField = (field, fieldId) => {
        try {
            setReadOnly((prev) => {
                return { ...prev, [field]: !prev[field] }
            })
            const inputField = document.getElementById(fieldId);
            if (inputField) {
                inputField.focus();
                inputField.select();
            }
        } catch (error) {
            console.error(error);
        }
    }



    const handleSubmitUserDetail = (name, value) => {
        try {
            if (name === "first_name" || "bus_email" && name !== 'profile_url') {
                if (value === "") {
                    return toast.error(`${name} is required!`)
                }
            }

            // if (name === "dob") {
            //     const dateRegex = new RegExp('^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$')
            //     if (!dateRegex.test(value.dob)) {
            //         return toast.error(`Please a valid date of birth!`)
            //     }
            // }
            setReadOnly((prev) => {
                return { ...prev, [name]: false }
            })
            setLoading((prev) => {
                return { ...prev, [name]: true }
            })
            post(endpoints?.updateBussUserProfile, {
                uuid: userDetails?.uuid,
                [name]: value,
            })
                .then((res) => {
                    if (name !== 'profile_url') {
                        toast.success(res.data.status.message);
                    }
                    if (getID('userData')) {
                        const userDetails = JSON.parse(decrypt(getID('userData')));
                        const updatedUserDetails = { ...userDetails, [name]: value }
                        const userData = encrypt(JSON.stringify(updatedUserDetails));
                        setID('userData', userData);
                    }
                    messageService.sendMessage("editProfile", { [name]: value }, "profileHeader")

                    setLoading((prev) => {
                        return { ...prev, [name]: false }
                    })
                    if (name === 'profile_url') {
                        setUserDetails((prev) => {
                            return { ...prev, profile_url: "" }
                        })
                    }
                    const { data } = res;
                }).catch(function (error) {
                    toast.error(error.res.data.status.message);
                    setLoading((prev) => {
                        return { ...prev, [name]: false }
                    })

                });
        } catch (error) {
            console.error(error);
        }
    }


    const handleChange = (e) => {
        try {
            const { name, value } = e.target;
            setUserDetails((prev) => {
                return { ...prev, [name]: value }
            })
        } catch (error) {
            console.error(error);
        }
    }


    const handleChangeImage = async (e) => {
        try {
            const { files } = e.target

            const _files = Array.from(files);
            const profileImage = _files.map(file => URL.createObjectURL(file));
            formData.append("files", _files[0], _files[0]?.name);
            formData.append("uuid", userDetails?.uuid);
            setLoading((prev) => {
                return { ...prev, profileImage: true }
            })
            CallAPI(endpoints?.updateProfilePicture, formData)
                .then((res) => {
                    setLoading((prev) => {
                        return { ...prev, profileImage: false }
                    })
                    if (res?.status?.code === 200) {
                        if (getID('userData')) {
                            const userDetails = JSON.parse(decrypt(getID('userData')));
                            const updatedUserDetails = { ...userDetails, profile_url: res?.data?.profile_url }
                            const userData = encrypt(JSON.stringify(updatedUserDetails));
                            setID('userData', userData);
                            setUserDetails((prev) => {
                                return { ...prev, profile_url: res?.data?.profile_url }
                            })
                            messageService.sendMessage("editProfile", { profile_url: res?.data?.profile_url }, "profileHeader")
                        }
                        return;
                    };
                    toast.error(res?.status?.message)
                    // toast.success(res.data.status.message);
                    // setLoading((prev) => {
                    //     return { ...prev, profileImage: false }
                    // })
                    // if (getID('userData')) {
                    //     const userDetails = JSON.parse(decrypt(getID('userData')));
                    //     const updatedUserDetails = { ...userDetails, profile_url: res?.data?.profile_url }
                    //     const userData = encrypt(JSON.stringify(updatedUserDetails));
                    //     setID('userData', userData);
                    //     setUserDetails((prev) => {
                    //         return { ...prev, profile_url: res?.data?.profile_url }
                    //     })
                    // }
                    // setFiles(profileImage);
                }).catch(function (error) {
                    toast.error(error.res.data.status.message);
                    setLoading((prev) => {
                        return { ...prev, profileImage: false }
                    })
                });
        } catch (error) {
            console.error(error);
        }

    }


    const handleCopyClipBoard = (link) => {
        try {
            navigator.clipboard.writeText(link).then(() => {
                toast.success("Link copied to clipboard!")

            })

        } catch (error) {
            console.error(error);
        }
    }

    const handleOpenForm = (socialType, fieldId) => {
        try {
            setShowForm((prev) => {
                return { [socialType]: !prev[socialType] }
            })
            const userDetails = JSON.parse(decrypt(getID('userData')));
            const { socialMediaLinks } = userDetails;
            const socialLink = socialMediaLinks[socialType];
            setSocialLinks((prev) => {
                return { ...prev, [socialType]: socialLink }
            })
            if (fieldId) {
                setTimeout(() => {
                    const inputField = document.getElementById(fieldId);
                    if (inputField) {
                        inputField.focus();
                        inputField.select();
                    }
                }, 500);
            }
        } catch (error) {
            console.error(error);
        }

    }


    const handleUpdateLink = (key, socialLink) => {
        try {
            const reqData = {
                user_id: userDetails?.uuid,
                [key]: socialLink,
            }
            setLoading((prev) => {
                return { ...prev, [key]: true }
            })
            post(endpoints?.addUpdateSocialLinks, reqData)
                .then((res) => {
                    toast.success(res.data.status.message);
                    setLoading((prev) => {
                        return { ...prev, [key]: false }
                    })
                    setShowForm((prev) => {
                        return { ...prev, [key]: false }
                    })
                    setUserDetails((prev) => {
                        return { ...prev, socialMediaLinks: { ...prev.socialMediaLinks, [key]: socialLink } }
                    })
                    if (getID('userData')) {
                        const userDetails = JSON.parse(decrypt(getID('userData')));
                        const updatedUserDetails = { ...userDetails, socialMediaLinks: { ...userDetails?.socialMediaLinks, [key]: socialLink } }
                        const userData = encrypt(JSON.stringify(updatedUserDetails));
                        setID('userData', userData);
                    }
                })
                .catch(function (error) {
                    toast.error(error.response.data.status.message);
                    setLoading((prev) => {
                        return { ...prev, [key]: false }
                    })

                });


        } catch (error) {
            console.error(error);
        }
    }

    const handleChangeLink = (e) => {
        try {
            const { name, value } = e.target;
            setSocialLinks((prev) => {
                return { ...prev, [name]: value }
            })
        } catch (error) {
            console.error(error);
        }

    }

    return (
        <>
            <Container maxW='100%' h="100%" bg='white' display="flex" justifyContent="center" gap={5}>
                <Box w="30%" h="85vh" border="1px solid #ADADAD" borderRadius="10px" marginTop={5}>
                    <Box w="100%" height="50%" borderRadius="10px 10px 0px 0px" className={Styles?.profileImage}>
                        <Image src={img1} alt="" w="100%" h="60%" borderRadius="10px 10px 0px 0px" position="sticky" top="0px" />
                        <Center >
                            {files.length > 0 ? files.map((image) => {
                                return <Image src={image} alt="" w="150px" h="150px" borderRadius={"50%"} position="absolute" top="27%" />
                            }) :
                                <Image src={!!userDetails?.profile_url ? userDetails?.profile_url : img3} alt="" w="150px" h="150px" borderRadius={"50%"} position="absolute" top="27%" />
                            }

                            {!!loading?.profileImage ? <BOLoading /> :
                                <Button height="25px" bg="white" className={Styles?.actionButtons} border="none"
                                    onClick={() => {
                                        const element = document.getElementById('profileInput')
                                        element.click();
                                    }}
                                >
                                    <i class="fas fa-pen"></i>
                                </Button>}
                            {!!userDetails?.profile_url && <Button className={Styles?.actionButtons} height="25px" marginLeft={2} bg="white" border="none"
                                onClick={() => {
                                    handleSubmitUserDetail('profile_url', "");
                                }}
                            >
                                {!!loading?.profile_url ? <BOLoading /> : <i class="fas fa-trash"></i>}
                            </Button>}
                        </Center>
                        <Input opacity={0} type="file" accept='.jpg, .png, .jpeg' name="profilePicutre" id="profileInput" w={0} h={"0px"} onChange={handleChangeImage} />
                    </Box>
                    <Box w="100%" height="50%" borderRadius="10px 10px 0px 0px" display="flex" flexDirection="column" alignItems="center">
                        <HStack w="95%" height="auto" m={2} display="flex" justifyContent="space-between">
                            <Button border="1px solid #ADADAD" height="30px" borderRadius="5px" bg="white" >
                                <Text fontFamily="Manrope" fontWeight="600" color="#000000">First Name</Text>
                            </Button>
                            <Box w="65%" height="30px" display="flex" borderRadius="5px" border="1px solid #ADADAD" justifyContent="space-between" alignItems="center" >
                                <Input
                                    value={userDetails?.first_name}
                                    name='first_name'
                                    readOnly={!!!readOnly['first_name']}
                                    height="30px"
                                    w="95%"
                                    border="none"
                                    color="#000000"
                                    outline="none"
                                    fontFamily="Manrope"
                                    fontWeight="400"
                                    onChange={handleChange}
                                    id="firstNameId"
                                />

                                {loading['first_name'] ?
                                    <Button height="25px" bg="white" border="none" >
                                        <BOLoading />
                                    </Button>

                                    : !!!readOnly['first_name'] ?

                                        <Button height="25px" bg="white" border="none" onClick={() => {
                                            handleActiveEditField('first_name', "firstNameId")
                                        }}>
                                            <i class="fas fa-pen"></i>
                                        </Button>
                                        :

                                        <Button height="25px" bg="white" border="none" onClick={() => {
                                            handleSubmitUserDetail('first_name', userDetails?.first_name)
                                        }}>
                                            <i class="fas fa-check"></i>
                                        </Button>
                                }


                            </Box>
                        </HStack>
                        <HStack w="95%" height="auto" m={2} display="flex" justifyContent="space-between">
                            <Button border="1px solid #ADADAD" height="30px" borderRadius="5px" bg="white" >
                                <Text fontFamily="Manrope" fontWeight="600" color="#000000">Last Name</Text>
                            </Button>
                            <Box w="65%" height="30px" display="flex" borderRadius="5px" border="1px solid #ADADAD" justifyContent="space-between" alignItems="center" >
                                <Input
                                    value={userDetails?.last_name}
                                    readOnly={!!!readOnly['last_name']}
                                    id='lastNameId'
                                    height="30px" w="95%" border="none" name='last_name' color="#000000" outline="none" fontFamily="Manrope" fontWeight="400"
                                    onChange={handleChange}
                                />

                                {loading['last_name'] ?
                                    <Button height="25px" bg="white" border="none" >
                                        <BOLoading />
                                    </Button>

                                    : !!!readOnly['last_name'] ?

                                        <Button height="25px" bg="white" border="none" onClick={() => {
                                            handleActiveEditField('last_name', 'lastNameId')
                                        }}>
                                            <i class="fas fa-pen"></i>
                                        </Button>
                                        :

                                        <Button height="25px" bg="white" border="none" onClick={() => {
                                            handleSubmitUserDetail('last_name', userDetails?.last_name)
                                        }}>
                                            <i class="fas fa-check"></i>
                                        </Button>
                                }
                            </Box>
                        </HStack>
                        <HStack w="95%" height="auto" m={2} display="flex" justifyContent="space-between">
                            <Button border="1px solid #ADADAD" height="30px" borderRadius="5px" bg="white" >
                                <Text fontFamily="Manrope" fontWeight="600" color="#000000">Date of Birth</Text>
                            </Button>
                            <Box w="65%" height="30px" display="flex" borderRadius="5px" border="1px solid #ADADAD" justifyContent="space-between" alignItems="center" >
                                <Input
                                    value={userDetails?.dob}
                                    readOnly={!!!readOnly['dob']}
                                    type="date"
                                    height="30px" w="95%" border="none" name='dob' color="#000000" outline="none" fontFamily="Manrope" fontWeight="400"
                                    onChange={handleChange}
                                    id="userDobId"
                                    max={maxDate}
                                />

                                {loading['dob'] ?
                                    <Button height="25px" bg="white" border="none" >
                                        <BOLoading />
                                    </Button>

                                    : !!!readOnly['dob'] ?

                                        <Button height="25px" bg="white" border="none" onClick={() => {
                                            handleActiveEditField('dob', 'userDobId')
                                        }}>
                                            <i class="fas fa-pen"></i>
                                        </Button>
                                        :

                                        <Button height="25px" bg="white" border="none" onClick={() => {
                                            handleSubmitUserDetail('dob', userDetails?.dob)
                                        }}>
                                            <i class="fas fa-check"></i>
                                        </Button>
                                }
                            </Box>
                        </HStack>
                        <HStack w="95%" height="auto" m={2} display="flex" justifyContent="space-between">
                            <Button border="1px solid #ADADAD" height="30px" borderRadius="5px" bg="white" >
                                <Text fontFamily="Manrope" fontWeight="600" color="#000000">Email</Text>
                            </Button>
                            <Box w="65%" height="30px" display="flex" borderRadius="5px" border="1px solid #ADADAD" justifyContent="space-between" alignItems="center" >
                                <Input
                                    value={userDetails?.bus_email}
                                    readOnly={!!!readOnly['bus_email']}

                                    height="30px" w="95%" border="none" name='bus_email' color="#000000" outline="none" fontFamily="Manrope" fontWeight="400"
                                    onChange={handleChange}
                                    id="userEmailId"
                                />

                                {loading['bus_email'] ?
                                    <Button height="25px" bg="white" border="none" >
                                        <BOLoading />
                                    </Button>

                                    : !!!readOnly['bus_email'] ?

                                        <Button height="25px" bg="white" border="none" onClick={() => {
                                            handleActiveEditField('bus_email', 'userEmailId')
                                        }}>
                                            <i class="fas fa-pen"></i>
                                        </Button>
                                        :

                                        <Button height="25px" bg="white" border="none" onClick={() => {
                                            handleSubmitUserDetail('bus_email', userDetails?.bus_email)
                                        }}>
                                            <i class="fas fa-check"></i>
                                        </Button>
                                }
                            </Box>
                        </HStack>
                        <HStack w="95%" height="auto" m={2} display="flex" justifyContent="space-between">
                            <Button border="1px solid #ADADAD" height="30px" borderRadius="5px" bg="white">
                                <Text fontFamily="Manrope" fontWeight="600" color="#000000">Bio</Text>
                            </Button>
                            <Box w="65%" height="auto" display="flex" borderRadius="5px" border="1px solid #ADADAD" justifyContent="space-between" alignItems="center" >
                                <TextArea
                                    value={userDetails?.description}
                                    readOnly={!!!readOnly['description']}

                                    height="30px" w="95%" border="none" name='description' color="#000000" outline="none" fontFamily="Manrope" fontWeight="400"
                                    onChange={handleChange}
                                    id="descriptionId"
                                />

                                {loading['description'] ?
                                    <Button height="25px" bg="white" border="none" >
                                        <BOLoading />
                                    </Button>

                                    : !!!readOnly['description'] ?

                                        <Button height="25px" bg="white" border="none" onClick={() => {
                                            handleActiveEditField('description', 'descriptionId')
                                        }}>
                                            <i class="fas fa-pen"></i>
                                        </Button>
                                        :

                                        <Button height="25px" bg="white" border="none" onClick={() => {
                                            handleSubmitUserDetail('description', userDetails?.description)
                                        }}>
                                            <i class="fas fa-check"></i>
                                        </Button>
                                }
                            </Box>
                        </HStack>
                    </Box>
                </Box>
                <Box w="70%" h="85vh" borderRadius="10px" marginTop={5}>
                    <HStack w="100%" height="auto" display="flex" justifyContent="space-between" >
                        <Text fontFamily="Manrope" fontWeight="700" color="#000000" fontSize="35px" paddingLeft="10px">Profile</Text>
                        <Box w="65%" height="45px" display="flex" border="1px solid #ADADAD" justifyContent="space-evenly" alignItems="center" marginRight="10px">
                            <Text fontFamily="Manrope" fontWeight="600" color="#000000" fontSize="18px" >Ajay Devgn- University Login</Text>
                            <Image boxSize='20px' objectFit='cover' src={img5} alt="" />
                        </Box>
                    </HStack>
                    <HStack w="100%" height="auto" display="flex" justifyContent="space-between" mt="15px">
                        <Box w="33%" height="130px" border="1px solid #ADADAD" borderRadius="5px" marginRight="10px">
                            <Text fontFamily="Manrope" fontWeight="700" color="#6D31ED" fontSize="20px" padding="10px">Training Courses</Text>
                            <Box display="flex" alignContent="center" width="100%" height="auto" >
                                <Box width="auto" height="auto" display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginLeft="10px">
                                    <Center width="40px" height="40px" bg="#6D62E5" borderRadius="50%" d="flex" justifyContent="center" alignItems="center">
                                        <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="20px">12</Text>
                                    </Center>
                                    <Text fontFamily="Manrope" fontWeight="600" color="#000000" fontSize="16px" textAlign="center">Completed</Text>
                                </Box>
                                <Box width="auto" height="auto" display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginLeft="10px">
                                    <Center width="40px" height="40px" bg="#6D62E5" borderRadius="50%" d="flex" justifyContent="center" alignItems="center">
                                        <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="20px">2</Text>
                                    </Center>
                                    <Text fontFamily="Manrope" fontWeight="600" color="#000000" fontSize="16px" textAlign="center">In Progress</Text>
                                </Box>
                            </Box>
                        </Box>
                        <Box w="28%" height="130px" border="1px solid #ADADAD" borderRadius="5px" marginRight="10px">
                            <Text fontFamily="Manrope" fontWeight="700" color="#6D31ED" fontSize="20px" padding="10px">Learning Hours</Text>
                            <Text fontFamily="Manrope" fontWeight="700" color="#323743" fontSize="35px" paddingLeft="10px">62 Hr</Text>
                            <Badge variant='outline' colorScheme='green' marginLeft="10px" fontSize="14px" d="flex">20% <Image src={img8} display="inline-block" width="10px" height="10px"></Image></Badge>
                        </Box>
                        <Box w="33%" height="130px" border="1px solid #ADADAD" borderRadius="5px" marginRight="10px">
                            <Text fontFamily="Manrope" fontWeight="700" color="#6D31ED" fontSize="20px" padding="10px">Subscription</Text>
                            <Text fontFamily="Lexend" fontWeight="500" color="#171A1F" fontSize="16px" paddingLeft="10px">Pay Professor</Text>
                            <Box d="flex" w="100%" justifyContent="space-evenly" alignItems="center">
                                <Text fontFamily="Lexend" fontWeight="700" color="#171A1F" fontSize="30px" display="inline-flex" paddingLeft="10px">$900</Text>
                                <Button size="sm" marginLeft={"10px"} color="#6D31ED" padding="0px 50px" borderRadius="20px" fontSize="16px" fontWeight="500" border="1px solid #CFCFCF" bg="white">Active</Button>
                            </Box>
                        </Box>
                    </HStack>
                    {/* Here Work */}
                    <Box w="100%" height="auto" display="flex" justifyContent="space-between" mt="15px">
                        <Box w="63.5%" height="130px" border="1px solid #ADADAD" borderRadius="5px" marginRight="10px">
                            <Text fontFamily="Manrope" fontWeight="700" color="#6D31ED" fontSize="20px" padding="10px">Achievements and Goals</Text>
                            <Box display="flex" justifyContent="space-evenly" alignContent="center" width="100%" height="auto" >
                                <Box width="70px" height="70px" display="flex" flexDir="column" alignItems="center" justifyContent="center" bg="#6D62E5" borderRadius="50%">
                                    <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="12px" >10 HR</Text>
                                    <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="10px" >Completed</Text>
                                </Box>
                                <Box width="70px" height="70px" display="flex" flexDir="column" alignItems="center" justifyContent="center" bg="#6D62E5" borderRadius="50%">
                                    <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="12px" >10 HR</Text>
                                    <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="10px" >Completed</Text>
                                </Box>
                                <Box width="70px" height="70px" display="flex" flexDir="column" alignItems="center" justifyContent="center" bg="#6D62E5" borderRadius="50%">
                                    <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="12px" >10 HR</Text>
                                    <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="10px" >Completed</Text>
                                </Box>
                                <Box width="70px" height="70px" display="flex" flexDir="column" alignItems="center" justifyContent="center" bg="#6D62E5" borderRadius="50%">
                                    <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="12px" >10 HR</Text>
                                    <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="10px" >Completed</Text>
                                </Box>
                                <Box width="70px" height="70px" display="flex" flexDir="column" alignItems="center" justifyContent="center" bg="#6D62E5" borderRadius="50%">
                                    <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="12px" >10 HR</Text>
                                    <Text fontFamily="Manrope" fontWeight="700" color="#FFFFFF" fontSize="10px" >Completed</Text>
                                </Box>

                            </Box>
                        </Box>
                        <Box w="33%" height="130px" border="1px solid #ADADAD" borderRadius="5px" marginRight="10px">
                            <Text fontFamily="Manrope" fontWeight="700" color="#6D31ED" fontSize="20px" padding="10px">Goals</Text>
                            <Box display="flex" width="100%" height="auto" >
                                <Box width="40%" height="75px" marginLeft="10px">
                                    {/* <CanvasJSChart options={options} width={"100px"} height={"100px"}/> */}
                                </Box>
                                <Box width="40%" height="75px" marginLeft="10px">
                                    <Grid templateColumns='repeat(2, 1fr)' gap={1}>
                                        <GridItem w='100%' h='auto' ><Text fontFamily="Manrope" fontWeight="500" color="#000000" fontSize="14px" padding="2px">Video</Text></GridItem>
                                        <GridItem w='100%' h='auto' ><Text fontFamily="Manrope" fontWeight="500" color="#000000" fontSize="14px" padding="2px">80%</Text></GridItem>
                                    </Grid>
                                    <Grid templateColumns='repeat(2, 1fr)' gap={1}>
                                        <GridItem w='100%' h='auto' ><Text fontFamily="Manrope" fontWeight="500" color="#000000" fontSize="14px" padding="2px">Files</Text></GridItem>
                                        <GridItem w='100%' h='auto' ><Text fontFamily="Manrope" fontWeight="500" color="#000000" fontSize="14px" padding="2px">40%</Text></GridItem>
                                    </Grid>
                                    <Grid templateColumns='repeat(2, 1fr)' gap={1}>
                                        <GridItem w='100%' h='auto' ><Text fontFamily="Manrope" fontWeight="500" color="#000000" fontSize="14px" padding="2px">PDF</Text></GridItem>
                                        <GridItem w='100%' h='auto' ><Text fontFamily="Manrope" fontWeight="500" color="#000000" fontSize="14px" padding="2px">30%</Text></GridItem>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box w="99%" height="auto" mt="15px" border="1px solid #ADADAD" borderRadius="5px" marginRight="10px">
                        <Text fontFamily="Manrope" fontWeight="700" color="#6D31ED" fontSize="20px" padding="10px">Social Connection</Text>
                        <HStack w="80%" mb="10px" className={Styles?.socialIconWrapper}>

                            {<Box className={Styles?.socialLinkWrapper}>
                                <Image src={FACEBOOK_ICON} alt="" w="50px" h="50px" borderRadius="10px 10px 0px 0px" position="sticky" top="0px" />
                                {!!!showForm?.facebook && <Box className={Styles?.socialActionWrapper}>
                                    {!!userDetails?.socialMediaLinks?.facebook &&
                                        <a href={userDetails?.socialMediaLinks?.facebook} target='_blank'><i class="fas fa-link"></i></a>}
                                    <i onClick={() => {
                                        handleOpenForm('facebook', 'facebookLink');
                                    }} class="fas fa-pencil"></i>
                                    {!!userDetails?.socialMediaLinks?.facebook && <i onClick={() => {
                                        handleCopyClipBoard(userDetails?.socialMediaLinks?.facebook);
                                    }} class="far fa-clipboard"></i>}
                                </Box>}
                                {!!showForm?.facebook && <Input type="text" id='facebookLink' onChange={handleChangeLink} value={socialLinks?.facebook} name='facebook' placeholder='Facebook link' />}
                                {loading?.facebook ? <BOLoading /> : !!showForm?.facebook && <i onClick={() => {
                                    handleUpdateLink('facebook', socialLinks?.facebook);
                                }} class="fas fa-check"></i>}
                                {!!showForm?.facebook && <i onClick={() => {
                                    handleOpenForm('facebook');
                                }} class="fas fa-close"></i>}
                            </Box>}


                            {<Box className={Styles?.socialLinkWrapper}>
                                <Image src={YOUTUBE_ICON} alt="" w="50px" h="50px" borderRadius="10px 10px 0px 0px" position="sticky" top="0px" />
                                {!!!showForm?.youtube && <Box className={Styles?.socialActionWrapper}>
                                    {!!userDetails?.socialMediaLinks?.youtube &&
                                        <a href={userDetails?.socialMediaLinks?.youtube} target='_blank'><i class="fas fa-link"></i></a>}
                                    <i onClick={() => {
                                        handleOpenForm('youtube', 'youtubeLink');
                                    }} class="fas fa-pencil"></i>
                                    {!!userDetails?.socialMediaLinks?.youtube && <i onClick={() => {
                                        handleCopyClipBoard(userDetails?.socialMediaLinks?.youtube);
                                    }} class="far fa-clipboard"></i>}
                                </Box>}
                                {!!showForm?.youtube && <Input type="text" id='youtubeLink' onChange={handleChangeLink} value={socialLinks?.youtube} name='youtube' placeholder='YouTube link' />}
                                {loading?.youtube ? <BOLoading /> : !!showForm?.youtube && <i onClick={() => {
                                    handleUpdateLink('youtube', socialLinks?.youtube);
                                }} class="fas fa-check"></i>}
                                {!!showForm?.youtube && <i onClick={() => {
                                    handleOpenForm('youtube');
                                }} class="fas fa-close"></i>}
                            </Box>}

                            {<Box className={Styles?.socialLinkWrapper}>
                                <Image src={TWIITTERX_ICON} alt="" w="50px" h="50px" borderRadius="10px 10px 0px 0px" position="sticky" top="0px" />
                                {!!!showForm?.twitterX && <Box className={Styles?.socialActionWrapper}>
                                    {!!userDetails?.socialMediaLinks?.twitterX &&
                                        <a href={userDetails?.socialMediaLinks?.twitterX} target='_blank'><i class="fas fa-link"></i></a>}
                                    <i onClick={() => {
                                        handleOpenForm('twitterX', 'twitterXLink');
                                    }} class="fas fa-pencil"></i>
                                    {!!userDetails?.socialMediaLinks?.twitterX && <i onClick={() => {
                                        handleCopyClipBoard(userDetails?.socialMediaLinks?.twitterX);
                                    }} class="far fa-clipboard"></i>}
                                </Box>}
                                {!!showForm?.twitterX && <Input type="text" id="twitterXLink" onChange={handleChangeLink} value={socialLinks?.twitterX} name='twitterX' placeholder='TwitterX link' />}
                                {loading?.twitterX ? <BOLoading /> : !!showForm?.twitterX && <i onClick={() => {
                                    handleUpdateLink('twitterX', socialLinks?.twitterX);
                                }} class="fas fa-check"></i>}
                                {!!showForm?.twitterX && <i onClick={() => {
                                    handleOpenForm('twitterX');
                                }} class="fas fa-close"></i>}
                            </Box>}

                            {<Box className={Styles?.socialLinkWrapper}>
                                <Image src={LINKEDIN_ICON} alt="" w="50px" h="50px" borderRadius="10px 10px 0px 0px" position="sticky" top="0px" />
                                {!!!showForm?.linkedin && <Box className={Styles?.socialActionWrapper}>
                                    {!!userDetails?.socialMediaLinks?.linkedin &&
                                        <a href={userDetails?.socialMediaLinks?.linkedin} target='_blank'><i class="fas fa-link"></i></a>}
                                    <i onClick={() => {
                                        handleOpenForm('linkedin', 'linkedinLink');
                                    }} class="fas fa-pencil"></i>
                                    {!!userDetails?.socialMediaLinks?.linkedin && <i onClick={() => {
                                        handleCopyClipBoard(userDetails?.socialMediaLinks?.linkedin);
                                    }} class="far fa-clipboard"></i>}
                                </Box>}
                                {!!showForm?.linkedin && <Input type="text" id='linkedinLink' onChange={handleChangeLink} value={socialLinks?.linkedin} name='linkedin' placeholder='LinkedIn link' />}
                                {loading?.linkedin ? <BOLoading /> : !!showForm?.linkedin && <i onClick={() => {
                                    handleUpdateLink('linkedin', socialLinks?.linkedin);
                                }} class="fas fa-check"></i>}
                                {!!showForm?.linkedin && <i onClick={() => {
                                    handleOpenForm('linkedin');
                                }} class="fas fa-close"></i>}
                            </Box>}



                            <Box className={Styles?.socialLinkWrapper}>
                                <Image src={INSTAGRAM_ICON} alt="" w="50px" h="50px" borderRadius="10px 10px 0px 0px" position="sticky" top="0px" />
                                {!!!showForm?.instagram && <Box className={Styles?.socialActionWrapper}>
                                    {!!userDetails?.socialMediaLinks?.instagram && <a href={userDetails?.socialMediaLinks?.instagram} target='_blank'><i class="fas fa-link"></i></a>}
                                    <i onClick={() => {
                                        handleOpenForm('instagram', 'instagramLink');
                                    }} class="fas fa-pencil"></i>
                                    {!!userDetails?.socialMediaLinks?.instagram && <i onClick={() => {
                                        handleCopyClipBoard(userDetails?.socialMediaLinks?.instagram);
                                    }} class="far fa-clipboard"></i>}

                                </Box>}
                                {!!showForm?.instagram && <Input type="text" id='instagramLink' onChange={handleChangeLink} value={socialLinks?.instagram} name='instagram' placeholder='Instagram link' />}
                                {loading?.instagram ? <BOLoading /> : !!showForm?.instagram && <i onClick={() => {
                                    handleUpdateLink('instagram', socialLinks?.instagram);
                                }} class="fas fa-check"></i>}
                                {!!showForm?.instagram && <i onClick={() => {
                                    handleOpenForm('instagram');
                                }} class="fas fa-close"></i>}
                            </Box>


                        </HStack>
                    </Box>
                </Box>
            </Container>
        </>
    )
}
