import Styles from "./Recycle.module.css";
import {
    ChakraProvider,
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Box,
    Divider,
    Grid,
    Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getID, setID } from "../../siteConfig";
import { decrypt, encrypt } from "../../middleware/auth";
import { COURSE_LINK } from "../Config";
import NoDataFound from "../NoDataFound/NoDataFound";
import { FileIcon, isObjectAllKeysBlank } from "../../util_helper";
import { CallAPI } from "../../middleware/api";
import endpoints from "../../middleware/endpoint";
import { toast } from "react-toastify";
import { BOLoading } from "../elements/elements";
import { ShimmerSectionHeader } from "react-shimmer-effects";

const RecycleBin = (props) => {
    const { setShowRecycleBin, showRecycleBin } = props;
    const btnRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [recycleData, setRecycleData] = useState({});
    const userId = getID('userId') || "";
    const [loading, setLoading] = useState('init');
    const fetchRecycleBinData = () => {
        try {
            CallAPI(endpoints?.getTrashData, {
                user_id: userId
            }).then((res) => {
                setLoading('')
                const { status, data } = res;
                if (status?.code === 200) {
                    setRecycleData(data);
                    return;
                }
                toast.error(status?.message);
            })
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        try {
            if (showRecycleBin) {
                fetchRecycleBinData();
            }
        } catch (error) {
            console.error(error);
        }
    }, [showRecycleBin])


    const handleDelete = (uuid) => {
        try {
            if (!!uuid) {
                setLoading(uuid)
                CallAPI(endpoints?.updateTrashData, {
                    uuid: uuid,
                    status: '0',
                }).then((res) => {
                    toast.success(res?.status?.message)
                    setLoading('')
                    fetchRecycleBinData();
                })
            }
            // if (getID('recycleBin')) {
            //     const recycleBin = JSON.parse(decrypt(getID('recycleBin')));
            //     const resourceLinks = recycleBin?.resourceLinks;
            //     const updateLink = resourceLinks.filter((items, idx) => idx !== indx)
            //     const updatedForSession = { ...recycleBin, resourceLinks: updateLink }
            //     setID('recycleBin', encrypt(JSON.stringify(updatedForSession)))
            //     setRecycleData((prev) => {
            //         return { ...prev, resourceLinks: updateLink }
            //     })
            // }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        console.log('recycleData :>> ', recycleData);
    }, [recycleData])

    return (
        <ChakraProvider>
            <Drawer
                isOpen={showRecycleBin}
                placement="right"
            >
                <DrawerOverlay />
                <DrawerContent className={Styles?.drawerContainer}>
                    <DrawerHeader>Recycle Bin</DrawerHeader>
                    {/* <Divider /> */}

                    {loading == 'init' ?
                        <Box padding={7} w={"100%"}>
                            <ShimmerSectionHeader />
                            <ShimmerSectionHeader />
                            <ShimmerSectionHeader />
                            <ShimmerSectionHeader />

                        </Box>

                        : <DrawerBody>

                            {/* {isObjectAllKeysBlank(recycleData) && <NoDataFound title="No data to restore!" />} */}
                            {recycleData?.resourceLinks?.length > 0 && <Box className={Styles?.sectionContainer}>
                                <Divider />
                                <h6>
                                    Resource Links:
                                </h6>
                                <Box>
                                    <Grid
                                        // templateColumns="repeat(auto-fill, minmax(100px, 1fr))"
                                        gap={3}
                                        display={"flex"}
                                        flexDirection={"column"}

                                    >
                                        {recycleData?.resourceLinks?.map((items, index) => {
                                            const [title, link] = items?.recycle_data.split("~");
                                            return (<Box className={Styles?.linkWidget} borderRadius="md">
                                                <Box className={Styles?.contentContainer}>
                                                    <img width={"35"} src={COURSE_LINK} />
                                                    <Text>{title}</Text>
                                                </Box>


                                                <Box className={Styles?.buttonContainer}>
                                                    <Button title="Restore">
                                                        <i class="fas fa-window-restore"></i>
                                                    </Button>

                                                    <Button title="Delete" onClick={() => {
                                                        handleDelete(items?.uuid);
                                                    }}>
                                                        {loading === items?.uuid ? <BOLoading /> : <i class="fas fa-trash"></i>}
                                                    </Button>
                                                </Box>
                                            </Box>)
                                        })}
                                    </Grid>



                                </Box>

                            </Box>}

                            {recycleData?.comments?.length > 0 && <Box className={Styles?.sectionContainer}>
                                <Divider />
                                <h6>
                                    Comments:
                                </h6>

                                <Box>
                                    <Grid

                                        gap={3}
                                        display={"flex"}
                                        flexDirection={"column"}

                                    >
                                        {recycleData?.comments?.map((items, index) => {
                                            // const [title, link] = items?.recycle_data.split("~");

                                            return (<Box className={Styles?.linkWidget} borderRadius="md">
                                                <Box className={Styles?.contentContainer}>
                                                    <i class="fas fa-comment-dots"></i>
                                                    <Text>{items?.recycle_data}</Text>
                                                </Box>


                                                <Box className={Styles?.buttonContainer}>
                                                    <Button title="Restore">
                                                        <i class="fas fa-window-restore"></i>
                                                    </Button>
                                                    <Button title="Delete" onClick={() => {
                                                        handleDelete(items?.uuid);
                                                    }}>
                                                        <i class="fas fa-trash"></i>
                                                    </Button>
                                                </Box>
                                            </Box>)
                                        })}
                                    </Grid>



                                </Box>

                            </Box>}
                            {recycleData?.resourceFiles?.length > 0 && <Box className={Styles?.sectionContainer}>
                                <Divider />
                                <h6>
                                    Resource Files:
                                </h6>

                                <Box>
                                    <Grid

                                        gap={3}
                                        display={"flex"}
                                        flexDirection={"column"}
                                    >
                                        {recycleData?.resourceFiles?.map((items, index) => {
                                            // const [title, link] = items?.recycle_data.split("~");
                                            const [fileName, url] = items?.recycle_data?.split("~");
                                            return (<Box className={Styles?.linkWidget} borderRadius="md">
                                                <Box className={Styles?.contentContainer}>
                                                    <FileIcon fileName={fileName} size="35px" />
                                                    <Text>{fileName}</Text>
                                                </Box>


                                                <Box className={Styles?.buttonContainer}>
                                                    <Button title="Restore">
                                                        <i class="fas fa-window-restore"></i>
                                                    </Button>
                                                    <Button title="Delete" onClick={() => {
                                                        handleDelete(items?.uuid);
                                                    }}>
                                                        <i class="fas fa-trash"></i>
                                                    </Button>
                                                </Box>
                                            </Box>)
                                        })}
                                    </Grid>



                                </Box>

                            </Box>}
                        </DrawerBody>}

                    <DrawerFooter>
                        {loading === 'init' ? "" : <Button variant="outline" mr={3}
                            onClick={() => {
                                setShowRecycleBin(false)
                            }}
                        >
                            Close
                        </Button>}
                        {/* <Button
                            bgColor="#6e31ec"
                            color="white"

                        >Save</Button> */}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </ChakraProvider>

    )

}

export default RecycleBin;