import { useEffect, useState } from "react";
import UndoPopup from "../UndoPopup/Undo";
import messageService from "../MessageService/Index";
import RecycleBin from "../RecycleBin/RecycleBin";

const Popups = () => {


    //for recycle
    const [showUndoPopup, setShowUndoPopup] = useState({})
    const [showRecycleBin, setShowRecycleBin] = useState(false)

    useEffect(() => {
        try {
            messageService.onMessage().subscribe((m) => {
                if (m?.senderId === "courseDetailPage" && m?.target === "undoPopup") {
                    setShowUndoPopup(m?.text);
                }
                if (m?.senderId === "dashboardHeader" && m?.target === "popup") {
                    setShowRecycleBin(m?.text?.show);
                }

            })
        } catch (error) {
            console.error(error);
        }
    }, [])
    return (
        <>
            <UndoPopup setShowUndoPopup={setShowUndoPopup} showUndoPopup={showUndoPopup} />
            {<RecycleBin
                setShowRecycleBin={setShowRecycleBin}
                showRecycleBin={showRecycleBin}
            />}
        </>

    )
}

export default Popups;