import React, { createRef, useEffect, useRef, useState } from "react";
import { clearUserData } from "../middleware/auth";
import { getUserData } from "../middleware/auth";
import { FaRegListAlt } from "react-icons/fa";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import Arrow from "../components/arrow";
import endpoints from "../middleware/endpoint";
import { Link, useNavigate } from "react-router-dom";
import { PROFILE_MENU, placeHolderImage } from "../components/Config";
import { CallAPI, post } from "../middleware/api";
import { NavLink } from "react-router-dom";
import Style from './customCSS.module.css'
import { BOLoading } from "../components/elements/elements";
// import debounce from 'debounce';
import Styles from "./Dashboard.module.css"
import messageService from "../components/MessageService/Index";
const { v4: uuidv4 } = require('uuid');




function DashboardHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  let userDAta = getUserData()?.usertoken;
  if (!!!userDAta) {
    navigate("/login");
  }
  // Assuming getUserData() returns an object
  // const userData = getUserData().userdata;

  // // Stringify the data
  // const userDataStringified = JSON.parse(userData);

  // console.log(userDataStringified);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const logoutdata = () => {
    clearUserData();
    navigate("/login");
    // window.location.replace("/login");
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      toggleButtonRef.current &&
      !toggleButtonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    try {
      messageService.onMessage().subscribe((m) => {
        if (m.senderId === "editProfile" && m.target === "profileHeader") {
          let object = m.text
          setUserDetails((prev) => {
            return { ...prev, ...object }
          })
        }
      })
    } catch (error) {
      console.error(error);
    }
  }, [])


  useEffect(() => {
    setUserDetails(getUserData()?.userdata)
  }, [])


  // Function to handle changes in the search input

  // Function to perform the search operation
  const performSearch = () => {
    try {
      if (!searchQuery || searchQuery === "") {
        setSearchResults([])
      } else {
        setLoading(true);
        post(endpoints.searchCourses, {
          searchText: searchQuery
        }).then((res) => {
          setSearchResults(res.data.data)
          setLoading(false)
          console.log(res.data)
        })
      }
    } catch (error) {
      console.error(error);
    }
  }



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      performSearch()
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery])



  const handleOpenRecycleBin = () => {
    try {
      messageService.sendMessage('dashboardHeader', { show: true }, 'popup')
    } catch (error) {
      console.error(error);
    }
  }





  return (
    <nav
      className="layout-navbar container-fluid navbar navbar-expand-xl navbar-attached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a className="nav-item nav-link px-0 me-xl-4">
          <i className="bx bx-menu bx-sm"></i>
        </a>
      </div>
      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        {/* <button className="sidebar-expander btn" type="button">
          <i className="bx bx-menu"></i>
        </button> */}

        <div className="navbar-nav align-items-center" style={{ flexDirection: "column" }}>
          <div className="nav-item d-flex align-items-center nav-search-bar">
            <input
              type="text"
              className="form-control border-2px shadow-none ps-1 ps-sm-2"
              placeholder="Search reports and help"
              value={searchQuery} // Bind the input value to the searchQuery state
              onChange={(e) => setSearchQuery(e.target.value)} // Call handleSearchChange function on input change
              style={{ width: "500px", marginRight: "10px" }} // Style for width and margin
            />
            <i className="bx bx-search fs-1 lh-0"></i>
          </div>
          {
            (searchResults.length > 0) ?
              <div className={`${Style?.searchDiv} box-shadow`} id="resultDiv">
                {
                  (loading) ?
                    <>
                      <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <BOLoading />
                      </div>
                    </>
                    :
                    (searchResults) ?
                      searchResults.map((searchedData) => {
                        return (
                          <>
                            <NavLink
                              to={`/coursevideo/${searchedData?.uuid}`}
                              onClick={() => setSearchResults([])}
                            >
                              <div className={Style?.searchSuggestion}>
                                <img src="https://banner2.cleanpng.com/20180425/jrw/kisspng-node-js-javascript-web-application-express-js-comp-5ae0f84e2a4242.1423638015246930701731.jpg" width="30px" height="5px"></img>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                  <p style={{ fontSize: "20px", paddingLeft: "6px", cursor: "pointer" }}>{searchedData.course_title}</p>
                                  <span><p style={{ fontSize: "12px", fontWeight: "bold", paddingLeft: "6px" }}>Publisher-<span style={{ fontSize: "12px", paddingLeft: "5px" }}>{searchedData.userDetails.first_name+" "+searchedData.userDetails.last_name}</span></p></span>
                                </div>
                              </div>
                            </NavLink>
                          </>
                        )
                      }) : "No Data Found"
                }
                <NavLink
                  to={'/Service'}
                >
                  <p style={{ fontSize: "20px", padding: "6px", cursor: "pointer", color: "blue" }}>See More</p>
                </NavLink>
              </div> : ""
          }

        </div>

        <ul className="navbar-nav flex-row align-items-center ms-auto">
          <li className={`nav-item has-border drop-menu`}>
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-icon dropdown-toggle hide-arrow"
                onClick={toggleDropdown}
              >
                <i className="bx bx-dots-vertical-rounded"></i>
              </button>

              <ul className="dropdown-menu dropdown-menu-end show">
                <li>
                  <a
                    className={`dropdown-item ${Styles?.menuLink}`}
                    onClick={() => {
                      navigate("/business");
                    }}
                  >
                    Business
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/courseupload");
                    }}
                    className={`dropdown-item ${Styles?.menuLink}`}
                  // href="/courseupload"
                  // href="/courseupload"
                  >
                    Teach
                  </a>
                </li>
                <li>
                  <a
                    className={`dropdown-item ${Styles?.menuLink}`}
                    onClick={() => {
                      navigate("/mycourse");
                    }}
                  >
                    My Courses
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <Arrow id="course" />
          <li className="nav-item has-border">
            <Arrow id="cart" />
            <a
              onClick={() => {
                navigate("/cart");
              }}
              className="nav-link"
            >
              <i className="bx bx-cart"></i>
            </a>
          </li>
          <li className="nav-item has-border">
            <a className="nav-link has-notification notification-icon">
              <i className="bx bx-bell"></i>
              <span>20</span>
            </a>
          </li>
          <li className="nav-item has-border">
            <a className="nav-link">
              <i className="bx bx-heart"></i>
            </a>
          </li>
          <li className={`nav-item has-border ${Styles?.menuLink}`}>
            <a className="nav-link" onClick={() => {
              handleOpenRecycleBin();
            }}>
              <i className="fas fa-recycle"></i>
            </a>
            <div className={Styles?.recycleList}>
            </div>
          </li>
          <li className="nav-item navbar-dropdown 5-user dropdown">
            <a
              className="nav-link  hide-arrow user-dropdown"
              onClick={toggleDropdown}
              ref={toggleButtonRef}
            >
              <div className="avatar avatar-online">
                <img
                  src={userDetails?.profile_url || placeHolderImage}
                  alt="..."
                  className="w-px-40 h-auto rounded-circle"
                />
              </div>
              <div className="user-details" style={{ cursor: "pointer" }}>
                <p className="user-name">
                  {userDetails?.first_name +
                    " " +
                    userDetails?.last_name}
                </p>
                <span className="user-role">
                  {userDetails?.roles?.toUpperCase()}
                </span>
              </div>
            </a>
            {isOpen && (
              <ul
                className="dropdown-menu dropdown-menu-end show"
                ref={dropdownRef}
              >
                {!userDetails?.roles ? (
                  <>
                    <li>
                      <Link className="dropdown-item" href="/editprofile">
                        <i className="bx bx-user me-2"></i>
                        <span className="align-middle">My Profile</span>
                      </Link>
                    </li>
                  </>
                ) : null}
                {userDetails?.roles === 'admin' && <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem>
                    <h2
                      style={{
                        padding: "0px",
                        marginBottom: "0px",
                        textDecoration: "none",
                        color: "#6d31ed",
                      }}
                    >
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Switch User
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2 >
                    <AccordionPanel textAlign="start"
                      pb={1}
                      cursor="pointer"
                      _hover={{ color: "#6d31ed", backgroundColor: "#f5f5f5" }}
                    >
                      Admin
                    </AccordionPanel>
                    <AccordionPanel
                      textAlign="start"
                      pb={1}
                      cursor="pointer"
                      _hover={{ color: "#6d31ed", backgroundColor: "#f5f5f5" }}
                    >
                      Student
                    </AccordionPanel>
                    <AccordionPanel
                      textAlign="start"
                      pb={1}
                      cursor="pointer"
                      _hover={{ color: "#6d31ed", backgroundColor: "#f5f5f5" }}
                    >
                      Learner
                    </AccordionPanel >
                    <AccordionPanel
                      pb={1}
                      cursor="pointer"
                      _hover={{ color: "#6d31ed", backgroundColor: "#f5f5f5" }}
                    >
                      University
                    </AccordionPanel>
                    <AccordionPanel
                      pb={1}
                      cursor="pointer"
                      _hover={{ color: "#6d31ed", backgroundColor: "#f5f5f5" }}
                    >
                      Mentor
                    </AccordionPanel>
                    <AccordionPanel
                      pb={1}
                      cursor="pointer"
                      _hover={{ color: "#6d31ed", backgroundColor: "#f5f5f5" }}
                    >
                      Department
                    </AccordionPanel>
                  </AccordionItem >
                </Accordion >}

                {/* <li>
                  <a
                    onClick={() => {
                      navigate("/jobandplacement");
                    }}
                    className="dropdown-item"
                  >
                    <i className="bx bx-user me-2"></i>
                    <span
                      className="align-middle"
                      style={{ "&:hover": { color: "#6d31ed" } }}
                    >
                      Home
                    </span>
                  </a>
                </li> */}
                {
                  PROFILE_MENU.map((items, index) => {
                    return <li key={index} style={{ cursor: "pointer" }}>
                      <a
                        className={`dropdown-item ${Styles?.profileMenuLinks}`}
                        onClick={() => {
                          navigate(items?.route);
                          setIsOpen(false);
                        }}
                      >
                        {items?.icon}
                        <span
                          className="align-middle"
                          style={{ "&:hover": { color: "#6d31ed" } }}
                        >
                          {items?.name}
                        </span>
                      </a>
                    </li>
                  })
                }

                <li style={{ cursor: "pointer" }}>
                  <div className="dropdown-divider"></div>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <a className="dropdown-item" onClick={logoutdata}>
                    <i className="bx bx-power-off me-2"></i>
                    <span className="align-middle">Log Out</span>
                  </a>
                </li>
              </ul >
            )}
          </li >
        </ul >
      </div >
    </nav >
  );
}

export default DashboardHeader;