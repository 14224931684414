import pdfIcon from "./img/pdf_file.svg"
import xcelIcon from "./img/xlsx_file.svg"
import docFile from "./img/doc_file.svg"
import pngFile from "./img/png_file.svg"
import zipFile from "./img/zip_file.svg"
import jpgFile from "./img/jpg_file.svg"
import placeHolderFile from "./img/place_holder_file.svg"
import { IoMdCloudDownload } from "react-icons/io";
import { getID, setID } from "./siteConfig"
import { decrypt, encrypt } from "./middleware/auth"
import { CallAPI } from "./middleware/api"
import endpoints from "./middleware/endpoint"

export const FileIcon = ({ fileName, size = "25px" }) => {
    let icon = placeHolderFile;
    const [file, ext] = fileName?.split(".");
    if (ext === "pdf") {
        icon = pdfIcon;
    }
    if (ext === "xlsx") {
        icon = xcelIcon;
    }
    if (ext === "docx") {
        icon = docFile;
    }
    if (ext === "png") {
        icon = pngFile;
    }
    if (ext === "jpg") {
        icon = jpgFile;
    }
    if (ext === "jpeg") {
        icon = jpgFile;
    }
    if (ext === "rar" || ext === "zip") {
        icon = zipFile;
    }
    if (!!icon) {
        return <img src={icon} width={size} title={fileName} />
    }
    return <IoMdCloudDownload color="green" />

}


export const AddToRecycleBin = (keys, value) => {
    try {
        if (!!getID('recycleBin')) {
            const recycleBinData = JSON.parse(decrypt(getID('recycleBin')));
            const existData = recycleBinData[keys];
            const updatedRecycleBin = { [keys]: [...existData, value] }
            setID('recycleBin', encrypt(JSON.stringify(updatedRecycleBin)))
            return;
        }
        const updatedData = { [keys]: [value] }
        setID('recycleBin', encrypt(JSON.stringify(updatedData)))
    } catch (error) {
        console.error(error);
    }

}

export const getFromRecycleBin = (key) => {
    try {
        if (!!getID('recycleBin')) {
            const recycleBinData = JSON.parse(decrypt(getID('recycleBin')));
            return recycleBinData[key] || {}
        }
    } catch (error) {
        console.error(error);
    }
}


export const isObjectAllKeysBlank = (obj) => {
    try {
        return Object.values(obj).every(
            value => value === '' || value === null || value === undefined
        );
    } catch (error) {
        console.error(error);
    }

}



export const AddToTrash = (uuid, key = "", data = "", course_id = "") => {
    try {
        if (!!getID('userId')) {
            const userId = getID('userId');
            CallAPI(endpoints?.addToTrash,
                {
                    "user_id": userId,
                    "course_id": course_id,
                    "recycle_key": key,
                    "description": "",
                    "row_uuid": uuid,
                    "recycle_data": data
                }).then((res) => {
                        console.log('res :>> ', res);
                })
        }
    } catch (error) {
        console.error(error);
    }

}
