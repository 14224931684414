import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  fonts: {
    body: "'Manrope', sans-serif",
    heading: "'Manrope', serif",
    // Add other font styles if necessary
  },
});

export default customTheme;
