import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Grid,
  GridItem,
  Text,
  Flex,
  List,
  ListItem,
  ListIcon,
  Heading,
  Select,
  Checkbox,
} from "@chakra-ui/react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "@chakra-ui/react";
import { GrNext } from "react-icons/gr";
import UploadHeader from "./uploadHeader.js";
import FileUploader from "./FileUplaoder.js";
import AddResources from "./AddResources.js";
import Card from "../Card.js";
import ApiData from "../ApiData.js";
import { useNavigate } from "react-router-dom";
import { MdCheckCircle } from "react-icons/md";
import { RolesList, formList } from "../Config.js";
import Styles from "./courseUploader.module.css";
import AddProfile from "./AddProfile.js";
import AddExperience from "./AddExperience.js";
import { CiEraser } from "react-icons/ci";
import ProfileWidget from "../Profile.js";
import CourseUpload from "./CourseUpload.js";
import { decrypt } from "../../middleware/auth.js";
import { getID } from "../../siteConfig.js";

const CourseUploader = () => {
  const navigate = useNavigate();
  const [selectedForm, setSelectedForm] = useState(1);

  const [fullName, setFullName] = useState("");
  const [userDetails, setUserDetails] = useState({});

  const handleSelectForm = (id) => {
    try {
      setSelectedForm(id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectRole = (e) => {
    try {
      const { checked, value, name } = e.target;
      console.log(checked, value, name);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextForm = () => {
    try {
      setSelectedForm((prev) => prev + 1);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    try {
      if (getID("userData")) {
        const userDetails = JSON.parse(decrypt(getID("userData")));
        setUserDetails(userDetails);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Container maxW="100%" bgColor="white" maxH="100%">
      {/* <Breadcrumb spacing="8px" separator={<GrNext color="gray.500" />}>
        <BreadcrumbItem>
          <BreadcrumbLink
            // href="/"
            style={{
              color: "#6f42c1",
              fontSize: "28px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={()=>{
              navigate("/")
            }} 
          >
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink
            // href="/Service"
            style={{
              color: "#6f42c1",
              fontSize: "28px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={()=>{
              navigate("/Service")
            }}   
          >
            All Courses
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            // href="/courseupload"
            style={{
              color: "#6f42c1",
              fontSize: "28px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={()=>{
              navigate("/courseupload")
            }}          >
            Upload Course
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb> */}

      {/* <Box marginLeft="20px">
        <Text
          fontSize="25px"
          fontWeight="500"
          marginTop="20px"
          marginBottom="20px"
        >
          Uploaded Videos
        </Text>
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          {ApiData.map((value, index) => {
            return (
              <GridItem key={index} marginBottom="30px">
                <Card
                  key={index}
                  imgsrc={value.imgsrc}
                  title={value.title}
                  profile={value.profile}
                  name={value.name}
                />
              </GridItem>
            );
          })}
        </Grid>
      </Box> */}

      <Flex>
        <Box flex="1" p="3">
          {/* form menu */}
          <List spacing={3} p={5}>
            {formList.map((items, idx) => {
              return (
                <ListItem
                  mt={5}
                  key={idx}
                  className={`${Styles?.formMenuList} ${
                    selectedForm === items?.id ? Styles?.activeForm : ""
                  }`}
                  // onClick={() => {
                  //   handleSelectForm(items?.id);
                  // }}
                >
                  <ListIcon
                    className={`${
                      selectedForm === items?.id ? Styles?.activeForm : ""
                    }`}
                    as={MdCheckCircle}
                    color="green.500"
                  />
                  {items?.name}
                </ListItem>
              );
            })}
          </List>
          <Box p="3">
            <ProfileWidget fullName={fullName} userData={userDetails} />
          </Box>
        </Box>
        <Box flex="3" p="3" width={"100%"}>
          {/* forms */}
          {selectedForm === 1 && (
            <AddProfile
              setFullName={setFullName}
              handleNextForm={handleNextForm}
              selectedForm={selectedForm}
              setSelectedForm={setSelectedForm}
            />
          )}
          {selectedForm === 2 && (
            <>
              <AddExperience
                selectedForm={selectedForm}
                setSelectedForm={setSelectedForm}
              />
            </>
          )}
          {selectedForm === 3 && (
            <>
              <CourseUpload
                selectedForm={selectedForm}
                setSelectedForm={setSelectedForm}
              />
            </>
          )}
        </Box>
        <Box flex="2" p="3" className={Styles?.parentRolesDiv}>
          {/* role area */}
          <Box className={Styles?.rolesDiv}>
            <Box p="3">
              <Heading as={"h6"}>Select your role in Tecdemy</Heading>
            </Box>
            <Box p="3">
              <Text>Select Plans</Text>
              <Select placeholder="Select option">
                <option value="admin">Admin</option>
                <option value="student">Student</option>
                <option value="learner">Learner</option>
                <option value="university">University</option>
                <option value="mentor">Mentor</option>
                <option value="department">Department</option>
              </Select>
              <Text className={Styles?.dropDownCaption} mt={1}>
                This below results are displayed based on the plan selected
              </Text>
            </Box>
            <Box p="3">
              <Heading
                className={Styles?.selectChannelTitle}
                textAlign={"left"}
                as={"h6"}
              >
                Select Channels & Apps
              </Heading>
            </Box>
            <Box p="3">
              <List spacing={3}>
                {RolesList.map((items, indx) => {
                  return (
                    <ListItem mb={1} key={indx}>
                      <Checkbox
                        colorScheme="purple"
                        defaultChecked
                        value={items?.name}
                        onChange={handleSelectRole}
                      >
                        {items?.name}
                      </Checkbox>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
            <Box p="3" className={Styles?.rightDivFooter}>
              <i class="fas fa-calendar"></i>
              <Text className={Styles?.dropDownCaption}>
                Schedule availability
              </Text>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default CourseUploader;
