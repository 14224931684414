import React from "react";
import { Container, Box, Grid, GridItem, Text } from "@chakra-ui/react";

import Card from "../Card";
import Arrow from "../arrow";
import Inprogressapi from "./inprogressapi";

const InProgress = () => {
  return (
    <Container maxW="100%" height="100vh" bgColor="white" marginLeft="0px">
      <Grid templateColumns="repeat(4, 1fr)" >
        {Inprogressapi.map((value, index) => {
          return (
            <GridItem key={index} marginBottom="10px">
              <Card
                key={index}
                imgsrc={value.imgsrc}
                title={value.title}
                profile={value.profile}
                name={value.name}
              />
            </GridItem>
          );
        })}
      </Grid>
    </Container>
  );
};

export default InProgress;
