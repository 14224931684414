import { Container } from "react-bootstrap";
import Styles from "./courseUploader.module.css";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CiEraser } from "react-icons/ci";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import TextArea from "antd/es/input/TextArea";
import { useFormik } from "formik";
import { MentorValidation } from "../ValidationSchema";
import { decrypt } from "../../middleware/auth";
import { toast } from "react-toastify";
import { post } from "../../middleware/api";
import endpoints from "../../middleware/endpoint";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getID } from "../../siteConfig";

const AddProfile = (props) => {
  const [editorState, setEditorState] = useState("");
  const { handleNextForm, setFullName, selectedForm, setSelectedForm } = props;
  const [files, setFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const handleChangeTextEditor = (value) => {
    try {
      console.log(value);
    } catch (error) {
      console.log(error);
    }
  };

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: {
        fullName: "",
        phoneNumber: "",
        emailAddress: "",
        profileSummary: "",
        certificates: "",
      },
      validationSchema: MentorValidation,
      onSubmit: (values) => {
        const toSubmitValues = { ...values, images: uploadingFiles };
        console.log("onSubmit :>> ", toSubmitValues);
        // handleNextForm();
      },
    });

  const handleUploadCertificates = (e) => {
    try {
      const _files = Array.from(e.target.files);
      setUploadingFiles(_files);
      const newImages = _files.map((file) => URL.createObjectURL(file));
      setFiles([...files, ...newImages]);
      handleChange(newImages.join(","));
    } catch (error) {
      console.error(error);
    }
  };

  // Now i am uploading the Certificates and sessions in database.

  const UploadCertificates = () => {
    const formData = new FormData();
    for (const file of uploadingFiles) {
      formData.append("files", file);
    }

    if (formData.has("files") === true) {
      post(endpoints.uploadSessions, formData)
        .then((res) => {
          toast.success("Uploaded Successfully");
          setSelectedForm(2);
        })
        .catch((error) => {
          toast.error("Failed to upload");
        });
    } else {
      toast.error("No File Selected");
    }

    setUploadingFiles([]);
  };

  // To show User Profile information

  useEffect(() => {
    try {
      if (getID("userData")) {
        const userDetails = JSON.parse(decrypt(getID("userData")));
        setUserDetails(userDetails);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Container maxW="100%" className={`${Styles?.mainContainer}`}>
      <Box className={`${Styles?.addProfileHeader} ${Styles?.textAlignLeft}`}>
        <Heading as={"h4"}>Start your journey with Tecdemy</Heading>
        <Text>Make sure all the info is correct & start applying</Text>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box className={Styles?.addProfileBody}>
          <Box p="3" className={Styles?.textAlignLeft}>
            <Heading as={"h6"}>Profile Information</Heading>
          </Box>
          <Flex>
            <Box flex="1" p="3" className={Styles?.textAlignLeft}>
              <Text>Full Name</Text>
              <Input
                name="fullName"
                value={userDetails.first_name + " " + userDetails.last_name}
                onChange={(e) => {
                  setFullName(e.target.value);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                placeholder="Enter your full name"
              ></Input>
              {/* {!!errors['fullName'] && !!touched['fullName'] ? <Text className={Styles?.errorField}>{errors['fullName']}</Text> : ""} */}
            </Box>
            <Box flex="1" p="3" className={Styles?.textAlignLeft}>
              <Text>Role</Text>
              <Input
                onBlur={handleBlur}
                name="userRole"
                onChange={handleChange}
                placeholder={
                  userDetails.roles ? userDetails.roles : "Select Option"
                }
                value={userDetails.roles}
              >
                {/* <option value='admin'>Admin</option>
                                <option value='student'>Student</option>
                                <option value='learner'>Learner</option>
                                <option value='university'>University</option>
                                <option value='mentor'>Mentor</option>
                                <option value='department'>Department</option> */}
              </Input>
              {/* {!!errors['userRole'] && !!touched['userRole'] ? <Text className={Styles?.errorField}>{errors['userRole']}</Text> : ""} */}
            </Box>
          </Flex>
          <Flex>
            <Box
              flex="1"
              p="3"
              className={`${Styles?.textAlignLeft} ${Styles?.phonewidth}`}
            >
              <Text>Phone Number</Text>
              {/* <Input
                onBlur={handleBlur}
                onChange={handleChange}
                value={userDetails.mobile_number}
                name="phoneNumber"
                placeholder="Enter your phone number"
              ></Input> */}
              <PhoneInput
                inputStyle={{ width: "100%", height: "40px" }}
                dropdownStyle={{
                  backgroundColor: "#d0c2f7",
                  textAlign: "left",
                }}
                buttonStyle={{ height: "100%", backgroundColor: "#d0c2f7" }}
                country={"us"}
                value={userDetails.mobile_number}
                onChange={handleChange}
              />
              {/* {!!errors['phoneNumber'] && !!touched['phoneNumber'] ? <Text className={Styles?.errorField}>{errors['phoneNumber']}</Text> : ""} */}
            </Box>
            <Box flex="1" p="3" className={Styles?.textAlignLeft}>
              <Text>Email Address</Text>
              <Input
                onBlur={handleBlur}
                onChange={handleChange}
                name="emailAddress"
                placeholder="Enter your email address"
                value={userDetails.bus_email}
              ></Input>
              {/* {!!errors['emailAddress'] && !!touched['emailAddress'] ? <Text className={Styles?.errorField}>{errors['emailAddress']}</Text> : ""} */}
            </Box>
          </Flex>
          <Flex>
            <Box flex="1" p="3" className={Styles?.textAlignLeft}>
              <Text>Profile Summary</Text>

              {/* <RichTextEditorComponent>
                            <p>The Rich Text Editor component is WYSIWYG ("what you see is what you get") editor that provides the best user experience to create and update the content. Users can format their content using standard toolbar commands.</p>
                            <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
                        </RichTextEditorComponent> */}
              <TextArea
                onChange={handleChange}
                onBlur={handleBlur}
                name="profileSummary"
                rows="5"
              ></TextArea>
              {/* {!!errors['profileSummary'] && !!touched['profileSummary'] ? <Text className={Styles?.errorField}>{errors['profileSummary']}</Text> : ""} */}
            </Box>
          </Flex>
        </Box>
        <Box className={Styles?.uploadCertificateArea}>
          <Box p="3" className={Styles?.textAlignLeft}>
            <Heading as={"h6"}>Upload Sample Sessions</Heading>
          </Box>
          <Flex
            p="3"
            className={Styles?.uploadCertificateList}
            flexWrap={"wrap"}
          >
            {files.map((image, index) => {
              return (
                <Box className={Styles?.uploadDivs} key={index}>
                  <img
                    src={image}
                    style={{ width: "100%" }}
                    alt={`upload-${index}`}
                  />
                  <i
                    onClick={() => {
                      setFiles(files.filter((items) => items !== image));
                    }}
                    className={`fas fa-xmark ${Styles?.crossButton}`}
                  ></i>
                </Box>
              );
            })}

            <Box className={Styles?.uploadDivs} flex="1">
              <i class="fas fa-plus"></i>
              <input
                type="file"
                accept=".jpg,.png,.jpeg,.mp4"
                multiple
                value={values["certificates"]}
                name="certificates"
                onChange={handleUploadCertificates}
                onBlur={handleBlur}
                onDrop={handleUploadCertificates}
                className={Styles?.inputCertificates}
              />
            </Box>
          </Flex>
          {!!errors["certificates"] && !!touched["certificates"] ? (
            <Text className={Styles?.errorField}>{errors["certificates"]}</Text>
          ) : (
            ""
          )}
        </Box>
        <Box className={Styles?.addProfileFooter} mt={5} textAlign={"right"}>
          <Button type="submit" onClick={UploadCertificates}>
            Next
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default AddProfile;
