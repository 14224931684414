import * as Yup from 'yup';
import { messageConfig } from './Config/messageConfig';


// Regex patterns
const nameRegex = /^[A-Za-z\s]+$/;
const phoneRegex = /^\+?[0-9]+$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

export const MentorValidation = Yup.object().shape({
  fullName: Yup.string()
    .matches(nameRegex, 'Full name can only contain letters and spaces')
    .required('This field is required!'),
  userRole: Yup.string()
    .matches(nameRegex, 'User role can only contain letters and spaces')
    .required('This field is required!'),
  phoneNumber: Yup.string()
    .matches(phoneRegex, 'Phone number can only contain digits and an optional "+" at the beginning')
    .required('This field is required!'),
  emailAddress: Yup.string()
    .required('This field is required!')
    .email('Please enter a valid email address!'),
  profileSummary: Yup.string().required('This field is required!'),
  // certificates: Yup.string().required('This field is required!'),
});

export const CourseUploadSchema = Yup.object().shape({
  courseTitle: Yup.string()
    .matches(/^[A-Za-z0-9\s]+$/, 'Course title can only contain letters, numbers, and spaces')
    .required('This field is required!'),
});

export const LoginPageValidations = Yup.object().shape({
  userType: Yup.string()
    .required(messageConfig?.msg_field_is_required),
  email: Yup.string()
    .required(messageConfig?.msg_field_is_required)
    .email('Please enter a valid email address!'),
  password: Yup.string()
    // .matches(passwordRegex, 'Password must be at least 6 characters, contain an uppercase letter, a lowercase letter, and a special character')
    .required(messageConfig?.msg_field_is_required),
});

export const SignupValidations = Yup.object().shape({
  firstname: Yup.string().required(messageConfig?.msg_field_is_required),
  // lastname: Yup.string().required(messageConfig?.msg_field_is_required),
  email: Yup.string().matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    'Please enter a valid email address!'
  ).required(messageConfig?.msg_field_is_required),
  password: Yup.string()
    // .min(8, "Password should be greater than 8 characters!")
    // .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    // .matches(/\d/, 'Password must contain at least one number')
    // .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
    // .max(16, "Password should be less than 16 characters!")
    .required(messageConfig?.msg_field_is_required),
  confirmPassword: Yup.string().required(messageConfig?.msg_field_is_required).oneOf([Yup.ref('password'), null], 'Confirm password should match with password!')

})


export const ForgotPasswordValidation = Yup.object().shape({
  resetCode: Yup.string().required(messageConfig?.msg_field_is_required)
    .length(6, "OTP length should be 6 digits"),
  newPassword: Yup.string()
    // .min(8, "Password should be greater than 8 characters!")
    // .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    // .matches(/\d/, 'Password must contain at least one number')
    // .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
    // .max(16, "Password should be less than 16 characters!")
    .required(messageConfig?.msg_field_is_required),
  confirmPassword: Yup.string().required(messageConfig?.msg_field_is_required).oneOf([Yup.ref('newPassword'), null], 'Confirm password should match with password!')
})  
