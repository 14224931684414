import React from "react";
import {
  Box,
  Button,
  Container,
  Input,
  Text,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import Styles from "./courseUploader.module.css";

import CourseSubsection from "./CourseSubsection";

const CourseSection = ({
  sectionDetails,
  onSectionChange,
  onAddSection,
  onDeleteSection,
  onSubsectionChange,
  onAddSubsection,
  onDeleteSubsection,
  onAddResourceLink,
  onDeleteResourceLink,
  onResourceLinkChange,
  onAddExternalUrl,
  onDeleteExternalUrl,
  onExternalUrlChange,
  onExternalResource,
  onhandleChangeVideos,
  setSectionsDetails,
  onResourceTitleChange,
  files,
  setFiles,
}) => {
  return (
    <Container maxW="100%" className={`${Styles?.mainContainer}`}>
      {sectionDetails.map((section, sectionIndex) => (
        <Box
          className={Styles?.addProfileBody}
          marginTop="20px"
          key={sectionIndex}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading as="h4" size="md">
              Section {sectionIndex + 1}
            </Heading>
            <IconButton
              icon={<CloseIcon color="black" />}
              onClick={() => onDeleteSection(sectionIndex)}
              aria-label="Delete Section"
              size="sm"
              colorScheme="white"
            />
          </Box>

          <Box flex="1" className={`${Styles?.flex} ${Styles?.AlignLeft}`}>
            <Text width="40%" display="flex" alignItems="center">
              Title
            </Text>
            <Input
              width="70%"
              name="section_title"
              value={section.title}
              placeholder="Title"
              onChange={(event) => onSectionChange(sectionIndex, event)}
            />
          </Box>

          <CourseSubsection
            sectionIndex={sectionIndex}
            subsections={section.courseList}
            onSubsectionChange={(subsectionIndex, event) =>
              onSubsectionChange(sectionIndex, subsectionIndex, event)
            }
            onAddSubsection={() => onAddSubsection(sectionIndex)}
            onDeleteSubsection={(subsectionIndex) =>
              onDeleteSubsection(sectionIndex, subsectionIndex)
            }
            onAddResourceLink={(subsectionIndex) =>
              onAddResourceLink(sectionIndex, subsectionIndex)
            }
            onDeleteResourceLink={(subsectionIndex, resIndex) =>
              onDeleteResourceLink(sectionIndex, subsectionIndex, resIndex)
            }
            onResourceLinkChange={(subsectionIndex, resIndex, event) =>
              onResourceLinkChange(
                sectionIndex,
                subsectionIndex,
                resIndex,
                event
              )
            }
            // onResourceTitleChange={(subsectionIndex, resIndex, event) =>
            //   onResourceTitleChange(
            //     sectionIndex,
            //     subsectionIndex,
            //     resIndex,
            //     event
            //   )
            // }
            onAddExternalUrl={(subsectionIndex) =>
              onAddExternalUrl(sectionIndex, subsectionIndex)
            }
            onDeleteExternalUrl={(subsectionIndex, urlIndex) =>
              onDeleteExternalUrl(sectionIndex, subsectionIndex, urlIndex)
            }
            onExternalUrlChange={(subsectionIndex, urlIndex, event) =>
              onExternalUrlChange(
                sectionIndex,
                subsectionIndex,
                urlIndex,
                event
              )
            }
            setSectionsDetails={setSectionsDetails}

            // onExternalResource={(subsectionIndex, event) =>
            //   onExternalResource(subsectionIndex, event)
            // }
            // onhandleChangeVideos={(subsectionIndex, event) =>
            //   onhandleChangeVideos(subsectionIndex, event)
            // }
            // onExternalResource={onExternalResource}
            // onhandleChangeVideos={onhandleChangeVideos}
          />
        </Box>
      ))}
      <Button onClick={onAddSection} mt={4} size="sm">
        Add Section
      </Button>
    </Container>
  );
};

export default CourseSection;
