import React from 'react'
import './JobAndPlacementPage1.css'
import './Responsive CSS for jobpage.css'
import { useState } from 'react'

import img1 from '../../img/img1_jobandplacement.jpg'
import img2 from '../../img/img2_jobandplacement.png'
import img3 from '../../img/img3_jobandplacement.png'
import img4 from '../../img/img4_jobandplacement.svg'
import img5 from '../../img/img5_jobandplacement.png'
import img6 from '../../img/img6_jobandplacement.jpg'

import { IoIosAdd } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { CiFileOn } from "react-icons/ci";
import { IoCloseCircleSharp } from "react-icons/io5";
import { FaHtml5 } from "react-icons/fa6";
import { FaNodeJs } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";


function JobAndPlacementPage1() {

// Upload File Handling Functionality

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('Select')

    const handleFileSet = (e) =>{
        const ourFileName = (e.target.files[0])?.name
        if(ourFileName){
            const file = e.target.files[0];
            setSelectedFile(file);
            setFileName(ourFileName)
        }else{
            setSelectedFile(null)
            setFileName('Select')
        }
    }


    // Remove File Functionality

    const RemoveFile = (e) =>{
        setFileName('Select')
        selectedFile(null)
    }








    // Add Professional Skills Functionality.

    const [skills, setSkills] = useState([])

    const AddSkills = ()=>{
        const GivenSkill = document.getElementById('skill_input_box').value
        if(!GivenSkill==""){
            const NewSkillAfterSplit = GivenSkill.split(",")
            setSkills([...skills, ...NewSkillAfterSplit])
            const skilladdingsection = document.getElementById('skilladdingsection').style.display='none'
        }else{
            const skilladdingsection = document.getElementById('skilladdingsection').style.display='none'
        }
    }

    // Function to handle removing a skill by index
    const RemoveSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
    };



    const handleAdd = (e)=>{
        const skilladdingsection = document.getElementById('skilladdingsection').style.display='block'
    }



    return (
        <div className='main_div_JobAndPlacementPage1'>
        {/* **********************div1 Code************************ */}
            <div className='div1_JobAndPlacementPage1'>
                <div className='div1_leftside_JobAndPlacementPage2'>
                    <h4 className='div1_leftside_name'>Full Name</h4>
                    <div className='div1_leftside_enternamediv'>
                        <img src={img1} className='div1_leftside_img1'></img>
                        <input type='text' placeholder='Enter Your Name' className='div1_leftside_input1' />
                    </div>
                    <h3 className='div1_leftside_JobAndPlacementPage1_h1'>Get a Virtual Offer</h3>
                    <h5 className='div1_leftside_JobAndPlacementPage1_h2'>Find how valuable your CV is and what trainings and jobs suits</h5>
                    <h5 className='div1_leftside_JobAndPlacementPage1_h2'>your career growth</h5>
                    <div className='div1-leftside_JobAndPlacementPage1_dropCVdiv'>
                        <div className='JobAndPlacementPage1_dropCVdiv_div1'>
                            <h5 className='JobAndPlacementPage1_dropCVheading' for="fileselect">Drop your CV <span style={{float:"right",cursor:"pointer"}}><IoClose fill='#9095A1FF'/></span></h5>
                            <label className='JobAndPlacementPage1_CVselectingarea'>
                                <input type='file' id='fileselect' style={{display:"none"}} onChange={handleFileSet}/>
                                <span style={{cursor:"pointer"}}><CiFileOn fill='#171A1FFF' style={{width:"30px", height:"30px"}}/></span>
                                <span style={{fontWeight:"bold", fontSize:"13px", color:"#171A1FFF"}}>{fileName}</span>
                                <span style={{cursor:"pointer"}}><IoCloseCircleSharp fill='#DE3B40FF' id='closeFilebtn'/></span>
                            </label>
                            <div className='JobAndPlacementPage1_buttonDiv'>
                                <span style={{float:"right"}}>
                                    <span><button className='JobAndPlacementPage1_clearBtn'>Clear</button></span>
                                    <span><button className='JobAndPlacementPage1_uploadBtn'>Upload</button></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='div1_rightside_JobAndPlacementPage2'>
                    <div className='div1_rightside_JobAndPlacementPage2_div1'>
                        <div className='div1_rightside_JobAndPlacementPage2_card1div'>
                            <span style={{cursor:"pointer"}}><FaHtml5 fill="#9C7F00FF" style={{width:"35px", height:"40px", marginRight:"10px"}}/></span>
                            <div>
                                <h4 style={{fontWeight:"bold", fontSize:"12px", color:"#171A1FFF"}}>Programmer</h4>
                                <h4 style={{fontSize:"12px", color:"#6D31EDFF"}}>$100K-$110K</h4>
                                <h4 style={{fontSize:"12px", color:"#62CD14FF"}}>Mumbai, India</h4>
                            </div>
                        </div>
                        <div className='div1_rightside_JobAndPlacementPage2_card2div'>
                            <span style={{cursor:"pointer"}}><FaNodeJs  fill="#9C7F00FF" style={{width:"35px", height:"40px", marginRight:"10px"}}/></span>
                            <div>
                                <h4 style={{fontWeight:"bold", fontSize:"12px", color:"#171A1FFF"}}>Trainning Node.js</h4>
                                <h4 style={{fontSize:"12px", color:"#6D31EDFF"}}>$100K-$110K</h4>
                                <h4 style={{fontSize:"12px", color:"#62CD14FF"}}>Mumbai, India</h4>
                            </div>
                        </div>
                        <div className='div1_rightside_JobAndPlacementPage2_card3div'>
                            <h3 className='div1_rightside_JobAndPlacementPage2_h1'>Hey Kavya Shree,</h3>
                            <h1 className='div1_rightside_JobAndPlacementPage2_h2'>Offers for you!!</h1>
                        </div>
                    </div>
                    <div className='div1_rightside_JobAndPlacementPage2_div2'>
                        <div className='div1_rightside_JobAndPlacementPage2_card4div'>
                            <span style={{cursor:"pointer"}}><FaHtml5 fill="#9C7F00FF" style={{width:"35px", height:"40px", marginRight:"10px"}}/></span>
                            <div>
                                <h4 style={{fontWeight:"bold", fontSize:"12px", color:"#171A1FFF"}}>Tech-Architect</h4>
                                <h4 style={{fontSize:"12px", color:"#6D31EDFF"}}>$100K-$110K</h4>
                                <h4 style={{fontSize:"12px", color:"#62CD14FF"}}>Mumbai, India</h4>
                            </div>
                        </div>
                        <div className='div1_rightside_JobAndPlacementPage2_card5div'>
                            <span style={{cursor:"pointer"}}><FaNodeJs  fill="#9C7F00FF" style={{width:"35px", height:"40px", marginRight:"10px"}}/></span>
                            <div>
                                <h4 style={{fontWeight:"bold", fontSize:"12px", color:"#171A1FFF"}}>Java Developer</h4>
                                <h4 style={{fontSize:"12px", color:"#6D31EDFF"}}>$120K-$130K</h4>
                                <h4 style={{fontSize:"12px", color:"#62CD14FF"}}>Mumbai, India</h4>
                            </div>
                        </div>
                        {/* <div className='div1_rightside_JobAndPlacementPage2_card6div'>                           
                            <span style={{cursor:"pointer"}}><CiLocationOn  fill="#FFFFFFFF" style={{width:"20px", height:"20px"}}/></span>
                        </div> */}
                    </div>
                    <div className='div1_rightside_JobAndPlacementPage2_div3'>
                        <div className='div1_rightside_JobAndPlacementPage2_card5div'>
                            <span style={{cursor:"pointer"}}><FaNodeJs  fill="#9C7F00FF" style={{width:"35px", height:"40px", marginRight:"10px"}}/></span>
                            <div>
                                <h4 style={{fontWeight:"bold", fontSize:"12px", color:"#171A1FFF"}}>React Developer</h4>
                                <h4 style={{fontSize:"12px", color:"#6D31EDFF"}}>$120K-$130K</h4>
                                <h4 style={{fontSize:"12px", color:"#62CD14FF"}}>Mumbai, India</h4>
                            </div>
                        </div>
                        <div className='div1_rightside_JobAndPlacementPage2_card5div'>
                            <span style={{cursor:"pointer"}}><FaHtml5  fill="#9C7F00FF" style={{width:"35px", height:"40px", marginRight:"10px"}}/></span>
                            <div>
                                <h4 style={{fontWeight:"bold", fontSize:"12px", color:"#171A1FFF"}}>Java Developer</h4>
                                <h4 style={{fontSize:"12px", color:"#6D31EDFF"}}>$120K-$130K</h4>
                                <h4 style={{fontSize:"12px", color:"#62CD14FF"}}>Mumbai, India</h4>
                            </div>
                        </div>
                        <div className='div1_rightside_JobAndPlacementPage2_card9div'>
                            <span style={{cursor:"pointer"}}><FaNodeJs  fill="#9C7F00FF" style={{width:"35px", height:"40px", marginRight:"10px"}}/></span>
                            <div>
                                <h4 style={{fontWeight:"bold", fontSize:"12px", color:"#171A1FFF"}}>Dev Ops</h4>
                                <h4 style={{fontSize:"12px", color:"#6D31EDFF"}}>$100K-$110K</h4>
                                <h4 style={{fontSize:"12px", color:"#62CD14FF"}}>Mumbai, India</h4>
                            </div>
                            <img  src={img6} style={{width:"35px", height:"35px", position:"relative", top:"-50px", right:"-20px", borderRadius:"30px",backgroundColor:"#FFF0AFFF"}}/>
                        </div>
                    </div>
                </div>
            </div>
            {/* ****************************div2 code**************************** */}
            <div className='div2_JobAndPlacementPage1'>
                <div className='div2_leftside_JobAndPlacementPage1'>
                    <div className='div2_leftside_JobAndPlacementPage1_professionalSkillDiv'>
                        <div className='div2_leftside_JobAndPlacementPage1_professionalSkillDiv1'>
                            <h4 className='div2_leftside_JobAndPlacementPage1_h1'>Input your skills and find the matches</h4>
                            <h4 className='div2_leftside_JobAndPlacementPage1_h2'>Professional Skills</h4>
                            <div className='div2_leftside_JobAndPlacementPage1_skilldiv'>
                                {/* <button className='div2_leftside_JobAndPlacementPage1_skill'>UX Research <span style={{float:"right",cursor:"pointer"}}><IoClose fill='#FFFFFFFF' width="25px" height="25px"/></span></button>
                                <button className='div2_leftside_JobAndPlacementPage1_skill'>UI Design <span style={{float:"right",cursor:"pointer"}}><IoClose fill='#FFFFFFFF'/></span></button>
                                <button className='div2_leftside_JobAndPlacementPage1_skill'>Web Design <span style={{float:"right",cursor:"pointer"}}><IoClose fill='#FFFFFFFF'/></span></button>
                                 */}
                                {
                                    skills.map((skill, index)=>{
                                        return(
                                            <>
                                                <button className='div2_leftside_JobAndPlacementPage1_skill'>{skill}<span style={{float:"right",cursor:"pointer"}}><IoClose fill='#FFFFFFFF' onClick={()=>RemoveSkill(index)}/></span></button>
                                            </>
                                        )
                                    })
                                }
                                <button className='div2_leftside_JobAndPlacementPage1_AddBtn' onClick={handleAdd}><span style={{float:"right",cursor:"pointer"}}><IoIosAdd fill='#6D31EDFF'/></span> Add</button>
                                <div id='skilladdingsection' ><input type="text" id='skill_input_box' placeholder='Enter Skill here' style={{backgroundColor:"transparent", border:"1px solid lightgray", outline:"none"}}></input><button style={{backgroundColor:"#6D31EDFF", border:"none", color:'white', padding:"0px 2px", marginLeft:"2px"}} onClick={AddSkills}>✔</button></div>
                            </div>
                        </div>
                        <div className='div2_leftside_JobAndPlacementPage1_professionalSkillDiv2'>
                            <img src={img2} style={{width:"100%", height:"100%"}}></img>
                        </div>
                        <div className='div2_leftside_JobAndPlacementPage1_professionalSkillDiv3'>
                                <span style={{cursor:"pointer"}}><CiFileOn fill='#9C7F00FF' style={{width:"35px", height:"50px"}}/></span>
                                <div>
                                    <h4 style={{fontWeight:"bold", fontSize:"12px", color:"#171A1FFF"}}>See your Score Card</h4>
                                    <h4 style={{fontSize:"12px", color:"#6D31EDFF"}}>Kavya Shree  UI/UX.CV</h4>
                                </div>
                        </div>
                    </div>

                    <div className='div2_leftside_JobAndPlacementPage1_descdiv'>
                        <h4 className='div2_leftside_JobAndPlacementPage1_h2'>Description</h4>
                        <textarea className='div2_leftside_JobAndPlacementPage1_desctextarea' placeholder='Enter your description'></textarea>
                        <button className='div1_rightside_checkEligibilityBtn'>Check your Eligibility</button>
                    </div>
                </div>

                <div className='div2_rightside_JobAndPlacementPage1'>
                    <div className='div2_rightside_JobAndPlacementPage1_div1'>
                        <img src={img3} style={{width:"60px", height:"60px", borderRadius: "48px"}}></img>
                        <img src={img4} style={{width:"30px", height:"30px", borderRadius: "48px", position: "relative", top: "-18px"}}></img>
                        <h4 className='div2_rightside_JobAndPlacementPage1_h1'>You have got a feedback from top mentors</h4>
                        <h4 className='div2_rightside_JobAndPlacementPage1_h2'>Learn and take the guidence from the top mentors of tecdemy</h4>
                        <button className='div1_rightside_viewFeedbackBtn'>View Feedback</button>
                    </div>
                    <div className='div2_rightside_JobAndPlacementPage1_div2'>
                        <div className='div2_rightside_JobAndPlacementPage1_cartoondiv'>
                            <img src={img5} style={{width:"100%", height:"100%"}}></img>
                        </div>
                        <div className='div2_rightside_JobAndPlacementPage1_getstartedbtndiv'>
                            <h4 className='div2_rightside_JobAndPlacementPage1_getstarted_h4'>Get career guidence and Tech support from our Mentors/Guru/Trainers</h4>
                            <center><button className='div1_rightside_getStartedBtn'>Get Started</button></center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobAndPlacementPage1