import React from "react";
import {
  Box,
  Avatar,
  Text,
  VStack,
  HStack,
  Badge,
  AvatarGroup,
} from "@chakra-ui/react";
import { IoBag } from "react-icons/io5";
import { BiCheckCircle } from "react-icons/bi";

const CandidateDetail = ({ candidate }) => {
  // if (!candidate) {
  //   return <Text>Select a candidate to see their details.</Text>;
  // }

  return (
    candidate && (
      <Box p={4} borderWidth="1px" borderRadius="md">
        <VStack spacing={4}>
          <Box width="100%" bg="#F5F1FEFF" height="100px" textAlign="center">
            <Avatar
              size="xl"
              src={candidate.profilePicture}
              position="relative"
              top="40px"
            />
          </Box>
          <VStack align="start" textAlign="center" marginTop="20px">
            <Text
              fontSize="24px"
              fontWeight="bold"
              alignSelf="center"
              color="#6D31EDFF"
            >
              {candidate.name}
            </Text>
            <Text alignSelf="center" fontSize="14px">
              {candidate.title}
            </Text>
            <Text alignSelf="center">{candidate.location}</Text>
            <Text alignSelf="center">{candidate.experience} experience</Text>
            <HStack spacing={2} wrap="wrap">
              {candidate.skills.map((skill, index) => (
                <Badge key={index}>{skill}</Badge>
              ))}
            </HStack>
          </VStack>
        </VStack>

        <Box mt={4}>
          <Text fontSize="lg" fontWeight="bold" display="flex">
            About
            <Badge
              fontSize="10px"
              color="#15ABFFFF"
              borderRadius="10px"
              marginLeft="10px"
              display="flex"
              alignItems="center"
              height="20px"
              width="100px"
            >
              <IoBag />
              Open for work
            </Badge>
          </Text>
          <Text>{candidate.description}</Text>
        </Box>
        <Box mt={4}>
          <Text fontSize="lg" fontWeight="bold">
            Working Experience
          </Text>
          <VStack align="start">
            {candidate.workingExperience.map((experience, index) => (
              <Box
                key={index}
                //   borderWidth="1px"
                borderRadius="md"
                p={2}
                w="full"
                display="flex"
                alignItems="center"
                gap="20px"
                marginTop="20px"
              >
                <Avatar src="" />
                <Box>
                  <Text fontWeight="bold" display="flex">
                    {experience.role}{" "}
                    <Badge
                      fontSize="10px"
                      color="#15ABFFFF"
                      borderRadius="10px"
                      marginLeft="10px"
                      display="flex"
                      alignItems="center"
                      height="20px"
                      // width="100px"
                    >
                      working
                    </Badge>
                  </Text>
                  <Box display="flex" justifyContent="space-between">
                    <Text color="#9095A1FF">
                      Fulltime &nbsp; | &nbsp; {experience.company} &nbsp; |
                      &nbsp;
                      {experience.duration}
                    </Text>
                    <AvatarGroup size="md" max={2} cursor="pointer">
                      <Avatar
                        name="Ryan Florence"
                        src="https://bit.ly/ryan-florence"
                      />
                      <Avatar
                        name="Segun Adebayo"
                        src="https://bit.ly/sage-adebayo"
                      />
                      <Avatar
                        name="Kent Dodds"
                        src="https://bit.ly/kent-c-dodds"
                      />
                      <Avatar
                        name="Prosper Otemuyiwa"
                        src="https://bit.ly/prosper-baba"
                      />
                      <Avatar
                        name="Christian Nwamba"
                        src="https://bit.ly/code-beast"
                      />
                    </AvatarGroup>
                  </Box>
                  <Text fontSize="14px">{candidate.description}</Text>
                </Box>
              </Box>
            ))}
          </VStack>
        </Box>
        <Box mt={4}>
          <Text fontSize="lg" fontWeight="bold" marginBottom="20px">
            Skills
          </Text>
          <VStack spacing={2} wrap="wrap" align>
            {candidate.skills.map((skill, index) => (
              <Text
                key={index}
                colorScheme="purple"
                display="flex"
                alignItems="center"
                alignSelf="start"
                gap="15px"
              >
                <BiCheckCircle color="purple" />
                {skill}
              </Text>
            ))}
          </VStack>
        </Box>
        <Box mt={4}>
          <Text fontSize="lg" fontWeight="bold">
            Education
          </Text>
          <VStack align="start">
            <Box
              //   borderWidth="1px"
              borderRadius="md"
              p={2}
              w="full"
              display="flex"
              alignItems="center"
              gap="20px"
              marginTop="20px"
            >
              <Avatar src="" />
              <Box>
                <Text fontWeight="bold" display="flex">
                  Arena Multimedia
                </Text>
                <Text color="#9095A1FF">Advanced Diploma in Multimedia</Text>
                <Text fontSize="14px">2014-1017</Text>
              </Box>
            </Box>
          </VStack>
        </Box>
      </Box>
    )
  );
};

export default CandidateDetail;
