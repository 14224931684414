import { updateLocale } from "moment/moment";

const endpoints = {
  studentsignUpData: "/api/user/signup",
  businesssignUpData: "/api/business/signup",
  studentloginData: "/api/user/login",
  businessloginData: "/api/business/login",
  // verifyemailOtp: '/api/user/verifyemail',
  editprofile: "/api/business/editprofile",
  bucketlist: "/api/user/bucketlist",
  uploadProfile: "/uploadImages",
  updateProfilePicture: "/api/business/updateProfilePicture",
  sendotp: "/api/business/sendotp",
  uploadSessions: "/api/business/uploadSessions",
  updatepassword: "/api/business/updatepassword",
  updateBussUserProfile: "/api/business/bussEditProfile",
  addUpdateSocialLinks: "/api/business/addUpdateSocialLinks",
  getExperienceData: "/api/business/experiencedData",
  createExperience: "/api/business/experience",
  deleteExperience: "/api/business/deleteExperience",
  updateExperience: "/api/business/updateExperience",
  fetchCourseList: "/api/business/fetchCoursesList",
  searchCourses: "/api/business/searchCourses",
  fetchRelatedCourseList: "/api/business/fetchRelatedCourseList",
  uploadCourse: "/api/business/uploadCourse",
  uploadCourseThumbnail: "/api/business/uploadCourseThumbnail",
  uploadExternalResources: "/api/business/uploadExternalResources",
  uploadCourseVideos: "/api/business/uploadCourseVideos",
  createComment: "/api/business/createComment",
  getComments: "/api/business/getComments",
  deleteComment: "/api/business/deleteComment",
  updateComment: "/api/business/updateComment",
  reviewByUser: "/api/business/reviewByUser",
  getReviews: "/api/business/getReviews",
  updateCourseList: "/api/business/updateCourseList",
  updatelikes: "/api/business/updatelikes",
  updatedislikes: "/api/business/updatedislikes",
  commentReport: "/api/business/commentReport",
  createReplies: "/api/business/createReplies",
  getReplies: "/api/business/getReplies",
  updateReplies: "/api/business/updateReplies",
  deleteReplies: "/api/business/deleteReplies",
  updateReplyLikes: "/api/business/updateReplyLikes",
  updateReplyDislikes: "/api/business/updateReplyDislikes",
  getTrashData: "/api/user/getTrashData",
  addToTrash: "/api/user/addToTrash",
  updateTrashData: "/api/user/updateTrashData",
};

export default endpoints;
