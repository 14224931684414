import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Button,
  Avatar,
  AvatarBadge,
  Box,
  Container,
  Flex,
  GridItem,
  List,
  ListItem,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import Ava1 from "../../../img/Male 1 G.svg";
import { getUserData } from "../../../middleware/auth";

const data = {
  BillingOps: {
    Submenu: [
      "Pricing",
      "Process Payment",
      "Test Transfer",
      "Order & Credits",
      "Subscribers",
      "Bookings",
      "Time Bookings",
      "Cancelled Booking",
      "Refunds",
    ],
  },
  BookingOps: {
    Submenu: [
      "Reservation Management",
      "Booking System Optimization",
      "Customer Support",
      "Inventory Management",
    ],
  },
  MeetingOps: {
    Submenu: [
      "Event Planning",
      "Venue Management",
      "Scheduling",
      "Attendee Management",
    ],
  },
  SubscriberOps: {
    Submenu: [
      "Subscriber Acquisition",
      "Subscription Management",
      "Churn Reduction",
      "Customer Engagement",
    ],
  },
  JobandPlacementOps: {
    Submenu: [
      "Job Postings",
      "Candidate Screening",
      "Interview Scheduling",
      "Placement Assistance",
    ],
  },
  PlatformOps: {
    Submenu: [
      "Infrastructure Management",
      "Service Monitoring",
      "Security Operations",
      "Platform Upgrades",
    ],
  },
};

const SideMenu = ({ state }) => {
  return (
    <Box
      width={247}
      // width={{ sm: "40%", lg: "40%", xl: "20%" }}
      height="100%"
      bgColor="#D3C1FA80"
      zIndex={0}
    >
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        align="stretch"
      >
        <Box>
          {state == "billing" && (
            <Accordion defaultIndex={[0]} allowMultiple zIndex={15} width={247}>
              {data.BillingOps.Submenu.map((item, index) => (
                <AccordionItem key={index} zIndex={15}>
                  <Text
                    pb={6}
                    bgColor="#D3C1FA80"
                    cursor="pointer"
                    _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    zIndex={15}
                  >
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {item}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Text>
                </AccordionItem>
              ))}
            </Accordion>
          )}

          {state == "booking" && (
            <Accordion defaultIndex={[0]} allowMultiple zIndex={15} width={247}>
              {data.BookingOps.Submenu.map((item, index) => (
                <AccordionItem key={index} zIndex={15}>
                  <Text
                    pb={6}
                    bgColor="#D3C1FA80"
                    cursor="pointer"
                    _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    zIndex={15}
                  >
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {item}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Text>
                </AccordionItem>
              ))}
            </Accordion>
          )}
          {state == "meeting" && (
            <Accordion defaultIndex={[0]} allowMultiple zIndex={15} width={247}>
              {data.MeetingOps.Submenu.map((item, index) => (
                <AccordionItem key={index} zIndex={15}>
                  <Text
                    pb={6}
                    bgColor="#D3C1FA80"
                    cursor="pointer"
                    _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    zIndex={15}
                  >
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {item}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Text>
                </AccordionItem>
              ))}
            </Accordion>
          )}
          {state == "subscribe" && (
            <Accordion defaultIndex={[0]} allowMultiple zIndex={15} width={247}>
              {data.SubscriberOps.Submenu.map((item, index) => (
                <AccordionItem key={index} zIndex={15}>
                  <Text
                    pb={6}
                    bgColor="#D3C1FA80"
                    cursor="pointer"
                    _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    zIndex={15}
                  >
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {item}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Text>
                </AccordionItem>
              ))}
            </Accordion>
          )}
          {state == "placement" && (
            <Accordion defaultIndex={[0]} allowMultiple zIndex={15} width={247}>
              <a href="/hiring">
                {data.JobandPlacementOps.Submenu.map((item, index) => (
                  <AccordionItem key={index} zIndex={15}>
                    <Text
                      pb={6}
                      bgColor="#D3C1FA80"
                      cursor="pointer"
                      _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      zIndex={15}
                    >
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {item}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </Text>
                  </AccordionItem>
                ))}
              </a>
            </Accordion>
          )}
          {state == "platform" && (
            <Accordion defaultIndex={[0]} allowMultiple zIndex={15} width={247}>
              {data.PlatformOps.Submenu.map((item, index) => (
                <AccordionItem key={index} zIndex={15}>
                  <Text
                    pb={6}
                    bgColor="#D3C1FA80"
                    cursor="pointer"
                    _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    zIndex={15}
                  >
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {item}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Text>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </Box>
      </VStack>
    </Box>
  );
};

export default SideMenu;
