import React from "react";
import {
  Flex,
  Box,
  Grid,
  Text,
  VStack,
  List,
  ListItem,
  ListIcon,
  Button,
  HStack,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import ApiData from "../../../components/ApiDataBill";

const BillingSubs = () => {
  return (
    <Box p={2}>
      <Flex>
        {/* font text  */}
        <Text
          fontFamily={"Manrope"}
          fontSize={"18"}
          fontWeight={"1000"}
          mb={5}
          ml={20}
        >
          Subscriptions Details
        </Text>
        <Text
          fontFamily={"Manrope"}
          fontSize={"18"}
          fontWeight={"1000"}
          mb={5}
          ml={"530"}
        >
          Recommended Courses
        </Text>
      </Flex>

      <Grid templateColumns="2fr 1fr" gap={6} mr={20}>
        <VStack spacing={10}>
          {/* First Subscriptions Box */}
          <Flex
            py={2}
            px={10}
            bg="white"
            borderRadius="8px"
            ml={"10"}
            h={"180"}
            cursor={"pointer"}
            _hover={{ bg: "lightblue", color: "white" }} // Box hover effect
            transition="background-color 0.3s ease, color 0.3s ease" // Smooth transition
          >
            <Box>
              <Text
                fontWeight="500"
                fontSize="16"
                color="black"
                fontFamily="Manrope"
                _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
              >
                OPTION # 2
              </Text>

              <Flex justifyContent="space-around" alignItems="center">
                <Text
                  fontWeight="700"
                  fontSize="20"
                  color="black"
                  fontFamily="Lexend"
                  _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  Pay tecdemy.com
                </Text>
                <HStack spacing={2}>
                  <Button
                    fontSize="10"
                    fontWeight="400"
                    color="white"
                    fontFamily="Manrope"
                    bg="green"
                    h="4"
                    _hover={{ transform: "scale(1.1)" }} // Button hover zoom effect
                    transition="transform 0.2s ease-in-out"
                  >
                    Active
                  </Button>
                </HStack>
              </Flex>
              <Text
                fontWeight="200"
                fontSize="10"
                fontFamily="Lexend"
                color="grey"
                _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
              >
                Pay with 20 USD discount with Coupon Code
              </Text>
              <HStack justifyContent="center" my={4}>
                <Text
                  fontSize="48"
                  fontWeight="700"
                  fontFamily="Lexend"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  $ 880
                </Text>
              </HStack>
            </Box>

            <Box>
              <VStack bg="white" py={2}  borderBottomRadius="xl" mr={"5"}>
                <List spacing={2} textAlign="start" px={12}>
                  <VStack w="full" pt={0} spacing={4}>
                    <ListItem
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <Text
                        fontSize={16}
                        fontWeight={700}
                        fontFamily="Lexend"
                        color="black"
                        _hover={{ transform: "scale(1.1)", color: "green" }}
                        transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                      >
                        Fees Details
                      </Text>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Final Fee Payable: 880 USD
                    </ListItem>
                    <ListItem
                      ml={"7"}
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Promotional Discount - 20 USD
                    </ListItem>
                    <ListItem
                      ml={"7"}
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Coupon code TECDEMY20USD
                    </ListItem>
                    <ListItem
                      ml={"-5"}
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Pay through Tecdemy
                    </ListItem>
                  </VStack>
                </List>
              </VStack>
            </Box>
          </Flex>

          {/* 2ND Subscriptions Box */}
          <Flex
            py={2}
            px={8}
            bg="white"
            borderRadius="8px"
            ml={"10"}
            h={"180"}
            cursor={"pointer"}
            _hover={{ bg: "lightblue", color: "white" }} // Box hover effect
            transition="background-color 0.3s ease, color 0.3s ease" // Smooth transition
          >
            <Box>
              <Text
                fontWeight="500"
                fontSize="16"
                color="black"
                fontFamily="Manrope"
                _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
              >
                OPTION # 1
              </Text>

              <Flex justifyContent="space-around">
                <Text
                  fontWeight="700"
                  fontSize="20"
                  color="black"
                  fontFamily="Lexend"
                  _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  Pay Professor
                </Text>
                <Text
                  mt={"-25"}
                  fontSize="48"
                  fontWeight="700"
                  fontFamily="Lexend"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  $ 900
                </Text>
              </Flex>

              <Text
                fontWeight="200"
                fontSize="10"
                fontFamily="Lexend"
                color="grey"
                _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
              >
                Pay directly to the proffesor with 60% discount
              </Text>
              <HStack justifyContent="center" my={4}>
                <Button
                  fontSize="16"
                  fontWeight="700"
                  color="black"
                  fontFamily="Manrope"
                  bg="lightblue"
                  w="full"
                  h="12"
                  _hover={{ bg: "darkblue", color: "white" }} // Button hover effect
                  transition="background-color 0.3s ease, color 0.3s ease"
                >
                  Get Started
                </Button>
              </HStack>
            </Box>

            <Box>
              <VStack bg="white" py={2} borderBottomRadius="xl">
                <List spacing={2} textAlign="start" px={12}>
                  <VStack w="full" pt={0} spacing={4}>
                    <ListItem
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <Text
                        fontSize={16}
                        fontWeight={700}
                        fontFamily="Lexend"
                        color="black"
                        _hover={{ transform: "scale(1.1)", color: "green" }}
                        transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                      >
                        Fees Details
                      </Text>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Final Fee Payable: 900 USD
                    </ListItem>
                    <ListItem
                      ml={"2"}
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Promotional Discount - 60%
                    </ListItem>
                    <ListItem
                      ml={"2"}
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      No Coupon Code applicable
                    </ListItem>
                    <ListItem
                      ml={"7"}
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Pay Directly Prof. Prasanna Kr.
                    </ListItem>
                  </VStack>
                </List>
              </VStack>
            </Box>
          </Flex>

          {/* 3rd Subscriptions Box */}
          <Flex
            py={2}
            px={6}
            bg="white"
            borderRadius="8px"
            ml={"10"}
            h={"180"}
            cursor={"pointer"}
            _hover={{ bg: "lightblue", color: "white" }} // Box hover effect
            transition="background-color 0.3s ease, color 0.3s ease" // Smooth transition
          >
            <Box>
              <Text
                fontWeight="500"
                fontSize="16"
                color="black"
                fontFamily="Manrope"
                _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
              >
                OPTION # 3
              </Text>

              <Flex justifyContent="space-around">
                <Text
                  fontWeight="700"
                  fontSize="20"
                  color="black"
                  fontFamily="Lexend"
                  _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  Pay via Business <br />
                  Optima LLC
                </Text>
                <Text
                  mt={"-25"}
                  fontSize="48"
                  fontWeight="700"
                  fontFamily="Lexend"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  $ 890
                </Text>
              </Flex>

              <Text
                fontWeight="200"
                fontSize="10"
                fontFamily="Lexend"
                color="grey"
                _hover={{ transform: "scale(1.1)", color: "white" }} // Text hover zoom effect
                transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
              >
                Pay with 10 USD with Coupon code
              </Text>
              <HStack justifyContent="center" my={4}>
                <Button
                  fontSize="16"
                  fontWeight="700"
                  color="black"
                  fontFamily="Manrope"
                  bg="lightblue"
                  w="full"
                  h="12"
                  _hover={{ bg: "darkblue", color: "white" }} // Button hover effect
                  transition="background-color 0.3s ease, color 0.3s ease"
                >
                  Get Started
                </Button>
              </HStack>
            </Box>

            <Box>
              <VStack bg="white" py={2} borderBottomRadius="xl">
                <List spacing={2} textAlign="start" px={12}>
                  <VStack w="full" pt={0} spacing={4}>
                    <ListItem
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <Text
                        fontSize={16}
                        fontWeight={700}
                        fontFamily="Lexend"
                        color="black"
                        _hover={{ transform: "scale(1.1)", color: "green" }}
                        transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                      >
                        Fees Details
                      </Text>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Final Fee Payable: 890 USD
                    </ListItem>
                    <ListItem
                      ml={"6"}
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Promotional Discount - 10 USD
                    </ListItem>
                    <ListItem ml={"6"}
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Coupon code BUSINESS10USD
                    </ListItem>
                    <ListItem
                      fontSize={12}
                      fontWeight={700}
                      fontFamily="Manrope"
                      color="black"
                      _hover={{ transform: "scale(1.1)", color: "green" }} // ListItem hover zoom effect
                      transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    >
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Pay through Busi.Opt. LLC
                    </ListItem>
                  </VStack>
                </List>
              </VStack>
            </Box>
          </Flex>
        </VStack>

        {/* ApiData Box */}
        <div
          className="container-fluid  ms-10"
          style={{ padding: 2, marginTop: "-20px" }}
        >
          <div className="row">
            <div
              className="col-8"
              style={{ width: "100%", marginLeft: "25px" }}
            >
              <div className="row gy-4" style={{ padding: 0, margin: 0 }}>
                {ApiData.map((value, index) => (
                  <div
                    className="col-4"
                    key={index}
                    style={{ padding: "10px", width: "50%" }}
                  >
                    <div
                      className="card"
                      style={{
                        transition:
                          "transform 0.3s, background-color 0.3s, color 0.3s",
                        padding: "2px",
                        backgroundColor: "white",
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                        overflow: "hidden",
                        width: "100%", // Adjust the card width here
                        margin: "0 auto", // Center the card horizontally
                      }}
                    >
                      <img
                        src={value.imgsrc}
                        alt={value.title}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "auto",
                          display: "block",
                          borderRadius: "8px",
                        }}
                      />
                      <div
                        className="card-title"
                        style={{ fontSize: "0.9rem", margin: "6px 0" }}
                      >
                        {value.title}
                      </div>
                      <div
                        className="card-profile"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={value.profile}
                          alt={value.name}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                        <div
                          className="card-name"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {value.name}
                        </div>
                      </div>
                    </div>
                    <style jsx>{`
                      .card:hover {
                        background-color: #f0f0f0; /* Change this to your desired hover background color */
                        color: #333; /* Change this to your desired hover text color */
                        transform: scale(1.05); /* Zoom in effect */
                      }
                      .card:hover .card-title,
                      .card:hover .card-name {
                        color: #ff0000; /* Change this to your desired hover text color */
                      }
                    `}</style>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Box>
  );
};

export default BillingSubs;
