import React, { useState } from "react";
import "./logo.css";
import { FaHeart } from "react-icons/fa";
import PopOver from "../components/MasterBot/PopOver";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./Sidemenu.module.css"
import { DASHBOARD_ICON } from "../components/Config";

function DashboardAside({ togglePopOver }) {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  const togglePopover = (e) => {
    setPopoverVisible(!popoverVisible);
  };

  const navigate = useNavigate();
  return (
    <aside
      id="layout-menu"
      className={`layout-menu menu-vertical menu bg-menu-theme shrinked-menu ${Styles?.menuContainer}`}
    >
      <div className={`app-brand demo ${Styles?.logoContainer} `}>
        <a onClick={() => {
          navigate("/")
        }} className={`app-brand-link menu-link ${Styles?.menuLink}`}>
          <span className="app-brand-logo demo">
            <img
              src="assets/img/custom/tecdemy-logo.png"
              width="47"
              height="47"
              alt=".."
            />
          </span>
          <div className={`${Styles?.menuTitle} logoname`}> Tecdemy</div>
        </a>
        <a
          onClick={() => {
            navigate("/")
          }}
          className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
        >
          <i className="bx bx-chevron-left bx-sm align-middle"></i>
        </a>
      </div>
      <div className="menu-inner-shadow"></div>
      <ul className="menu-inner py-1">
        <li className={`menu-item ${pathname === "/dashboard" ? "active open" : ""} ${Styles?.menuListItem}`} onClick={() => {
          navigate("/dashboard");
        }}>
          {/* <a href="#" className="menu-link menu-toggle"> */}
          <a className={`menu-link ${Styles?.menuLinkContainer}`}>
            <i className="menu-icon tf-icons">
              <img
                src={DASHBOARD_ICON}
                className="icon-img"
                alt=".."
              />
            </i>
            <div className={Styles?.menuTitle}>Dashboard</div>
          </a>

        </li>

        <li className={`menu-item ${pathname === "/Service" ? "active open" : ""} ${Styles?.menuListItem}`} onClick={() => {
          navigate("/Service");
        }}>
          {/* <a href="#" className="menu-link menu-toggle"> */}
          <a className="menu-link ">
            <i className="menu-icon tf-icons">
              <img
                src="assets/img/custom/course-icon.svg"
                className="icon-img"
                alt=".."
              />
            </i>
            <div className={Styles?.menuTitle}>Courses</div>
          </a>

        </li>

        <li className={`menu-item ${pathname === "/groups" ? "active open" : ""} ${Styles?.menuListItem}`} onClick={() => {
          navigate("/groups");
        }}>
          <a className="menu-link">
            <i className="menu-icon tf-icons">
              <img
                src="assets/img/custom/group-icon.svg"
                className="icon-img"
                alt=".."
              />
            </i>
            <div className={Styles?.menuTitle}>Groups</div>
          </a>
        </li>
        <li className={`menu-item ${pathname === "/support" ? "active open" : ""} ${Styles?.menuListItem}`} onClick={() => {
          navigate("/support");
        }}>
          <a className="menu-link">
            <i className="menu-icon tf-icons">
              <img
                src="assets/img/custom/support-icon.svg"
                className="icon-img"
                alt=".."
              />
            </i>
            <div className={Styles?.menuTitle}>Support</div>
          </a>
        </li>
        <li className={`menu-item ${pathname === "/settings" ? "active open" : ""} ${Styles?.menuListItem}`} onClick={() => {
          navigate("/settings");
        }}>
          <a className={`menu-link ${Styles?.menuLinkContainer}`}>
            <i className="menu-icon tf-icons">
              <img
                src="assets/img/custom/setting-icon.svg"
                className="icon-img"
                alt=".."
              />
            </i>
            <div className={Styles?.menuTitle}>Settings</div>
          </a>
        </li>
        <li className={`menu-item ${Styles?.menuListItem}`} onClick={() => {
          navigate("/dashboard");
          togglePopover();
        }}>
          <a className={`menu-link ${Styles?.menuLinkContainer}`}>
            <i className="menu-icon tf-icons">
              <i style={{ color: "red" }} className="fas fa-notes-medical"></i>
            </i>
            <div className={Styles?.menuTitle}>IBotControl</div>
          </a>
        </li>

      </ul >
      {popoverVisible && (
        <div className="popover">
          {/* Your popover content goes here */}
          <PopOver />
        </div>
      )}
    </aside >
  );
}

export default DashboardAside;
