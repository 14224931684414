import { Link, useNavigate } from "react-router-dom";
import { decrypt, getUserData } from "../middleware/auth";
import { clearUserData } from "../middleware/auth";
import { useEffect, useState } from "react";
import { getID } from "../siteConfig";
import { DASHBOARD_ICON, placeHolderImage } from "../components/Config";
import Styles from "./Homepage.module.css"

const HomeHeader = () => {
  let userData = getUserData().usertoken;
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    try {
      const _userDetails = JSON.parse(decrypt(getID('userData')));
      setUserDetails(_userDetails);
    } catch (error) {
      console.error(error);
    }
  }, [])


  const logoutdata = () => {
    clearUserData();
    navigate("/login");
  };
  return (
    <header className="headerwrap navbar navbar-expand" style={{ zIndex: "1" }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-sm-12 col-md-4 col-lg-auto">
            <a onClick={() => {
              navigate("/homepage")
            }} className="navbar-brand">
              <img
                src="assets/images/logo.png"
                className="brand-svg"
                alt=".."
              />
            </a>
          </div>
          <div className="col-12 col-sm-12 col-md-8 col-lg">
            <div className="collapse navbar-collapse navbermenu" id="nav-icons">
              <ul className="navbar-nav">
                <li className="nav-item dropdown coursesmenu">
                  <a className="nav-link dropdown-toggle" href="/">
                    Our Courses
                  </a>
                  <div className="dropdown-menu">
                    <button
                      type="button"
                      className="btn coursemenu-close-btn"
                      data-bs-toggle="dropdown"
                    >
                      <i className="fa fa-close"></i>
                    </button>
                    <ul className="submenu">
                      <li className="nav-item dropdown">
                        <a className="nav-link" href="/">
                          Cloud Computing
                        </a>
                        <ul className="dropdown-menu sub-submenu">
                          <li>
                            <a href="/Innertechpage">
                              Cloud Architect Masters Course
                            </a>
                          </li>
                          <li>
                            <a href="/">AWS Masters Program</a>
                          </li>
                          <li>
                            <a href="/">
                              Microsoft Azure Cloud Master Training
                            </a>
                          </li>
                          <li>
                            <a href="/">Salesforce Architect Masters Course</a>
                          </li>
                          <li>
                            <a href="/">
                              AWS Certification Training Course for Solutions
                              Architects
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              Salesforce certification Training Course
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              Microservices Certification Training Course
                            </a>
                          </li>
                          <li>
                            <a href="/">GCP Certification Training Course</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="/">DevOps</a>
                      </li>
                      <li>
                        <a href="/">BI and Visualization</a>
                      </li>
                      <li>
                        <a href="/">Cyber Security</a>
                      </li>
                      <li>
                        <a href="/">Data Science</a>
                      </li>
                      <li>
                        <a href="/">Programming Frameworks</a>
                      </li>
                      <li>
                        <a href="/">Project Management</a>
                      </li>
                      <li>
                        <a href="/">Software Testing</a>
                      </li>
                      <li>
                        <a href="/">Big Data</a>
                      </li>
                      <li>
                        <a href="/">Artificial Intelligence</a>
                      </li>
                      <li>
                        <a href="/">Databases</a>
                      </li>
                      <li>
                        <a href="/">Frontend Development</a>
                      </li>
                    </ul>
                  </div>
                </li>
                {!userData ? (
                  <>
                    <li className="nav-item">
                      <a className={`nav-link ${Styles?.menuLink}`} >
                        Login
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${Styles?.menuLink}`} >
                        Get Started
                      </a>
                    </li>
                  </>
                ) : null}
              </ul>
              {/* {userData ? (
                <ul>
                  <li className="nav-item navbar-dropdown dropdown-user dropdown">
                    <a
                      className="nav-link hide-arrow user-dropdown"
                      href="/"
                      data-bs-toggle="dropdown"
                    >
                      <div className="avatar avatar-online">
                        <img
                          src={userDetails?.profile_url || placeHolderImage}
                          alt="..."
                          className="w-px-40 h-auto rounded-circle"
                        />
                      </div>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <Link className="dropdown-item" style={{display : "flex", gap : "10px", alignItems : "center"}} to="/dashboard">
                          <img src={DASHBOARD_ICON} style={{mixBlendMode : "hard-light"}} alt="Dashboard"/>
                          <span className="align-middle">Dashboard</span>
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/editprofile">
                          <i className="bx bx-user me-2"></i>
                          <span className="align-middle">My Profile</span>
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/settings">
                          <i className="bx bx-cog me-2"></i>
                          <span className="align-middle">Settings</span>
                        </Link>
                      </li>
                      <li>
                        <a className="dropdown-item" to="/billing">
                          <span className="d-flex align-items-center align-middle">
                            <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                            <span className="flex-grow-1 align-middle ms-1">
                              Billing
                            </span>
                            <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">
                              4
                            </span>
                          </span>
                        </a>
                      </li>
                      <li>
                        <div className="dropdown-divider"></div>
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          className="dropdown-item"
                          onClick={logoutdata}
                        >
                          <i className="bx bx-power-off me-2"></i>
                          <span className="align-middle">Log Out</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

function HomepageHeader() {
  return (
    <div>
      <HomeHeader />
    </div>
  );
}
export default HomepageHeader;
