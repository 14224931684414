// RightComponent.js
import React from "react";
import { Box } from "@chakra-ui/react";
import CardCourse from "../MbotCard";
import ApiData from "../ApiData";

const RightComponent = ({ videoId }) => {
  

  console.log(videoId);

  return (
    <Box marginTop="20px">
      <CardCourse
        key={ApiData[1].length}
        URL={videoId}
        title={ApiData[1].title}
        profile={ApiData[1].profile}
        name={ApiData[1].name}
      />
    </Box>
  );
};

export default RightComponent;
