export const setID = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.error(error);
    }
}


export const getID = (key) => {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        console.error(error);
    }
}


export const removeID = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error(error);
    }
}