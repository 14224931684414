import React from "react";
import { Container, Text } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import AllCourses from "./AllCourses";
import InProgress from "./InProgress";
import Completed from "./completed";

const MyCourse = () => {
  return (
    <div>
      <Container maxW="100%" bgColor="white">
        <Text color="#6f42c1" fontSize="28px" fontWeight="500">
          My Courses
        </Text>
        <Tabs variant="enclosed" colorScheme="white">
          <TabList>
            <Tab
              _selected={{
                borderBottom: "4px solid #6534e4",
                color: "#6534e4",
                fontWeight: "700",
                borderRadius: "0px",
              }}
              _focus={{ boxShadow: "md" }}
              borderColor="white"
              borderTop="3px solid transparent"
              fontWeight="500"
            >
              All Courses
            </Tab>
            <Tab
              _selected={{
                borderBottom: "4px solid #6534e4",
                color: "#6534e4",
                fontWeight: "700",
                borderRadius: "0px",
              }}
              _focus={{ boxShadow: "md" }}
              borderColor="white"
              borderTop="3px solid transparent"
              fontWeight="500"
            >
              In Progress
            </Tab>

            <Tab
              _selected={{
                borderBottom: "4px solid #6534e4",
                color: "#6534e4",
                fontWeight: "700",
                borderRadius: "0px",
              }}
              _focus={{ boxShadow: "md" }}
              borderColor="white"
              borderTop="3px solid transparent"
              fontWeight="500"
            >
              Completed
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AllCourses />
            </TabPanel>
            <TabPanel>
              <InProgress />
            </TabPanel>
            <TabPanel>
              <Completed />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </div>
  );
};

export default MyCourse;
