import Styles from "./element.module.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


export const BOLoading = () => {
    return <div className={Styles?.loadingElement}>
        <i class="fas fa-spinner"></i>
    </div>;
}

export const BOPhoneInput = (props) => {
    const { value, handleChange, className = "" } = props;
    return <div className={`${Styles?.phoneInputWrapper} ${className}`}>
        <PhoneInput
            country={'us'}
            value={value}
            onChange={handleChange}
            autoFormat
        />
    </div>

}