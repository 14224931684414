import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Input,
  Text,
  Heading,
  IconButton,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import ThumbnailUploader from "./ThumbnailUploader";
import Styles from "./courseUploader.module.css";

const CourseSubsection = ({
  subsections,
  onSubsectionChange,
  onAddSubsection,
  onDeleteSubsection,
  onAddResourceLink,
  onDeleteResourceLink,
  onResourceLinkChange,
  onAddExternalUrl,
  onDeleteExternalUrl,
  onExternalUrlChange,
  setSectionsDetails,
  sectionIndex,
  onResourceTitleChange,
}) => {
  const [extfile, setExtFile] = useState({});
  const [externalResourceUrl, setExternalResourceUrl] = useState("");
  const [videofile, setVideoFile] = useState("");
  const [video, setVideo] = useState("");
  // useEffect(() => {
  //   console.log("dasd", videofile);
  // }, [videofile]);

  // const handleChange = (index, event) => {
  //   const { name, value } = event.target;
  //   onSubsectionChange(index, { target: { name, value } });

  //   if (name === "external_resources") {
  //     onExternalResource({ target: { files: event } }); // Pass files in the target object
  //   } else if (name === "course_video") {
  //     onhandleChangeVideos({ target: { files: event } }); // Pass files in the target object
  //   }
  // };

  const handleUploadResources = (e, secIndex, subIndex) => {
    const { name, files } = e.target;
    const _files = Array.from(files);
    const newImages = _files.map((file) => URL.createObjectURL(file));
    const external = extfile[name] || [];
    setExtFile((prev) => {
      return { ...prev, [name]: [...external, ...newImages] };
    });
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      newSections[secIndex].courseList[subIndex].external_resources = _files;
      return newSections;
    });
  };

  const handleDeleteFile = (file, name) => {
    //add try catch
    setExtFile((prev) => {
      return {
        ...prev,
        [name]: extfile[name].filter((items) => items !== file),
      };
    });
  };

  const handleDeleteVideo = (file, name) => {
    //add try catch
    setVideoFile((prev) => {
      return {
        ...prev,
        [name]: videofile[name].filter((items) => items !== file),
      };
    });
  };
  const handleUploadVideos = (secIndex, subIndex, e) => {
    const { name, files } = e.target;
    const _files = Array.from(files);
    const newImages = _files.map((file) => URL.createObjectURL(file));
    const external = videofile[name] || [];
    setVideoFile((prev) => {
      return { ...prev, [name]: [...external, ...newImages] };
    });
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      newSections[secIndex].courseList[subIndex].course_video = _files;
      return newSections;
    });
  };
  return (
    <Container
      maxW="100%"
      className={`${Styles?.mainContainer}`}
      marginBottom="10px"
    >
      {/* <Box
        className={`${Styles?.addProfileHeader} ${Styles?.AlignLeft}`}
        marginBottom="10px"
      >
        <Heading as={"h4"}>Course Content</Heading>
      </Box> */}

      {subsections.map((subsection, index) => (
        <Box className={Styles?.addProfileBody} marginTop="10px" key={index}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading as="h5" size="md" marginLeft="10px">
              Subsection {index + 1}
            </Heading>
            <IconButton
              icon={<CloseIcon color="black" />}
              onClick={() => onDeleteSubsection(index)}
              aria-label="Delete Subsection"
              size="sm"
              colorScheme="white"
            />
          </Box>

          <Box flex="1" className={`${Styles?.flex} ${Styles?.AlignLeft}`}>
            <Text width="40%" display="flex" alignItems="center">
              Title
            </Text>
            <Input
              width="70%"
              name="title"
              value={subsection.title}
              placeholder="Title"
              onChange={(event) => onSubsectionChange(index, event)}
            />
          </Box>

          <Box flex="1" className={`${Styles?.flex} ${Styles?.AlignLeft}`}>
            <Text width="40%" display="flex" alignItems="center">
              Description
            </Text>
            <Textarea
              width="70%"
              name="description"
              value={subsection.description}
              placeholder="Description"
              onChange={(event) => onSubsectionChange(index, event)}
            />
          </Box>

          <Box className={`${Styles?.flex} ${Styles?.AlignLeft}`}>
            <Text width="30%" display="flex" alignItems="center">
              Resource List
            </Text>
            <Box width="80%">
              {subsection.resource_list.map((resource, resIndex) => (
                <Flex
                  key={resIndex}
                  alignItems="center"
                  mb={2}
                  flexDirection="row"
                >
                  <Input
                    marginRight="10px"
                    name={`title`}
                    value={resource.title}
                    placeholder="Resource Title"
                    onChange={(event) =>
                      onResourceLinkChange(sectionIndex, resIndex, event)
                    }
                    mb={2}
                  />
                  <Input
                    name={`link`}
                    value={resource.link}
                    placeholder="Resource Link"
                    onChange={(event) =>
                      onResourceLinkChange(index, resIndex, event)
                    }
                    mb={2}
                  />
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => onDeleteResourceLink(index, resIndex)}
                    aria-label="Delete Resource Link"
                    size="sm"
                    ml={2}
                  />
                </Flex>
              ))}
              <IconButton
                width="100%"
                icon={<AddIcon />}
                onClick={() => onAddResourceLink(index)}
                aria-label="Add Resource Link"
              />
            </Box>
          </Box>

          <Box flex="1" className={`${Styles?.flex} ${Styles?.AlignLeft}`}>
            <Text width="30%" display="flex" alignItems="center">
              External URLs
            </Text>
            <Box width="80%">
              {subsection.external_urls_videos.map((url, urlIndex) => (
                <Flex key={urlIndex} alignItems="center" mb={2}>
                  <Input
                    marginRight="10px"
                    name={`title`}
                    value={url.title}
                    placeholder="External url title"
                    onChange={(event) =>
                      onExternalUrlChange(index, urlIndex, event)
                    }
                    mb={2}
                  />
                  <Input
                    name={`link`}
                    value={url.link}
                    placeholder="External URL/Video"
                    onChange={(event) =>
                      onExternalUrlChange(index, urlIndex, event)
                    }
                    mb={2}
                  />
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => onDeleteExternalUrl(index, urlIndex)}
                    aria-label="Delete External URL"
                    size="sm"
                    ml={2}
                  />
                </Flex>
              ))}
              <IconButton
                width="100%"
                icon={<AddIcon />}
                onClick={() => onAddExternalUrl(index)}
                aria-label="Add External URL"
              />
            </Box>
          </Box>

          <Box flex="1" className={`${Styles?.flex} ${Styles?.AlignLeft}`}>
            <Text width="50%" display="flex" alignItems="center">
              External Resources
            </Text>
            <ThumbnailUploader
              accept=".jpeg,.png,.jpg,.pdf,.xlsx,.xls"
              isThumbnail={false}
              name={`external_resources${index}`}
              // onExternalResource={onExternalResource}
              // onhandleChangeVideos={onhandleChangeVideos}
              handleChangeUploader={(e) => {
                handleUploadResources(e, sectionIndex, index);
              }}
              files={extfile[`external_resources${index}`]}
              handleDeleteFile={handleDeleteFile}
              subIndex={index}
            />
          </Box>

          <Box flex="1" className={`${Styles?.flex} ${Styles?.AlignLeft}`}>
            <Text width="80%" display="flex" alignItems="center">
              Add Video
            </Text>
            <ThumbnailUploader
              accept=".mp4"
              isThumbnail={true}
              name={`course_video${index}`}
              handleChangeUploader={(e) =>
                handleUploadVideos(sectionIndex, index, e)
              }
              files={videofile[`course_video${index}`]}
              handleDeleteFile={handleDeleteVideo}
              subIndex={index}
              // handleChange={(e) => {
              //   handleChange(e);
              //   handleChangeThumbnail(e);
              // }}
            />
          </Box>
        </Box>
      ))}

      <Box className={Styles?.addProfileFooter} width="100%">
        <Button onClick={onAddSubsection} mt="10px" width="100%">
          Add Subsection
        </Button>
      </Box>
    </Container>
  );
};

export default CourseSubsection;
