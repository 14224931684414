import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Link,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Logo from "../../../img/logo.png";
import { IoSearch } from "react-icons/io5";
import { MdOutlineLocationOn } from "react-icons/md";
import { useNavigate, useNavigationType } from "react-router-dom";

const CandidateNavbar = ({ children }) => {
  const [state, setState] = useState("");
  return (
    <Container maxW="100%" height="100%" margin="0px" padding="0px">
      <JobBoard state={state} setState={setState} />
      {children};
    </Container>
  );
};

export default CandidateNavbar;

const JobBoard = ({ state, setState }) => {
  const [isFixed, setIsFixed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Container
      maxW="100%"
      height="100%"
      padding="0px"
      bgColor="white"
      marginBottom={isFixed ? "100px" : "0px"}
    >
      <Box
        width="100%"
        display={{ base: "", sm: "flex", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Image src={Logo} height="90px" width="250px" />
        <Box
          bgColor="#6D31EDFF"
          height="60px"
          display="flex"
          alignItems="center"
          width="330px"
          justifyContent="center"
          borderRadius="10px"
        >
          <Link onClick={() => navigate("/jobandplacement")}>
            <Button
              colorScheme="purple"
              // bgColor="#6D31EDFF"
              color="white"
              _hover={{ bgColor: "#f5f1fe", color: "#6D31EDFF" }}
            >
              Search Jobs
            </Button>
          </Link>
          <Link onClick={() => navigate("/hiring")}>
            <Button bgColor="white" color="#6D31EDFF">
              Search Candidates
            </Button>
          </Link>
        </Box>
        <Button colorScheme="purple" variant="outline" marginRight="20px">
          Login with Tecdemy
        </Button>
      </Box>
      <Box
        width="100%"
        bgColor="#6D31EDFF"
        height={{ base: "300px", md: "100px" }}
        display="flex"
        flexDirection={{ base: "column", sm: "column", md: "row" }}
        padding="20px"
        paddingLeft="40px"
        alignItems="center"
        gap={{ base: "20px", sm: "30px", md: "40px", lg: "50px", xl: "70px" }}
        position={isFixed ? "fixed" : "static"}
        top={isFixed ? "0" : "auto"}
        zIndex={isFixed ? 1000 : "auto"}
        transition="top 0.3s ease-in-out"
      >
        <Box textAlign="center">
          <Text
            fontSize={{
              base: "30px",
              sm: "25px",
              md: "30px",
              lg: "35px",
              xl: "35px",
            }}
            fontWeight="700"
            color="white"
          >
            Search Candidates
          </Text>
          <Text fontSize="14px" color="white">
            Careers through Tecdemy
          </Text>
        </Box>

        <Box width={{ sm: "50%", md: "50%", lg: "60%", xl: "70%" }}>
          <Box>
            <Checkbox
              defaultChecked
              colorScheme="green"
              color="white"
              marginRight="10px"
            >
              INTERNAL
            </Checkbox>
            <Checkbox
              defaultChecked
              colorScheme="green"
              color="white"
              marginRight="10px"
            >
              GLOBAL
            </Checkbox>
            <Checkbox defaultChecked colorScheme="green" color="white">
              OTHERS
            </Checkbox>
          </Box>
          <Box
            // width={{ sm: "50%", md: "50%", lg: "60%", xl: "70%" }}
            height={{ base: "150px", md: "50px" }}
            bgColor="white"
            display="flex"
            flexDirection={{
              base: "column",
              sm: "column",
              md: "row",
              lg: "row",
            }}
            alignItems="center"
            justifyContent="space-between"
            padding="5px"
            borderRadius="5px"
          >
            <IoSearch size="20px" />
            <Input
              placeholder="Search by profile, experience or skill "
              width={{ base: "100%", sm: "100%", md: "30%" }}
              border="0px"
              marginBottom={{ base: "10px", md: "0px" }}
            />
            <MdOutlineLocationOn size="20px" />
            <Input
              placeholder="Location"
              width={{ base: "100%", sm: "100%", md: "30%" }}
              border="0px"
              marginBottom={{ base: "10px", md: "0px" }}
            ></Input>
            <Select
              placeholder="Experience"
              width={{ base: "100%", sm: "100%", md: "30%" }}
              marginBottom={{ base: "10px", md: "0px" }}
            >
              <option value="option3">0 years</option>
              <option value="option1">1 year+</option>
              <option value="option2">2 years+</option>
              <option value="option3">3 years+</option>
              <option value="option3">4 years+</option>
            </Select>
            <a href="/job-search-results">
              <Button
                colorScheme="yellow"
                height={{ base: "40px", sm: "40px", md: "40px" }}
              >
                Search
              </Button>
            </a>
          </Box>
        </Box>
      </Box>
      <Box width="100%">
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
            xl: "repeat(6, 1fr)",
          }}
          flexWrap="wrap"
        >
          <GridItem w="100%" h="10">
            <Link onClick={() => navigate("/PaymentPrice")}>
              <Button
                width="100%"
                colorScheme={state == "billing" ? "purple" : "white"}
                color={state == "billing" ? "white" : "black"}
                borderRadius="0px"
                onClick={() => setState("billing")}
              >
                Billing Ops
              </Button>
            </Link>
            {/* <Accordion defaultIndex={[0]} allowMultiple zIndex={15}>
              <AccordionItem zIndex={15}>
                <Text
                  bgColor="#6D31ED66"
                  color="black"
                  margin="0px"
                  textDecoration="none"
                  padding="0px"
                >
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Billing Ops
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </Text>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                  zIndex={15}
                >
                  Pricing
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Process Payment
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Test Transfer
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Orders and Credits
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Subscribers
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Bookings
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Time Bookings
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Cancelled Bookings
                  <IoIosArrowForward />
                </AccordionPanel>
                <AccordionPanel
                  pb={1}
                  bgColor="#D3C1FA80"
                  cursor="pointer"
                  _hover={{ color: "#6d31ed", backgroundColor: "white" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                >
                  Refunds
                  <IoIosArrowForward />
                </AccordionPanel>
              </AccordionItem>
            </Accordion> */}
          </GridItem>

          <GridItem w="100%" h="10">
            <Link onClick={() => navigate("/Bookings")}>
              <Button
                width="100%"
                colorScheme={state == "booking" ? "purple" : "white"}
                color={state == "booking" ? "white" : "black"}
                borderRadius="0px"
                onClick={() => setState("booking")}
              >
                Booking Ops
              </Button>
            </Link>
          </GridItem>

          <GridItem w="100%" h="10">
            <Button
              width="100%"
              colorScheme={state == "meeting" ? "purple" : "white"}
              color={state == "meeting" ? "white" : "black"}
              borderRadius="0px"
              onClick={() => setState("meeting")}
            >
              Meeting Ops
            </Button>
          </GridItem>
          <GridItem w="100%" h="10">
            <Link onClick={() => navigate("/BillingCancel")}>
              <Button
                width="100%"
                colorScheme={state == "subscribe" ? "purple" : "white"}
                color={state == "subscribe" ? "white" : "black"}
                borderRadius="0px"
                onClick={() => setState("subscribe")}
              >
                Subscriber Ops
              </Button>
            </Link>
          </GridItem>
          <GridItem w="100%" h="10">
            <Link onClick={() => navigate("/hiring")}>
              <Button
                width="100%"
                colorScheme={state == "placement" ? "purple" : "white"}
                color={state == "placement" ? "white" : "black"}
                borderRadius="0px"
                onClick={() => setState("placement")}
              >
                Jobs and Placement Ops
              </Button>
            </Link>
          </GridItem>
          <GridItem w="100%" h="10">
            <Button
              width="100%"
              colorScheme={state == "platform" ? "purple" : "white"}
              color={state == "platform" ? "white" : "black"}
              borderRadius="0px"
              onClick={() => setState("platform")}
            >
              Platform Ops
            </Button>
          </GridItem>
        </Grid>
      </Box>
    </Container>
  );
};
