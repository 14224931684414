import "./App.css";
import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import Innertechpage from "./components/innerpage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import NotFoundComponent from "./Shared/NotFoundComponent";
import CommonLayout from "./components/commonlayout";

import SignUpForm from "./components/SignUp";
import SignInForm from "./components/SignIn";
import ResetPass from "../src/components/ResetPassword/ResetPass";
import ConfirmPas from "../src/components/ResetPassword/ConfirmPass";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Logintype from "./components/logintype";

import Contentvideo from "./components/Contentvideo";
import Editprofile2 from "./components/Editprofile2";
import DashboardLayout from "./layout/DashboardLayout";
import { decrypt, getUserData } from "./middleware/auth";
import Dashboard from "./components/dashboard";
import Coursesearch from "./components/Coursesearch";
import Coursedetail from "./components/Coursedetail";
// import * as CryptoJS from 'crypto-js'

import CheckoutPage from "./components/Checkout";
import Orderconfirmpage from "./components/Orderconfirm";

import Navbar from "../src/bucketlist/navbar";
import Groups from "./components/groups";
import Support from "./components/support";
import Javascript from "./components/javascript-course";
import Amazon from "./components/amazon-course";
import Fullsatck from "./components/fullstack-course";
import Cybersecurity from "./components/cybersecurity-course";
import Homepagefooter from "./Shared/Homepagefooter";
import Service from "./components/Service";
import Business from "./components/Business";
import SettingsPage from "./components/Setting";
import CourseVideo from "./components/CourseVideo";

import CourseUploader from "./components/CourseUploader/CourseUploader";
import MyCourse from "./components/MyCourses/MyCourse";
import Cart from "./components/Shopping Cart/Cart";
// import Payment from "./components/Checkout/Payment";

import MentorGuidance from "./components/MentorGuidance/MentorGuidance";
import Teachers from "./components/Teachers";
import Payment from "./components/Payment/Payment";

import NavbarJob from "./Tecdemy Job Portal/Layout/Navbar/Navbar";
import SideBar from "./Tecdemy Job Portal/Layout/Sidebar/Sidebar";

import JobAndPlacementPage1 from "./Tecdemy Job Portal/Screens/JobAndPlacementPage1";
import JobAndPlacementPage2 from "./Tecdemy Job Portal/Screens/JobAndPlacementPage2";
import JobSearchResult from "./Tecdemy Job Portal/Screens/JobSearchResult";
import JobUserProfile from "./Tecdemy Job Portal/Screens/JobUserProfile";
import PaymentPrice from "./components/PaymentOps/PaymentPrice";

import JobBoardHeader from "./Tecdemy Job Portal/Layout/Navbar/Navbar";
import Bookings from "./Tecdemy Job Portal/Screens/BookingOps/Bookings";
import Hiring from "./Tecdemy Job Portal/Screens/Hiring/Hiring";
import BillingCancel from "./Tecdemy Job Portal/Screens/PaymentOps/BillingCancel";
import BillingRefunds from "./Tecdemy Job Portal/Screens/PaymentOps/BillingRefunds";
import BillingCanConf from "./Tecdemy Job Portal/Screens/PaymentOps/BillingCanConf";
import BillingBook from "./Tecdemy Job Portal/Screens/PaymentOps/BillingBook";
import BillingSubs from "./Tecdemy Job Portal/Screens/PaymentOps/BillingSubs";
import PaymentOps from "./Tecdemy Job Portal/Screens/PaymentOps/PaymentOps";
import HelpCenter from "./components/HelpCenter/HelpCenter";
import CandidateNavbar from "./Tecdemy Job Portal/Layout/Navbar/Candidatenavbar";
import Shoping from "./components/UploadPages/Shoping";
import PayScan from "./Payment/PayScan";
import Mentors from "./components/Mentors/mentors";
import VerifyEmail from "./components/VerifyEmail.js";
import Popups from "./components/Popus/Popup";
import { getID } from "./siteConfig.js";

// import BillingBook from "./Tecdemy Job Portal/Screens/PaymentOps/BillingBook";
// import BillingSubs from "./Tecdemy Job Portal/Screens/PaymentOps/BillingSubs";
// const Dashboard = React.lazy(() => import('./components/dashboard'));
// const Contentvideo = React.lazy(() => import('./components/Contentvideo'));
// const Coursesearch = React.lazy(() => import('./components/Coursesearch'));
// const Coursedetail = React.lazy(() => import('./components/Coursedetail'));
// const SuperadminloginContainer = React.lazy(() => import('./superadmin/superadminlogin'));
// C:\Users\user\Desktop\UpTecDemy\Tecdemey_website\src\Tecdemy Job Portal\Screens\PaymentOps\BillingSubs.js
// import BillingSubs from "./Tecdemy Job Portal/Screens/PaymentOps/BillingSubs";

function App() {
  const [token, setToken] = useState("");
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/main.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    try {
      if (getID("userData")) {
        const userDetails = JSON.parse(decrypt(getID("userData")));

        setUserDetails(userDetails);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  let userDAta = getUserData().usertoken;

  return (
    <div>
      <ToastContainer />
      <Popups />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <CommonLayout>
                <Layout />
              </CommonLayout>
            }
          />
          {
            // <Route
            //   path="/dashboard"
            //   element={
            //     <DashboardLayout>
            //       <Dashboard />
            //     </DashboardLayout>
            //   }
            // />
          }
          {/* <Route
            path="/homepage"
            element={
              <CommonLayout>
                <Layout />
              </CommonLayout>
            }
          /> */}
          <Route
            path="/Innertechpage"
            element={
              <CommonLayout>
                <Innertechpage />
              </CommonLayout>
            }
          />
          {/* <Route path="/signUp" element={<SignUpForm />} /> */}
          {/* <Route
            path="/Service"
            element={
              <DashboardLayout>
                <Service />
              </DashboardLayout>
            }
          /> */}
          {/* <Route
            path="/Mentor"
            element={
              <DashboardLayout>
                <Teachers />
              </DashboardLayout>
            }
          /> */}
          {/* <Route
            path="/Service"
            element={
              <DashboardLayout>
                <Service />
              </DashboardLayout>
            }
          /> */}
          {/* <Route
            path="/course-search"
            element={
              <DashboardLayout>
                <Coursesearch />
              </DashboardLayout>
            }
          /> */}
          {/* <Route
            path="/javascript"
            element={
              <DashboardLayout>
                <Javascript />
              </DashboardLayout>
            }
          /> */}
          {/* <Route
            path="/amazon-aws"
            element={
              <DashboardLayout>
                <Amazon />
              </DashboardLayout>
            }
          /> */}
          {/* <Route
            path="/fullstack"
            element={
              <DashboardLayout>
                <Fullsatck />
              </DashboardLayout>
            }
          /> */}
          <Route
            path="/cybersecurity"
            element={
              <DashboardLayout>
                <Cybersecurity />
              </DashboardLayout>
            }
          />
          <Route path="/job" element={<NavbarJob />} />
          {/* <Route path="/Billing" element={<SideBar />} /> */}
          {/* <Route
            path="/course-video"
            element={
              <DashboardLayout>
                <Contentvideo />
              </DashboardLayout>
            }
          /> */}
          {/* <Route
            path="/mentor-guidance"
            element={
              <DashboardLayout>
                <MentorGuidance />
              </DashboardLayout>
            }
          /> */}
          {/* <Route
            path="/coursevideo/:courseId"
            element={
              <DashboardLayout>
                <CourseVideo />
              </DashboardLayout>
            }
          /> */}
          {/* <Route
            path="/cart"
            element={
              <DashboardLayout>
                <Cart />
              </DashboardLayout>
            }
          /> */}
          <Route
            path="/jobandplacement"
            element={
              <NavbarJob>
                <JobAndPlacementPage1 />
              </NavbarJob>
            }
          />
          <Route
            path="/job-user-profile"
            element={
              <NavbarJob>
                <JobUserProfile />
              </NavbarJob>
            }
          />
          <Route
            path="/job-search-results"
            element={
              <NavbarJob>
                <JobSearchResult />
              </NavbarJob>
            }
          />
          <Route
            path="/jobandplacement"
            element={
              <NavbarJob>
                <JobAndPlacementPage1 />
              </NavbarJob>
            }
          />
          <Route
            path="/resumeReview"
            element={
              <NavbarJob>
                <JobAndPlacementPage2 />
              </NavbarJob>
            }
          />
          <Route
            path="/PaymentOps"
            element={
              <DashboardLayout>
                <PaymentOps />
              </DashboardLayout>
            }
          />
          <Route
            path="/BillingCancel"
            element={
              <SideBar>
                <BillingCancel />
              </SideBar>
            }
          />
          <Route
            path="/BillingRefunds"
            element={
              <SideBar>
                <BillingRefunds />
              </SideBar>
            }
          />
          <Route
            path="/BillingCanConf"
            element={
              <SideBar>
                <BillingCanConf />
              </SideBar>
            }
          />
          <Route
            path="/hiring"
            element={
              <CandidateNavbar>
                <Hiring />
              </CandidateNavbar>
            }
          />
          <Route
            path="/PaymentOps"
            element={
              <SideBar>
                <PaymentOps />
              </SideBar>
            }
          />
          <Route
            path="/bookings"
            element={
              <SideBar>
                <Bookings />
              </SideBar>
            }
          />
          <Route
            path="/BillingBook"
            element={
              <SideBar>
                <BillingBook />
              </SideBar>
            }
          />
          <Route
            path="/BillingSubs"
            element={
              <SideBar>
                <BillingSubs />
              </SideBar>
            }
          />
          <Route
            path="/BillingBook"
            element={
              <SideBar>
                <BillingBook />
              </SideBar>
            }
          />
          <Route
            path="/BillingSubs"
            element={
              <SideBar>
                <BillingSubs />
              </SideBar>
            }
          />
          <Route
            path="/checkout"
            element={
              <DashboardLayout>
                <CheckoutPage />
              </DashboardLayout>
            }
          />
          <Route
            path="/payment"
            element={
              <DashboardLayout>
                <Payment />
              </DashboardLayout>
            }
          />
          <Route path={`/PlanPayment/:planPrice`} element={<PaymentOps />} />
          <Route
            path="/orderconfirm"
            element={
              <DashboardLayout>
                <Orderconfirmpage />
              </DashboardLayout>
            }
          />
          <Route
            path="/business"
            element={
              <DashboardLayout>
                <Business />
              </DashboardLayout>
            }
          />
          <Route
            path="/mycourse"
            element={
              <DashboardLayout>
                <MyCourse />
              </DashboardLayout>
            }
          />
          <Route
            path="/settings"
            element={
              <DashboardLayout>
                <SettingsPage />
              </DashboardLayout>
            }
          />
          <Route
            path="/courseupload"
            element={
              <DashboardLayout>
                <CourseUploader />
              </DashboardLayout>
            }
          />
          <Route
            path="/editprofile"
            element={
              <DashboardLayout>
                {/* <Editprofile /> */}
                <Editprofile2 />
              </DashboardLayout>
            }
          />
          {/* <Route path="*" element={<NotFoundComponent />} /> */}
          <Route
            path="/bucketlist"
            element={
              <DashboardLayout>
                <Navbar />
              </DashboardLayout>
            }
          />
          <Route
            path="/groups"
            element={
              <DashboardLayout>
                <Groups />
                <Homepagefooter />
              </DashboardLayout>
            }
          />
          <Route
            path="/support"
            element={
              <DashboardLayout>
                <HelpCenter />
                <Homepagefooter />
              </DashboardLayout>
            }
          />
          <Route
            path="/Mentors"
            element={
              <DashboardLayout>
                <Mentors />
                <Homepagefooter />
              </DashboardLayout>
            }
          />
          <Route
            path="/Mentors"
            element={
              <DashboardLayout>
                <Mentors />
                <Homepagefooter />
              </DashboardLayout>
            }
          />
          <Route
            path="/Teachers"
            element={
              <DashboardLayout>
                <Teachers />
                <Homepagefooter />
              </DashboardLayout>
            }
          />
          <Route
            path="/PaymentOps"
            element={
              <DashboardLayout>
                <PaymentOps />
              </DashboardLayout>
            }
          />
          <Route
            path="/BillingCancel"
            element={
              <DashboardLayout>
                <BillingCancel />
              </DashboardLayout>
            }
          />
          <Route
            path="/Bookings"
            element={
              <DashboardLayout>
                <Bookings />
              </DashboardLayout>
            }
          />
          <Route
            path="/BillingRefunds"
            element={
              <DashboardLayout>
                <BillingRefunds />
              </DashboardLayout>
            }
          />
          <Route
            path="/BillingCanConf"
            element={
              <DashboardLayout>
                <BillingCanConf />
              </DashboardLayout>
            }
          />
          <Route
            path="/Payment"
            element={
              <DashboardLayout>
                <Payment />
                <Homepagefooter />
              </DashboardLayout>
            }
          />
          <Route
            path="/Shoping"
            element={
              <DashboardLayout>
                <Shoping />
                <Homepagefooter />
              </DashboardLayout>
            }
          />
          <Route
            path="/PayScan"
            element={
              <DashboardLayout>
                <PayScan />
                <Homepagefooter />
              </DashboardLayout>
            }
          />
          <Route
            path="/PaymentPrice"
            element={
              <SideBar>
                <PaymentPrice />
              </SideBar>
            }
          />
          <Route
            path="/"
            element={
              <CommonLayout>
                <Layout />
              </CommonLayout>
            }
          />
          {/* <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/login" element={<SignInForm />} />
          <Route path="/ResetPass" element={<ResetPass />} />
          <Route path="/ConfirmPass" element={<ConfirmPas />} />
          <Route path="/usertype" element={<Logintype />} />
          <Route path="/login" element={<SignInForm setToken={setToken} />} /> */}
          {/* <Route
            path="/homepage"
            element={
              <CommonLayout>
                <Layout />
              </CommonLayout>
            }
          /> */}
          <Route
            path="/Innertechpage"
            element={
              <CommonLayout>
                <Innertechpage />
              </CommonLayout>
            }
          />
          {/* <Route path="/signUp" element={<SignUpForm />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
