import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ShareComponent from "./ShareComponent";
import {
  ChakraProvider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  Button,
  SimpleGrid,
  Table,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Card,
  TableContainer,
  Box,
  Input,
  Text,
  Flex,
  IconButton,
  CloseButton,
  Center,
  Grid,
  Icon,
} from "@chakra-ui/react";
import YouTubeSearch from "../../components/Youtube";
import { ChevronDownIcon, CloseIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import cardImg from "../../img/CardImage.jpeg";

const MiddleComponent = ({
  newOption,
  options,
  handleTextareaChange,
  handleAddOption,
  maxChar,
  handleCloseButtonClick,
  handleNewData,
  youTubeSearchData,
  handlePageChange,
  currentPage,
  totalPages,
  newData,
}) => {
  const [searchText, setSearchText] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState(options);
  const [searchResults, setSearchResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const navigate = useNavigate();

  const [debounceTimer, setDebounceTimer] = useState(null);

  const [isRatingVisible, setIsRatingVisible] = useState(false);
  const [rating, setRating] = useState(0);
  const [isStarClicked, setIsStarClicked] = useState(false);

  const handleStarClick = () => {
    setIsRatingVisible(!isRatingVisible);
    setIsStarClicked(true); // Set isStarClicked to true when star is clicked
    if (rating < 5) {
      setRating(rating + 1);
    }
  };

  const handleShareClick = () => {
    setIsShareOpen(true);
  };

  const closeShareModal = () => {
    setIsShareOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (searchText.trim()) {
      const newOption = { value: searchText, label: searchText };
      if (!dropdownOptions.find((option) => option.value === searchText)) {
        setDropdownOptions([...dropdownOptions, newOption]);
      }
    }
  }, [searchText]);

  const handleSearchChange = (e) => {
    clearTimeout(debounceTimer);
    const value = e.target.value;
    setDebounceTimer(setTimeout(() => setSearchText(value), 1000));
  };

  const handleDeleteOption = (value) => {
    setDropdownOptions(
      dropdownOptions.filter((option) => option.value !== value)
    );
  };

  const handleSearch = () => {
    setIsSearching(true);
    setTimeout(() => {
      setSearchResults([
        {
          title: "Web Development Course 1",
          description:
            "This course covers HTML, CSS, and JavaScript basics. Perfect for beginners to start their journey into web development.",
          // image: cardImg,
          details: [
            "Introduction to HTML",
            "Styling with CSS",
            "Interactive JavaScript",
          ],
          link: "/mycourse",
        },
        {
          title: "Web Development Course 2",
          description:
            "Learn how to build responsive websites using modern CSS frameworks and JavaScript libraries.",
          // image: cardImg,
          details: [
            "Responsive Design Principles",
            "CSS Flexbox and Grid",
            "Using Bootstrap",
          ],
          link: "/mycourse",
        },
        {
          title: "Web Development Course 3",
          description:
            "Advanced web development techniques with a focus on backend integration and full-stack development.",
          // image: cardImg,
          details: [
            "Node.js and Express",
            "Database Integration",
            "Deploying Applications",
          ],
          link: "/mycourse",
        },
      ]);
      setIsSearching(false);
    }, 2000); // Simulate API call delay
  };

  const handleCourseClick = (link) => {
    navigate(link);
  };

  const handleCheckboxChange = (value) => {
    setSelectedCheckboxes((prev) =>
      prev.includes(value)
        ? prev.filter((checkbox) => checkbox !== value)
        : [...prev, value]
    );
  };

  return (
    <Box width="1000px" padding="20px">
      <span style={{ position: "absolute", top: "-4px", right: "18px" }}>
        <IconButton
          size="sm"
          fontSize="10px"
          colorScheme="red"
          aria-label="Close"
          icon={<CloseButton />}
          _hover={{ bgColor: "green.500" }}
          onClick={handleCloseButtonClick}
        />
      </span>
      <Flex width="100%" height="100%">
        <Box>
          <Text fontSize="20px" fontWeight="800" mt={-10}>
            IBotControl
          </Text>
          <Box display="flex" alignItems="center" mt={-2.5}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                marginTop="20px"
                width="200px"
              >
                {newOption || "Learn React in 2 days"}
              </MenuButton>
              <MenuList>
                {dropdownOptions.map((option) => (
                  <MenuItem key={option.value} closeOnSelect={false}>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Text>{option.label}</Text>
                      <IconButton
                        icon={<CloseIcon />}
                        size="xs"
                        colorScheme="red"
                        onClick={() => handleDeleteOption(option.value)}
                      />
                    </Flex>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Flex marginTop="20px" marginLeft="10px">
              <ChakraProvider>
                <Menu>
                  <MenuButton
                    width="240px"
                    padding={2}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Flex>
                      <MenuItem>
                        <Checkbox>LocalBot</Checkbox>
                      </MenuItem>
                      <MenuItem ml={-20}>
                        <Checkbox>IBot</Checkbox>
                      </MenuItem>
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    <SimpleGrid columns={2} spacing={2} padding={2}>
                      <MenuItem>
                        <Checkbox>iMentor</Checkbox>
                      </MenuItem>
                      <MenuItem>
                        <Checkbox>iContent</Checkbox>
                      </MenuItem>
                      <MenuItem>
                        <Checkbox>Global</Checkbox>
                      </MenuItem>
                      <MenuItem>
                        <Checkbox>Others</Checkbox>
                      </MenuItem>
                    </SimpleGrid>
                  </MenuList>
                </Menu>
              </ChakraProvider>
            </Flex>
          </Box>

          <Flex width={460}>
            <Input
              marginTop="10px"
              placeholder="Search"
              onChange={handleSearchChange}
            />
            <Button onClick={handleSearch} colorScheme="blue" marginTop="10px">
              Search
            </Button>
          </Flex>
          <Container marginTop="20px" width="470px">
            {isSearching ? (
              <Box alignContent={Center}>Loading...</Box>
            ) : (
              <Carousel width={410}>
                <TableContainer>
                  <Table variant="simple">
                    <Tbody>
                      <Tr>
                        <Th>searchResults 1</Th>
                        <Td>
                          <i class="fas fa-file-pdf"></i>
                        </Td>
                      </Tr>
                      <Tr>
                        <Th>searchResults 2</Th>
                        <Td>
                          <i class="fas fa-file-word"></i>
                        </Td>
                      </Tr>
                      <Tr>
                        <Th>searchResults 3</Th>
                        <Td>
                          {" "}
                          <i class="fas fa-file-prescription"></i>
                        </Td>
                      </Tr>
                    </Tbody>
                    
                      <Tr>
                        <Th>searchResults 4</Th>
                        <Td>
                          {" "}
                          <i class="fas fa-file-image"></i>
                        </Td>
                      </Tr>
                   
                  </Table>
                </TableContainer>

                <TableContainer>
                  <Table variant="simple">
                    <Tbody>
                      <Tr>
                        <Th>searchResults 1</Th>
                        <Td>
                          <i class="fas fa-file-pdf"></i>
                        </Td>
                      </Tr>
                      <Tr>
                        <Th>searchResults 2</Th>
                        <Td>
                          <i class="fas fa-file-word"></i>
                        </Td>
                      </Tr>
                      <Tr>
                        <Th>searchResults 3</Th>
                        <Td>
                          {" "}
                          <i class="fas fa-file-prescription"></i>
                        </Td>
                      </Tr>
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>searchResults 4</Th>
                        <Td>
                          {" "}
                          <i class="fas fa-file-image"></i>
                        </Td>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>

                <TableContainer>
                  <Table variant="simple">
                    <Tbody>
                      <Tr>
                        <Th>searchResults 1</Th>
                        <Td>
                          <i class="fas fa-file-pdf"></i>
                        </Td>
                      </Tr>
                      <Tr>
                        <Th>searchResults 2</Th>
                        <Td>
                          <i class="fas fa-file-word"></i>
                        </Td>
                      </Tr>
                      <Tr>
                        <Th>searchResults 3</Th>
                        <Td>
                          {" "}
                          <i class="fas fa-file-prescription"></i>
                        </Td>
                      </Tr>
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>searchResults 4</Th>
                        <Td>
                          {" "}
                          <i class="fas fa-file-image"></i>
                        </Td>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
                {/* {searchResults.map((result, index) => (
                  <div key={index} onClick={() => handleCourseClick(result.link)}>
                    <img src={result.image} alt={`Course ${index + 1}`} />
                    <div style={{ padding: "20px" }}>
                      <Text fontSize="18px" fontWeight="bold">
                        {result.title}
                      </Text>
                      <Text marginBottom="10px">{result.description}</Text>
                      <ul>
                        {result.details.map((detail, i) => (
                          <li key={i}>{detail}</li>
                        ))}
                      </ul>
                    </div>
                    <p className="legend">{result.title}</p>
                  </div>
                ))} */}
              </Carousel>
            )}
            <Button mt={-10} ml={-50}>
              Submit
            </Button>
          </Container>

          <Box borderTop="2px solid grey">
            <Flex justifyContent={"space-between"}>
              <Text fontWeight={700} color={"grey"} mt={2}>
                iMENTOR
              </Text>
              <Input w={80} placeholder="Mentor Search" />
            </Flex>

            <Flex gap={5} mt={2}>
              <Card
                color={"grey"}
                height={120}
                w={140}
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="1px solid gray"
                position="relative"
              >
                <i
                  className="fas fa-share-nodes"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                  onClick={handleShareClick}
                ></i>
                <i
                  className="far fa-comment-dots"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    left: "10px",
                    fontSize: "1em",
                  }}
                ></i>
                <i
                  className={`far fa-star${isStarClicked ? " orange" : ""}`}
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                  onClick={handleStarClick}
                ></i>
                {isRatingVisible && rating > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      right: "10px",
                      textAlign: "center",
                    }}
                  >
                    {rating}/5
                  </div>
                )}
                <i
                  className="far fa-circle-dot"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    fontSize: "1em",
                  }}
                ></i>
                <i
                  className="far fa-circle-user"
                  style={{
                    fontSize: "4em",
                  }}
                ></i>
              </Card>
              <Card
                color={"grey"}
                height={120}
                w={140}
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="1px solid gray"
                position="relative"
              >
                <i
                  className="fas fa-share-nodes"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                  onClick={handleShareClick}
                ></i>
                <i
                  className="far fa-comment-dots"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    left: "10px",
                    fontSize: "1em",
                  }}
                ></i>
                <i
                  className={`far fa-star${isStarClicked ? " orange" : ""}`}
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                  onClick={handleStarClick}
                ></i>
                {isRatingVisible && rating > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      right: "10px",
                      textAlign: "center",
                    }}
                  >
                    {rating}/5
                  </div>
                )}
                <i
                  className="far fa-circle-dot"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    fontSize: "1em",
                  }}
                ></i>
                <i
                  className="far fa-circle-user"
                  style={{
                    fontSize: "4em",
                  }}
                ></i>
              </Card>
              <Card
                color={"grey"}
                height={120}
                w={140}
                display="flex"
                alignItems="center"
                justifyContent="center"
                border="1px solid gray"
                position="relative"
              >
                <i
                  className="fas fa-share-nodes"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                  onClick={handleShareClick}
                ></i>
                <i
                  className="far fa-comment-dots"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    left: "10px",
                    fontSize: "1em",
                  }}
                ></i>
                <i
                  className={`far fa-star${isStarClicked ? " orange" : ""}`}
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                  onClick={handleStarClick}
                ></i>
                {isRatingVisible && rating > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      right: "10px",
                      textAlign: "center",
                    }}
                  >
                    {rating}/5
                  </div>
                )}
                <i
                  className="far fa-circle-dot"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    fontSize: "1em",
                  }}
                ></i>
                <i
                  className="far fa-circle-user"
                  style={{
                    fontSize: "4em",
                  }}
                ></i>
              </Card>
            </Flex>

            <Flex mt={5} justifyContent={"space-between"}>
              <Link to="/mentors">
                <Button colorScheme="green" marginLeft="4px">
                  Let's get Started
                </Button>
              </Link>
              <Link to="/mentors">
                <Button colorScheme="green" marginLeft="10px">
                  Mentors
                </Button>
              </Link>
              <Link to="/mentors">
                <Button colorScheme="green" marginLeft="10px">
                  Disco Room
                </Button>
              </Link>
            </Flex>
          </Box>
        </Box>

        <Box maxWidth={500}>
          <YouTubeSearch />
          <ChakraProvider>
            <Box border="1px solid gray" padding={4} ml={6} mt={4}>
              <Flex gap={2}>
                {/* First Main Card with 9 Nested Cards */}
                <Card
                  color={"grey"}
                  height={85}
                  w={80}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border="1px solid gray"
                  position="relative"
                  padding={10}
                >
                  <Grid templateColumns="repeat(3, 1fr)" gap={1}>
                    {[...Array(9)].map((_, index) => (
                      <Card
                        key={index}
                        height={6}
                        w={8}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        border="1px solid gray"
                      >
                        {/* {index + 1} */}
                      </Card>
                    ))}
                  </Grid>
                </Card>

                {/* Second Main Card with 6 Nested Cards */}
                <Card
                  color={"grey"}
                  height={85}
                  w={80}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border="1px solid gray"
                  position="relative"
                  padding={8}
                >
                  <Grid templateColumns="repeat(3, 1fr)" gap={1}>
                    {[...Array(6)].map((_, index) => (
                      <Card
                        key={index}
                        height={6}
                        w={8}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        border="1px solid gray"
                      >
                        {/* {index + 1} */}
                      </Card>
                    ))}
                  </Grid>
                </Card>

                {/* Third Main Card with "+3" and ArrowRight */}
                <Card
                  color={"grey"}
                  height={85}
                  w={80}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border="1px solid gray"
                  position="relative"
                >
                  <Flex
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize="3xl" mb={2}>
                      +3
                    </Text>
                    <Icon as={ArrowRightIcon} w={8} h={6} />
                  </Flex>
                </Card>
              </Flex>
            </Box>
          </ChakraProvider>

          <Flex>
            <Button
              onClick={() => handleNewData(youTubeSearchData)}
              colorScheme="green"
              marginTop="20px"
              marginLeft="85px"
            >
              Subscribe
            </Button>
            <Button colorScheme="green" marginTop="20px" marginLeft="30px">
              Add to List
            </Button>
            <Button colorScheme="green" marginTop="20px" marginLeft="30px">
              Buy Now
            </Button>
          </Flex>
          <span style={{ marginLeft: "60px", color: "green" }}>{newData}</span>
        </Box>
      </Flex>
      <ShareComponent isOpen={isShareOpen} onClose={closeShareModal} />
    </Box>
  );
};

export default MiddleComponent;
