import { Box, Button, Text } from "@chakra-ui/react";
import Styles from "./Undo.module.css"
import { getID, setID } from "../../siteConfig";
import { decrypt, encrypt } from "../../middleware/auth";
import messageService from "../MessageService/Index";
import ResourceLinks from "../ResourceLinks/ResourceLinks";


const UndoPopup = (props) => {
    const { setShowUndoPopup, showUndoPopup } = props;


    console.log('showUndoPopup :>> ', showUndoPopup);

    const handleUndoChanges = () => {
        try {
            if (getID('recycleBin')) {
                const recycleBin = JSON.parse(decrypt(getID('recycleBin')));
                const recycleArray = recycleBin[showUndoPopup?.data?.recycleKey];
                const [_title, _link] = showUndoPopup?.data?.link.split("~");
                const updatedResourcesLinks = recycleArray.filter((items) => {
                    const [title, link] = items?.titleLink.split("~");
                    return title !== _title;
                });
                const updateBin = { ...recycleBin, resourceLinks: updatedResourcesLinks }
                setID('recycleBin', encrypt(JSON.stringify(updateBin)))
                const fetchFromSession = recycleArray.find((items) => {
                    const [title, link] = items?.titleLink.split("~");
                    return title === _title;
                });
                //sending to course detail page
                if (!!fetchFromSession && JSON.stringify(fetchFromSession) !== "{}") {
                    messageService.sendMessage('undoPopup', fetchFromSession, 'courseDetailPage')
                }
                setShowUndoPopup((prev) => {
                    return { ...prev, show: false }
                })
            }
        } catch (error) {
            console.error(error);
        }
    }


    return <Box
        className={`${Styles?.popupContainer} box-shadow ${!!showUndoPopup?.show ? Styles?.ContainerActive : ""}`}>
        <Box className={Styles?.popupContent}>
            <Box className={Styles?.popupHeader}>
                <Text>
                    You can undo your changes!
                </Text>
            </Box>
            <Box className={Styles?.popupFooter}>
                <Button className={Styles?.updateButton} onClick={handleUndoChanges} bgColor="#6e31ec" color={"#ffffff"}>Undo</Button>
                <Button onClick={() => {
                    setShowUndoPopup(false)
                }}>
                    Cancel
                </Button>
            </Box>

        </Box>
    </Box>
}

export default UndoPopup;