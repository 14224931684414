import Men1 from "../../../img/Male 1 G.svg";
import Men2 from "../../../img/Male 2 E.svg";
import Men3 from "../../../img/Male 3 E.svg";
import Female1 from "../../../img/Female 1 E.svg";
import Female2 from "../../../img/Female 2 G.svg";
import Female3 from "../../../img/Female 3 G.svg";

const candidates = [
  {
    id: 1,
    name: "Kavya",
    title: "UX Designer",
    location: "San Francisco, CA",
    experience: "3 years",
    skills: ["Sketch", "Figma", "User Research"],
    description:
      "I'm a UX/UI Designer with expertise in designing user interfaces and improving user experience through design.",
    profilePicture: Female1,
    workingExperience: [
      {
        role: "Senior UX Designer",
        company: "Tech Corp",
        duration: "Jan 2021 - Present",
      },
      {
        role: "Junior UX Designer",
        company: "Innovate LLC",
        duration: "Aug 2018 - Dec 2020",
      },
    ],
  },
  {
    id: 2,
    name: "Ajay",
    title: "Software Engineer",
    location: "New York, NY",
    experience: "5 years",
    skills: ["JavaScript", "React", "Node.js"],
    description:
      "I'm a Software Engineer with a strong background in web development and full-stack technologies.",
    profilePicture: Men1,
    workingExperience: [
      {
        role: "Senior Software Engineer",
        company: "Data Inc.",
        duration: "Feb 2019 - Present",
      },
      {
        role: "Software Engineer",
        company: "Web Solutions",
        duration: "Jul 2015 - Jan 2019",
      },
    ],
  },
  {
    id: 3,
    name: "Kavya",
    title: "UX Designer",
    location: "San Francisco, CA",
    experience: "3 years",
    skills: ["Sketch", "Figma", "User Research"],
    description:
      "I'm a UX/UI Designer with expertise in designing user interfaces and improving user experience through design.",
    profilePicture: Female2,
    workingExperience: [
      {
        role: "Senior UX Designer",
        company: "Tech Corp",
        duration: "Jan 2021 - Present",
      },
      {
        role: "Junior UX Designer",
        company: "Innovate LLC",
        duration: "Aug 2018 - Dec 2020",
      },
    ],
  },
  {
    id: 4,
    name: "Ajay",
    title: "Software Engineer",
    location: "New York, NY",
    experience: "5 years",
    skills: ["JavaScript", "React", "Node.js"],
    description:
      "I'm a Software Engineer with a strong background in web development and full-stack technologies.",
    profilePicture: Men2,
    workingExperience: [
      {
        role: "Senior Software Engineer",
        company: "Data Inc.",
        duration: "Feb 2019 - Present",
      },
      {
        role: "Software Engineer",
        company: "Web Solutions",
        duration: "Jul 2015 - Jan 2019",
      },
    ],
  },
  {
    id: 5,
    name: "Kavya",
    title: "UX Designer",
    location: "San Francisco, CA",
    experience: "3 years",
    skills: ["Sketch", "Figma", "User Research"],
    description:
      "I'm a UX/UI Designer with expertise in designing user interfaces and improving user experience through design.",
    profilePicture: Female3,
    workingExperience: [
      {
        role: "Senior UX Designer",
        company: "Tech Corp",
        duration: "Jan 2021 - Present",
      },
      {
        role: "Junior UX Designer",
        company: "Innovate LLC",
        duration: "Aug 2018 - Dec 2020",
      },
    ],
  },
  {
    id: 6,
    name: "Ajay",
    title: "Software Engineer",
    location: "New York, NY",
    experience: "5 years",
    skills: ["JavaScript", "React", "Node.js"],
    description:
      "I'm a Software Engineer with a strong background in web development and full-stack technologies.",
    profilePicture: Men3,
    workingExperience: [
      {
        role: "Senior Software Engineer",
        company: "Data Inc.",
        duration: "Feb 2019 - Present",
      },
      {
        role: "Software Engineer",
        company: "Web Solutions",
        duration: "Jul 2015 - Jan 2019",
      },
    ],
  },
  {
    id: 7,
    name: "Kavya",
    title: "UX Designer",
    location: "San Francisco, CA",
    experience: "3 years",
    skills: ["Sketch", "Figma", "User Research"],
    description:
      "I'm a UX/UI Designer with expertise in designing user interfaces and improving user experience through design.",
    profilePicture: Female3,
    workingExperience: [
      {
        role: "Senior UX Designer",
        company: "Tech Corp",
        duration: "Jan 2021 - Present",
      },
      {
        role: "Junior UX Designer",
        company: "Innovate LLC",
        duration: "Aug 2018 - Dec 2020",
      },
    ],
  },
  {
    id: 8,
    name: "Ajay",
    title: "Software Engineer",
    location: "New York, NY",
    experience: "5 years",
    skills: ["JavaScript", "React", "Node.js"],
    description:
      "I'm a Software Engineer with a strong background in web development and full-stack technologies.",
    profilePicture: Men3,
    workingExperience: [
      {
        role: "Senior Software Engineer",
        company: "Data Inc.",
        duration: "Feb 2019 - Present",
      },
      {
        role: "Software Engineer",
        company: "Web Solutions",
        duration: "Jul 2015 - Jan 2019",
      },
    ],
  },
  {
    id: 9,
    name: "Kavya",
    title: "UX Designer",
    location: "San Francisco, CA",
    experience: "3 years",
    skills: ["Sketch", "Figma", "User Research"],
    description:
      "I'm a UX/UI Designer with expertise in designing user interfaces and improving user experience through design.",
    profilePicture: Female3,
    workingExperience: [
      {
        role: "Senior UX Designer",
        company: "Tech Corp",
        duration: "Jan 2021 - Present",
      },
      {
        role: "Junior UX Designer",
        company: "Innovate LLC",
        duration: "Aug 2018 - Dec 2020",
      },
    ],
  },
  {
    id: 10,
    name: "Ajay",
    title: "Software Engineer",
    location: "New York, NY",
    experience: "5 years",
    skills: ["JavaScript", "React", "Node.js"],
    description:
      "I'm a Software Engineer with a strong background in web development and full-stack technologies.",
    profilePicture: Men3,
    workingExperience: [
      {
        role: "Senior Software Engineer",
        company: "Data Inc.",
        duration: "Feb 2019 - Present",
      },
      {
        role: "Software Engineer",
        company: "Web Solutions",
        duration: "Jul 2015 - Jan 2019",
      },
    ],
  },
];

export default candidates;
