import React from 'react'
import './JobSearchResult.css'
import img8 from '../../img/image8 JobSearchResult.svg'
import img9 from '../../img/image9 JobSearchResult.svg'
import img10 from '../../img/image10 JobSearchResult.svg'
import img11 from '../../img/image11 JobSearchResult.jpg'
import img13 from '../../img/img13.svg'
import { AiOutlineHome } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { CiBookmark } from "react-icons/ci";
import { CiHeart } from "react-icons/ci";
import { MdOutlineDrafts } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";



function JobSearchResult() {
  return (
    <>
      <div className='main-container-JobSearchResult'>
        <div className='leftside-JobSearchResult'>
          <div className='leftside-div1 active'>
            <div className='leftside-div1-mainlogodiv'>
              <div className='leftside-div1-logo'>
                <img src={img8} style={{width:"100%", height:"100%"}}></img>
              </div>
              <div className='leftside-div1-jobtitle'>
                <h1 className='leftside-div1-jobtitle-h1'>UI/UX Designer</h1>
                <h1 className='leftside-div1-jobtitle-h2'>10 - 11 LPA</h1>
              </div>
            </div>
            <div className='leftside-div1-small-logo-div'>
              <div className='leftside-div1-small-logo'>
                <AiOutlineHome fill='#9095A1FF' width="100%" height="100%"/>
              </div>
              <div className='leftside-div1-location-div'>
                <h1 className='leftside-div1-jobtitle-h3'>India</h1>
              </div>
            </div>
            <div className='leftside-div1-small-logo-div'>
              <div className='leftside-div1-small-logo'>
                <CiLocationOn fill='#9095A1FF' width="100%" height="100%"/>
              </div>
              <div className='leftside-div1-location-div'>
                <h1 className='leftside-div1-jobtitle-h3'>Mumbai</h1>
              </div>
            </div>
            <div className='leftside-div1-small-logo-div'>
              <div className='leftside-div1-small-logo'>
                <CiBookmark fill='#9095A1FF' width="100%" height="100%"/>
              </div>
              <div className='leftside-div1-location-div'>
                <h1 className='leftside-div1-jobtitle-h3'>Onsite</h1>
              </div>
            </div>
          </div>
          <div className='leftside-div1'>
            <div className='leftside-div1-mainlogodiv'>
              <div className='leftside-div1-logo'>
                <img src={img9} style={{width:"100%", height:"100%"}}></img>
              </div>
              <div className='leftside-div1-jobtitle'>
                <h1 className='leftside-div1-jobtitle-h1'>UI/UX Designer</h1>
                <h1 className='leftside-div1-jobtitle-h2'>10 - 11 LPA</h1>
              </div>
            </div>
            <div className='leftside-div1-small-logo-div'>
              <div className='leftside-div1-small-logo'>
                <AiOutlineHome fill='#9095A1FF' width="100%" height="100%"/>
              </div>
              <div className='leftside-div1-location-div'>
                <h1 className='leftside-div1-jobtitle-h3'>India</h1>
              </div>
            </div>
            <div className='leftside-div1-small-logo-div'>
              <div className='leftside-div1-small-logo'>
                <CiLocationOn fill='#9095A1FF' width="100%" height="100%"/>
              </div>
              <div className='leftside-div1-location-div'>
                <h1 className='leftside-div1-jobtitle-h3'>Mumbai</h1>
              </div>
            </div>
            <div className='leftside-div1-small-logo-div'>
              <div className='leftside-div1-small-logo'>
                <CiBookmark fill='#9095A1FF' width="100%" height="100%"/>
              </div>
              <div className='leftside-div1-location-div'>
                <h1 className='leftside-div1-jobtitle-h3'>Onsite</h1>
              </div>
            </div>
          </div>
          <div className='leftside-div1'>
            <div className='leftside-div1-mainlogodiv'>
              <div className='leftside-div1-logo'>
                <img src={img10} style={{width:"100%", height:"100%"}}></img>
              </div>
              <div className='leftside-div1-jobtitle'>
                <h1 className='leftside-div1-jobtitle-h1'>UI/UX Designer</h1>
                <h1 className='leftside-div1-jobtitle-h2'>10 - 11 LPA</h1>
              </div>
            </div>
            <div className='leftside-div1-small-logo-div'>
              <div className='leftside-div1-small-logo'>
                <AiOutlineHome fill='#9095A1FF' width="100%" height="100%"/>
              </div>
              <div className='leftside-div1-location-div'>
                <h1 className='leftside-div1-jobtitle-h3'>India</h1>
              </div>
            </div>
            <div className='leftside-div1-small-logo-div'>
              <div className='leftside-div1-small-logo'>
                <CiLocationOn fill='#9095A1FF' width="100%" height="100%"/>
              </div>
              <div className='leftside-div1-location-div'>
                <h1 className='leftside-div1-jobtitle-h3'>Mumbai</h1>
              </div>
            </div>
            <div className='leftside-div1-small-logo-div'>
              <div className='leftside-div1-small-logo'>
                <CiBookmark fill='#9095A1FF' width="100%" height="100%"/>
              </div>
              <div className='leftside-div1-location-div'>
                <h1 className='leftside-div1-jobtitle-h3'>Onsite</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='rightside-JobSearchResult'>
          <div className='rightside-div1'>
            <div className='rightside-div1-leftdiv'>
              <h3 className='rightside-div1-leftdiv-h1'>UI / UX Designer</h3>
              <h3 className='rightside-div1-leftdiv-h2'>10 - 11 LPA</h3>
              <div className='rightside-div1-leftdiv-icondiv'>
                  <div className='leftside-div1-small-logo'>
                    <AiOutlineHome fill='#9095A1FF' width="100%" height="100%"/>
                  </div>
                  <div className='leftside-div1-location-div'>
                    <h1 className='leftside-div1-jobtitle-h3'>India</h1>
                  </div>
                    <div className='leftside-div1-small-logo'>
                      <CiLocationOn fill='#9095A1FF' width="100%" height="100%"/>
                    </div>
                    <div className='leftside-div1-location-div'>
                      <h1 className='leftside-div1-jobtitle-h3'>chennai</h1>
                    </div>
              </div>
              <div className='rightside-div1-leftdiv-buttondiv'>
                <button className='rightside-div1-leftdiv-button'>Mid-Senior Level</button>
                <button className='rightside-div1-leftdiv-button'>Full Time</button>
                <button className='rightside-div1-leftdiv-button'>Remote</button>
              </div>
            </div>
           
            <div className='rightside-div1-rightdiv'>
              <div className='rightside-div1-rightdiv-buttondiv'>
                <button className='rightside-div1-rightdiv-button1'>...</button>
                <button className='rightside-div1-rightdiv-button2'><span><CiHeart fill='#6D31EDFF' style={{width:"15px", height:"15px"}}/></span>Save</button>
                <a href='/job-user-profile'><button className='rightside-div1-rightdiv-button3'>Apply Now</button></a>
              </div>
              <h4 className='rightside-div1-JobSearchResult-h1'>Posted 1h Ago</h4>
            </div>
          </div>
          <div className='rightside-div2-JobSearchResult'>
            <div className='rightside-div2-JobSearchResult-leftdiv'>
              <h3 className='rightside-div2-JobSearchResult-h1'>Contact recruiter</h3>
              <div className='rightside-div2-JobSearchResult-photoandtextdiv'>
                <div className='rightside-div2-JobSearchResult-photodiv'>
                  <img src={img11} style={{width:"100%", height:"100%"}}></img>
                </div>
                <div className='rightside-div2-JobSearchResult-textdiv'>
                  <div style={{display:'flex'}}>
                    <span><h5 className='rightside-div2-JobSearchResult-text1'>Suganya</h5></span><span style={{marginLeft:"25px"}}><h5 className='rightside-div2-JobSearchResult-text2'>Job poster</h5></span>
                  </div>  
                  <h3 className='rightside-div2-JobSearchResult-text3'>Hiring Manager</h3>
                </div>
              </div>
            </div>
            <div className='rightside-div2-JobSearchResult-rightdiv'>
              <span><MdOutlineDrafts fill='#6D31EDFF' width="8px" height="8px"/></span><button className='rightside-div2-JobSearchResult-text'>Message</button>
            </div>
          </div>
          <div className='rightside-div3'>
            <h4 className='rightside-div3-h1'>Job Description</h4> 
            <h4 className='rightside-div3-h2'>A UI/UX Designer plays avital role in developing intuitive and engaging userinterfaces for digital products. Their responsibilties inlude
            understanding user behaviors, creating wireframes and prototypes, and implementing visually appealing design elements</h4> 
          </div>
          <div className='rightside-div4 active'>
            <div className='rightside-div4-div1'>
              <h5 className='rightside-div4-div1-h1'>About Company</h5>
              <button className='rightside-div4-div1-button'>View company profile</button>
            </div>
            <h5 className='rightside-div4-div1-h2'>BUSINESS OPTIMA</h5>
            <h5 className='rightside-div4-div1-h3'>Business Optima offers strategic solutions to multi verticals from business operations to tech operations. The company is backed by industry experts. As a leading force in the realm of business consultancy, we empower organizations to navigate the complexities of the modern business landscape with confidence and foresight using AI tools and latest technologies.</h5>
            <div className='rightside-div4-logodiv'>
              <div className='rightside-div4-logodiv-leftdiv'>
                <span><img src={img13} width='14px' height='14px' ></img></span><span style={{paddingLeft:"5px"}}>Outsource</span>
              </div>
              <div className='rightside-div4-logodiv-rightdiv'>
                <span><FaRegUserCircle fill='#9095A1FF' width='14px' height='14px' /></span><span style={{paddingLeft:"5px"}}>100 - 300 employee</span>
              </div>
              {/* <div style={{display:"flex"}}><img src={img13} style={{width:"10px", height:"10px"}}></img><h6 className='rightside-div4-div1-h1'>Outsource</h6></div> */}
            </div>
            <div className='rightside-div4-logodiv'>
              <div className='rightside-div4-logodiv-leftdiv'>
                <span><IoLocationOutline fill='#9095A1FF'  width='14px' height='14px'/></span><span style={{paddingLeft:"5px"}}>India</span>
              </div>
              <div className='rightside-div4-logodiv-rightdiv'>
                <span><CiCalendar  fill='#9095A1FF' width='14px' height='14px' /></span><span style={{paddingLeft:"5px", marginRight:"60px"}}>Mon  -  Fri</span>
              </div>
            </div>
          </div>
        </div>
      </div>       
    </>
  )
}

export default JobSearchResult