import React, { useState, useEffect } from "react";
import axios from "axios";
import { Input, Card, Skeleton, message, Space, Button, Flex } from "antd";
import imgRemote from "../img/circle.png";

const { Search } = Input;
const { Meta } = Card;

const YouTubeSearch = ({ onVideoSelect }) => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [videoIndex, setVideoIndex] = useState(0);
  const videosPerPage = 6;
  const apiKey = "AIzaSyBWs_RprsJw7_eQl84PQL2eZ4zIMgl069U";

  useEffect(() => {
    fetchDefaultVideos();
  }, []);

  const fetchDefaultVideos = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search`,
        {
          params: {
            part: "snippet",
            type: "video",
            maxResults: 30,
            q: "default",
            key: apiKey,
          },
        }
      );

      setSearchResults(response.data.items);
      message.success(`Found ${response.data.items.length} default videos.`);
    } catch (error) {
      console.error("Error fetching default YouTube videos:", error);
      message.error(
        "An error occurred while fetching default videos. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (value) => {
    if (!value.trim()) {
      message.error("Please enter a valid search term.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search`,
        {
          params: {
            part: "snippet",
            type: "video",
            maxResults: 50,
            q: value,
            key: apiKey,
          },
        }
      );

      setSearchResults(response.data.items);
      message.success(`Found ${response.data.items.length} videos.`);
    } catch (error) {
      console.error("Error fetching YouTube search results:", error);
      message.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    if (
      pageNumber >= 1 &&
      pageNumber <= Math.ceil(searchResults.length / videosPerPage)
    ) {
      setCurrentPage(pageNumber);
    }
  };

  const handleImageClick = async () => {
    const newIndex = (videoIndex + 1) % searchResults.length;
    setVideoIndex(newIndex);
    setSelectedVideoId(searchResults[newIndex].id.videoId);
    setRotationAngle(rotationAngle - 90);

    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search`,
        {
          params: {
            part: "snippet",
            type: "video",
            maxResults: 50,
            q: "random",
            key: apiKey,
          },
        }
      );

      setSearchResults(response.data.items);
      message.success(`Found ${response.data.items.length} new videos.`);
    } catch (error) {
      console.error("Error fetching YouTube search results:", error);
      message.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const totalPages = Math.ceil(searchResults.length / videosPerPage);

  const renderResults = () => {
    const startIndex = (currentPage - 1) * videosPerPage;
    const endIndex = startIndex + videosPerPage;
    const videosToDisplay = searchResults.slice(startIndex, endIndex);

    return (
      <div className="video-container">
        {videosToDisplay.map((result) => (
          <div key={result.id.videoId} className="video-card">
             <Card
              hoverable
              style={{ width: 150, height: 140 }}
              cover={
                <img
                  alt={result.snippet.title}
                  src={result.snippet.thumbnails.high.url}
                  style={{ width: 160, height: 85, cursor: "pointer" }}
                  onClick={() => setSelectedVideoId(result.id.videoId)}
                />
              }
            >
              <Meta
                title={result.snippet.title}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
            </Card>
          </div>
        ))}
      </div>
    );
  };

  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = 5;
    const half = Math.floor(maxPagesToShow / 2);

    const startPage = Math.max(1, currentPage - half);
    const endPage = Math.min(totalPages, currentPage + half);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          type={i === currentPage ? "primary" : "default"}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Button>
      );
    }

    return (
      <div className="pagination-container">
        <Button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </Button>
        {pages}
        <Button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </Button>
      </div>
    );
  };

  return (
    <>
      <style>
        {`
          .video-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
          }

          .video-card {
            flex: 1 0 30%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .pagination-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2px;
            margin-top: 10px;
          }

          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <div className="w-full flex justify-center items-center mt-10">
        <div className="container">
          <Space direction="vertical" size="middle" style={{ width: "480px" }}>
            <Flex>
              <Search
                placeholder="Search for videos..."
                enterButton="Search"
                size="large"
                loading={isLoading}
                onSearch={handleSearch}
              />
              <img
                src={imgRemote}
                alt="Remote Image"
                style={{
                  width: "75px",
                  height: "75px",
                  borderRadius: "50%",
                  zIndex: 15,
                  cursor: "pointer",
                  transform: `rotate(${rotationAngle}deg)`,
                  transition: "transform 0.5s ease",
                  marginTop: "-15px",
                  marginLeft: "20px",
                }}
                onClick={handleImageClick}
              />
            </Flex>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "2px",
                justifyContent: "center",
                maxHeight: "280px",
                overflowY: "auto",
              }}
            >
              {isLoading ? <Skeleton active /> : renderResults()}
            </div>
            <div style={{ textAlign: "center", marginTop: "-15px" }}>
              {renderPagination()}
            </div>
          </Space>
        </div>
        <div style={{ marginLeft: "60px", width: "80%", height: "120px" }}>
          {selectedVideoId && (
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${selectedVideoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
            ></iframe>
          )}
        </div>
      </div>
    </>
  );
};

export default YouTubeSearch;
