// PopOver.js
import React, { useState,useEffect } from "react";
import { Card, Flex} from "@chakra-ui/react";
import LeftComponent from "../../components/MasterBot/LeftComponent ";
import MiddleComponent from  "../../components/MasterBot/MiddleComponent ";
import RightComponent from  "../../components/MentorGuidance/RightComponent";

function PopOver({ youTubeSearchData }) {
    const [isCardOpen, setIsCardOpen] = useState(false);
    const [newData, setNewData] = useState('');
    const [maxChar, SetMaxChar] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const handleImageClick = () => {
      setIsCardOpen(!isCardOpen);
    };
  
    const handleCloseButtonClick = () => {
      setIsCardOpen(false);
    };
  
    const handleNewData = (data) => {
      setNewData('Search data Received');
    };
  
    const [newOption, setNewOption] = useState('');
    const [options, setOptions] = useState([
      { value: 'option1', label: 'Learn React in 2 days' },
      { value: 'option2', label: 'Build a personal website' },
    ]);
  
    const handleTextareaChange = (event) => {
      const inputValue = event.target.value;
      if (inputValue.length <= 400) {
        setNewOption(inputValue);
      } else {
        SetMaxChar('Maximum character limit (400) exceeded!');
      }
    };
  
    const handleAddOption = () => {
      if (newOption.trim() !== '') {
        const newValue = `option${options.length + 1}`;
        const newLabel = newOption.trim();
        const newOptionObj = { value: newValue, label: newLabel };
        setOptions([...options, newOptionObj]);
        setNewOption('');
      }
    };
  
    useEffect(() => {
      console.log('Updated YouTube Search Data:', youTubeSearchData);
    }, [youTubeSearchData]);
  
    return (
      <div style={{ position: 'fixed', bottom: '60px', left: '120px', zIndex: '60' }}>
        <LeftComponent handleImageClick={handleImageClick} />
  
        {isCardOpen && (
          <div
            className="card"
            style={{
              position: 'absolute',
              bottom: '60px',
              left: '0',
              zIndex: '100',
              padding: '0px',
              backgroundColor: 'green',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              height:"580px"
            }}
          >
            <Card size="lg" padding="20px" display="flex" flexDirection="row" boxShadow="md">
              <Flex flexDirection="row">
                <MiddleComponent
                  newOption={newOption}
                  options={options}
                  handleTextareaChange={handleTextareaChange}
                  handleAddOption={handleAddOption}
                  maxChar={maxChar}
                  handleCloseButtonClick={handleCloseButtonClick}
                  handleNewData={handleNewData}
                  youTubeSearchData={youTubeSearchData}
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  totalPages={5}
                  newData={newData}
                />
                <RightComponent/>
              </Flex>
            </Card>
          </div>
        )}
      </div>
    );
  }
  
  export default PopOver;
