import React, { useState } from "react";
import {
  Box,
  Container,
  HStack,
  VStack,
  Input,
  Avatar,
  Text,
  Button,
  SimpleGrid,
  Badge,
  AvatarBadge,
} from "@chakra-ui/react";
import { IoBag } from "react-icons/io5";
import StarRating from "../../../components/Mentors/ratingFunctionality";

const CandidateList = ({ candidates, onSelectCandidate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const candidatesPerPage = 8;

  const totalPages = Math.ceil(candidates.length / candidatesPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentCandidates = candidates.slice(
    (currentPage - 1) * candidatesPerPage,
    currentPage * candidatesPerPage
  );

  return (
    <VStack align="start" spacing={4}>
      {currentCandidates.map((candidate) => (
        <HStack
          key={candidate.id}
          p={4}
          borderWidth="1px"
          borderRadius="md"
          w="full"
          onClick={() => onSelectCandidate(candidate)}
          cursor="pointer"
        >
          <Avatar src={candidate.profilePicture}>
            <AvatarBadge boxSize="1em" bg="green.500" />
          </Avatar>
          <Box>
            <Text fontWeight="bold" display="flex" marginBottom="0px">
              {candidate.name}{" "}
              <Badge
                fontSize="10px"
                border="1px solid #15ABFFFF"
                color="#15ABFFFF"
                borderRadius="10px"
                marginLeft="10px"
                display="flex"
                alignItems="center"
                height="20px"
              >
                <IoBag />
                Open for work
              </Badge>
              <Box
                marginLeft="40px"
                as="button"
                height="25px"
                lineHeight="1.2"
                transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                border="1px"
                px="8px"
                borderRadius="5px"
                fontSize="10px"
                fontWeight="semibold"
                bg="#F5F1FEFF"
                color="#6D31EDFF"
                _hover={{ bg: "white" }}
                _active={{
                  bg: "white",
                  transform: "scale(0.98)",
                  borderColor: "#6D31EDFF",
                }}
                _focus={{
                  boxShadow: "0 0 1px 2px #6D31EDFF, 0 1px 1px #6D31EDFF",
                }}
              >
                Send message
              </Box>
              <Button
                fontSize="10px"
                height="25px"
                marginLeft="10px"
                bgColor="#6D31EDFF"
                borderRadius="5px"
                color="white"
                _hover={{ bg: "#F5F1FEFF", color: "#6D31EDFF" }}
              >
                Connect
              </Button>
            </Text>
            <StarRating totalStars={5} />
            <Text fontSize="14px">
              {candidate.title} &nbsp; | &nbsp; {candidate.location}
            </Text>

            <HStack spacing={2} wrap="wrap" marginTop="10px">
              {candidate.skills.map((skill, index) => (
                <Badge key={index} borderRadius="10px">
                  {skill}
                </Badge>
              ))}
            </HStack>
          </Box>
        </HStack>
      ))}
      <HStack spacing={4} justify="center" w="full">
        <Button
          onClick={() => handleClick(currentPage - 1)}
          isDisabled={currentPage === 1}
        >
          Previous
        </Button>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index + 1}
            onClick={() => handleClick(index + 1)}
            isActive={currentPage === index + 1}
          >
            {index + 1}
          </Button>
        ))}
        <Button
          onClick={() => handleClick(currentPage + 1)}
          isDisabled={currentPage === totalPages}
        >
          Next
        </Button>
      </HStack>
    </VStack>
  );
};

export default CandidateList;
