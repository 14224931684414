import React, { useState } from 'react'
import './JobAndPlacementPage1.css'
import './Responsive CSS for jobpage.css'
import img1 from '../../img/img1_jobandplacement.jpg'
import img2 from '../../img/img2_jobandplacement.png'
import img3 from '../../img/img3_jobandplacement.png'
import img4 from '../../img/img4_jobandplacement.svg'
import img5 from '../../img/img5_jobandplacement.png'
import img7 from '../../img/img7_jobandplacement.jpg'


import { IoIosAdd } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { CiFileOn } from "react-icons/ci";
import { IoCloseCircleSharp } from "react-icons/io5";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    useDisclosure,
} from '@chakra-ui/react'

function JobAndPlacementPage1() {

    const { isOpen, onOpen, onClose } = useDisclosure();


    // Upload File Handling Functionality

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('Select')

    const handleFileSet = (e) =>{
        const ourFileName = (e.target.files[0])?.name
        if(ourFileName){
            const file = e.target.files[0];
            setSelectedFile(file);
            setFileName(ourFileName)
        }else{
            setSelectedFile(null)
            setFileName('Select')
        }
    }


    // Remove File Functionality

    const RemoveFile = (e) =>{
        setFileName('Select')
        selectedFile(null)
    }





    // CV Preview Functionality

    const [previewUrl, setPreviewUrl] = useState('');

    // Function to handle file selection
    const handleFileUpload = (e) => {
        const file = selectedFile;
        if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
        }
    };



    // Remove Preview functionality

    const removePreview = (e)=>{
        setPreviewUrl(" ")
    }





    // Add Professional Skills Functionality.

    const [skills, setSkills] = useState([])

    const AddSkills = ()=>{
        const GivenSkill = document.getElementById('skill_input_box').value
        if(!GivenSkill==""){
            const NewSkillAfterSplit = GivenSkill.split(",")
            setSkills([...skills, ...NewSkillAfterSplit])            
            const skilladdingsection = document.getElementById('skilladdingsection').style.display='none'
        }else{
            const skilladdingsection = document.getElementById('skilladdingsection').style.display='none'
        }
    }

    // Function to handle removing a skill by index
    const RemoveSkill = (index) => {
      const updatedSkills = [...skills];
      updatedSkills.splice(index, 1);
      setSkills(updatedSkills);
    };

    

    const handleAdd = (e)=>{
        const skilladdingsection = document.getElementById('skilladdingsection').style.display='block'
    }


    return (
        <div className='main_div_JobAndPlacementPage1'>
        {/* **********************div1 Code************************ */}
            <div className='div1_JobAndPlacementPage1'>
                <div className='div1_leftside_JobAndPlacementPage1'>
                    <h4 className='div1_leftside_name'>Full Name</h4>
                    <div className='div1_leftside_enternamediv'>
                        <img src={img1} className='div1_leftside_img1'></img>
                        <input type='text' placeholder='Enter Your Name' className='div1_leftside_input1'/>
                    </div>
                    <h3 className='div1_leftside_JobAndPlacementPage1_h1'>Get a Virtual Offer</h3>
                    <h5 className='div1_leftside_JobAndPlacementPage1_h2'>Find how valuable your CV is and what trainings and </h5>
                    <h5 className='div1_leftside_JobAndPlacementPage1_h2'>jobs suitsyour career growth</h5>
                    <div className='div1-leftside_JobAndPlacementPage1_dropCVdiv'>
                        <div className='JobAndPlacementPage1_dropCVdiv_div1'>
                            <h5 className='JobAndPlacementPage1_dropCVheading'>Drop your CV <span style={{float:"right",cursor:"pointer"}}><IoClose fill='#9095A1FF'/></span></h5>
                            <label className='JobAndPlacementPage1_CVselectingarea' for="fileselect">
                                <input type='file' id='fileselect' style={{display:"none"}} onChange={handleFileSet}/>
                                <span style={{cursor:"pointer"}}><CiFileOn fill='#171A1FFF' style={{width:"30px", height:"30px"}}/></span>
                                    <span style={{fontWeight:"bold", fontSize:"13px", color:"#171A1FFF"}}>{fileName}</span>
                                    <span style={{cursor:"pointer"}}><IoCloseCircleSharp fill='#DE3B40FF' id='closeFilebtn'/></span>
                            </label>
                            <div className='JobAndPlacementPage1_buttonDiv'>
                                <span style={{float:"right"}}>
                                    <span><button className='JobAndPlacementPage1_clearBtn' onClick={removePreview}>Clear</button></span>
                                    <span><button className='JobAndPlacementPage1_uploadBtn' onClick={handleFileUpload}>Upload</button></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>              
                <div className='div1_middleDiv_JobAndPlacementPage1' style={{backgroundImage:`url(${img7})`,backgroundSize: 'cover', backgroundPosition: 'center'}}>
                {previewUrl && (
                    <img src={previewUrl} style={{width:"100%", height:"100%", boxShadow: "0px 4px 50px #1f212510, 0px 0px 1px #1d212757"}}></img>
                )}
                </div>
                <div className='div1_rightside_JobAndPlacementPage1'>
                    <h3 className='div1_rightside_h1'>Hey Kavya Shree,</h3>
                    <h1 className='div1_rightside_h2'>Offers for</h1>
                    <h1 className='div1_rightside_h2'>you!!</h1>
                    <a href='/resumeReview'><button className='div1_rightside_getOfferBtn'>Get Offer Now</button></a>
                </div>
            </div>
            {/* ****************************div2 code**************************** */}
            <div className='div2_JobAndPlacementPage1'>
                <div className='div2_leftside_JobAndPlacementPage1'>
                    <div className='div2_leftside_JobAndPlacementPage1_professionalSkillDiv'>
                        <div className='div2_leftside_JobAndPlacementPage1_professionalSkillDiv1'>
                            <h4 className='div2_leftside_JobAndPlacementPage1_h1'>Input your skills and find the matches</h4>
                            <h4 className='div2_leftside_JobAndPlacementPage1_h2'>Professional Skills</h4>
                            <div className='div2_leftside_JobAndPlacementPage1_skilldiv'>
                                {/* <button className='div2_leftside_JobAndPlacementPage1_skill'>UX Research <span style={{float:"right",cursor:"pointer"}}><IoClose fill='#FFFFFFFF' width="25px" height="25px"/></span></button>
                                <button className='div2_leftside_JobAndPlacementPage1_skill'>UI Design <span style={{float:"right",cursor:"pointer"}}><IoClose fill='#FFFFFFFF'/></span></button>
                                <button className='div2_leftside_JobAndPlacementPage1_skill'> Web Design <span style={{float:"right",cursor:"pointer"}}><IoClose fill='#FFFFFFFF'/></span></button> */}
                                
                                {
                                    skills.map((skill, index)=>{
                                        return(
                                            <>
                                                <button className='div2_leftside_JobAndPlacementPage1_skill'>{skill}<span style={{float:"right",cursor:"pointer"}}><IoClose fill='#FFFFFFFF' onClick={()=>RemoveSkill(index)}/></span></button>
                                            </>
                                        )
                                    })
                                }
                                {/* onClick={onOpen} */}
                               
                                <button className='div2_leftside_JobAndPlacementPage1_AddBtn' onClick={handleAdd}><span style={{float:"right",cursor:"pointer"}}><IoIosAdd fill='#6D31EDFF' /></span> Add</button><br/>
                                <div id='skilladdingsection' ><input type="text" id='skill_input_box' placeholder='Enter Skill here' style={{backgroundColor:"transparent", border:"1px solid lightgray", outline:"none"}}></input><button style={{backgroundColor:"#6D31EDFF", border:"none", color:'white', padding:"0px 2px", marginLeft:"2px"}} onClick={AddSkills}>✔</button></div>

                                {/* ************************************Modal to Add Skills***************************** */}
                                

                                {/* <Modal isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent>
                                    <ModalHeader>Add Professional Skills</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Input placeholder="Enter Skill"  id={'skill_input_box'}/>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button colorScheme='blue' mr={3} onClick={AddSkills}>
                                            Add
                                        </Button>
                                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                                            Close
                                        </Button>
                                    </ModalFooter>
                                    </ModalContent>
                                </Modal> */}

                            </div>
                        </div>
                        <div className='div2_leftside_JobAndPlacementPage1_professionalSkillDiv2'>
                            <img src={img2} style={{width:"100%", height:"100%"}}></img>
                        </div>
                        <div className='div2_leftside_JobAndPlacementPage1_professionalSkillDiv3'>
                                <span style={{cursor:"pointer"}}><CiFileOn fill='#9C7F00FF' style={{width:"35px", height:"50px"}}/></span>
                                <div>
                                    <h4 style={{fontWeight:"bold", fontSize:"12px", color:"#171A1FFF"}}>See your Score Card</h4>
                                    <h4 style={{fontSize:"12px", color:"#6D31EDFF"}}>Kavya Shree  UI/UX.CV</h4>
                                </div>
                        </div>
                    </div>

                    <div className='div2_leftside_JobAndPlacementPage1_descdiv'>
                        <h4 className='div2_leftside_JobAndPlacementPage1_h2'>Description</h4>
                        <textarea className='div2_leftside_JobAndPlacementPage1_desctextarea' placeholder='Enter your description'></textarea>
                        <button className='div1_rightside_checkEligibilityBtn'>Check your Eligibility</button>
                    </div>
                </div>

                <div className='div2_rightside_JobAndPlacementPage1'>
                    <div className='div2_rightside_JobAndPlacementPage1_div1'>
                        <img src={img3} style={{width:"60px", height:"60px", borderRadius: "48px"}}></img>
                        <img src={img4} style={{width:"30px", height:"30px", borderRadius: "48px", position: "relative", top: "-18px"}}></img>
                        <h4 className='div2_rightside_JobAndPlacementPage1_h1'>You have got a feedback from top mentors</h4>
                        <h4 className='div2_rightside_JobAndPlacementPage1_h2'>Learn and take the guidence from the top mentors of tecdemy</h4>
                        <button className='div1_rightside_viewFeedbackBtn'>View Feedback</button>
                    </div>
                    <div className='div2_rightside_JobAndPlacementPage1_div2'>
                        <div className='div2_rightside_JobAndPlacementPage1_cartoondiv'>
                            <img src={img5} style={{width:"100%", height:"100%"}}></img>
                        </div>
                        <div className='div2_rightside_JobAndPlacementPage1_getstartedbtndiv'>
                            <h4 className='div2_rightside_JobAndPlacementPage1_getstarted_h4'>Get career guidence and Tech support from our Mentors/Guru/Trainers</h4>
                            <a href='/PaymentPrice'><center><button className='div1_rightside_getStartedBtn'>Get Started</button></center></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobAndPlacementPage1