import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { CourseUploadSchema } from "../ValidationSchema";
import {
  Box,
  Button,
  Container,
  Input,
  Text,
  Textarea,
  Flex,
  Heading,
  Select,
  InputGroup,
  InputLeftAddon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import ThumbnailUploader from "./ThumbnailUploader";
import Styles from "./courseUploader.module.css";
import { toast } from "react-toastify";

import CourseSection from "./CourseSection";
import endpoints from "../../middleware/endpoint";
import { CallAPI, post } from "../../middleware/api";
import { getID } from "../../siteConfig";
import { decrypt } from "../../middleware/auth";
import { BOLoading } from "../elements/elements";

const CourseUpload = ({ selectedForm, setSelectedForm }) => {
  const [thumbnailFiles, setThumbnailFiles] = useState([]);
  const [image, setImage] = useState("");

  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [sectionDetails, setSectionsDetails] = useState([
    {
      section_title: "",
      courseList: [
        {
          title: "",
          description: "",
          resource_list: [{ title: "", link: "" }],
          external_urls_videos: [{ title: "", link: "" }],
          external_resources: "",
          course_video: "",
        },
      ],
    },
  ]);

  useEffect(() => {
    try {
      if (getID("userData")) {
        const userDetails = JSON.parse(decrypt(getID("userData")));
        setUserDetails(userDetails);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  // const handleChangeThumbnail = async (e) => {
  //   try {
  //     const { files } = e.target;
  //     setImage(files);

  //     const _files = Array.from(files);
  //     // debugger;
  //     // const file1 = files.createobject(_files);
  //     setFiles(_files);
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  // const handleExternalResources = async (sectionIndex, subsectionIndex, e) => {
  //   try {
  //     const newFiles = Array.from(e.target.files).map((file) =>
  //       URL.createObjectURL(file)
  //     );
  //     setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  //   } catch (error) {
  //     toast.error(error.message || "An error occurred while processing files.");
  //     console.error("Error handling external resources: ", error);
  //   }
  // };

  // const handleVideos = async (sectionIndex, subsectionIndex, e) => {
  //   try {
  //     const { files } = e.target;
  //     const _files = Array.from(files);
  //     setSectionsDetails((prevSections) => {
  //       const newSections = [...prevSections];
  //       newSections[sectionIndex].courseList[subsectionIndex].course_video =
  //         _files;
  //       return newSections;
  //     });
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const { values, errors, handleBlur, handleChange, touched } = useFormik({
    initialValues: {
      course_title: "",
      course_type: "",
      level: "",
      actual_price: "",
      discounted_price: "",
      course_description: "",
      thumbnail: "",
    },
    validationSchema: CourseUploadSchema,
    // onSubmit: (values) => {
    //   try {
    //     const courseData = {
    //       ...values,
    //       sectionDetails,
    //       thumbnail: thumbnailUrl,
    //       user_id: userDetails?.user_id,
    //     };
    //     console.log("Submitting course data: ", courseData);
    //     // Here you would send `courseData` to the API
    //   } catch (error) {
    //     console.error("Error submitting course data: ", error);
    //   }
    // },
  });

  const uploadCourseData = (thumbnailUrl, updatedSections) => {
    try {
      const courseData = {
        ...values,
        sectionDetails: updatedSections,
        thumbnail: thumbnailUrl.data.filename,
        user_id: userDetails.id,
      };
      console.log("Uploading course data: ", courseData);
      post(endpoints.uploadCourse, courseData)
        .then((res) => {
          toast.success("Course Added Successfully");
        })
        .catch((error) => {
          toast.error("Failed to Add");
          console.error("Error uploading course data: ", error);
        });
    } catch (error) {
      toast.error("Failed to Add Course");
      console.error("Error in uploadCourseData: ", error);
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);

      const sections = sectionDetails.map(async (section, sectionIndex) => {
        const courseList = section.courseList.map(
          async (subsection, subsectionIndex) => {
            // Upload videos
            const videoFormData = new FormData();
            console.log("video", subsection.course_video);
            videoFormData.append("courseVideos", subsection.course_video[0]);
            // subsection.course_video.forEach((file) => {
            //   videoFormData.append("courseVideos", file);
            // });

            const videoResponse = await CallAPI(
              endpoints.uploadCourseVideos,
              videoFormData
            );

            const VideoUrls = videoResponse[0].filename;
            toast.success("Videos uploaded successfully");

            // Upload external resources
            const resourcesFormData = new FormData();
            subsection.external_resources.forEach((file) => {
              resourcesFormData.append("extResources", file);
            });
            const resourcesResponse = await CallAPI(
              endpoints.uploadExternalResources,
              resourcesFormData
            );
            toast.success("Resources uploaded successfully");

            const resourceUrls =
              resourcesResponse
                ?.map((item) => `${item.fileName}~${item.fileUrl}`)
                .join("|") || "";

            const formattedResourceList = subsection.resource_list
              .map((item) => `${item.title}~${item.link}`)
              .join("|");

            const formattedExternalUrls = subsection.external_urls_videos
              .map((item) => `${item.title}~${item.link}`)
              .join("|");
            const updatedCourseList = {
              ...subsection,
              // course_video: videoResponse.data
              //   .map((item) => item.filename)
              //   .join(","),
              external_resources: resourceUrls,
              course_video: VideoUrls,
              resource_list: formattedResourceList,
              external_urls_videos: formattedExternalUrls,
            };

            return updatedCourseList;
          }
        );

        return {
          ...section,
          courseList: await Promise.all(courseList),
        };
      });

      const updatedSections = await Promise.all(sections);
      // Upload thumbnail
      // const images = Array.from(image);
      const thumbnailFormData = new FormData();
      thumbnailFormData.append("thumbImage", image[0]);
      const thumbnailResponse = await post(
        endpoints.uploadCourseThumbnail,
        thumbnailFormData
      );
      toast.success("Thumbnail uploaded successfully");

      uploadCourseData(thumbnailResponse, updatedSections);
      return;
    } catch (error) {
      toast.error("Failed to upload course data");
      console.error("Error in handleSubmit: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSectionChange = (index, event) => {
    const { name, value } = event.target;
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      newSections[index][name] = value;
      return newSections;
    });
  };

  const handleSubsectionChange = (sectionIndex, subsectionIndex, event) => {
    const { name, value } = event.target;
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].courseList[subsectionIndex][name] = value;
      return newSections;
    });
  };

  const addSection = () => {
    setSectionsDetails((prevSections) => [
      ...prevSections,
      {
        section_title: "",
        courseList: [
          {
            title: "",
            description: "",
            resource_list: [{ title: "", link: "" }],
            external_urls_videos: [{ title: "", link: "" }],
            external_resources: "",
            course_video: "",
          },
        ],
      },
    ]);
  };

  const deleteSection = (index) => {
    setSectionsDetails((prevSections) =>
      prevSections.filter((_, i) => i !== index)
    );
  };

  const addSubsection = (sectionIndex) => {
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].courseList.push({
        title: "",
        description: "",
        resource_list: [{ title: "", link: "" }],
        external_urls_videos: [{ title: "", link: "" }],
        external_resources: "",
        course_video: "",
      });
      return newSections;
    });
  };

  const deleteSubsection = (sectionIndex, subsectionIndex) => {
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].courseList = newSections[
        sectionIndex
      ].courseList.filter((_, i) => i !== subsectionIndex);
      return newSections;
    });
  };

  const addResourceLink = (sectionIndex, subsectionIndex) => {
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].courseList[subsectionIndex].resource_list.push({
        title: "",
        link: "",
      });
      return newSections;
    });
  };

  const deleteResourceLink = (sectionIndex, subsectionIndex, resIndex) => {
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      // const resourceArray =
      newSections[sectionIndex].courseList[
        subsectionIndex
      ].resource_list.splice(resIndex, 1);
      // .split(",");
      // resourceArray.splice(resIndex, 1);
      // newSections[sectionIndex].courseList[subsectionIndex].resource_list =
      //   resourceArray.join(",");
      return newSections;
    });
  };

  // const onResourceTitleChange = (
  //   sectionIndex,
  //   subsectionIndex,
  //   resIndex,
  //   event
  // ) => {
  //   const { value } = event.target;
  //   setSectionsDetails((prevSections) => {
  //     const newSections = [...prevSections];
  //     const resourceArray =
  //       newSections[sectionIndex].courseList[
  //         subsectionIndex
  //       ].resource_list.split(",");
  //     resourceArray[resIndex] = value;
  //     // newSections[sectionIndex].courseList[subsectionIndex].resource_list =
  //       resourceArray.join(",");
  //     return newSections;
  //   });
  // };

  const addExternalUrl = (sectionIndex, subsectionIndex) => {
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      newSections[sectionIndex].courseList[
        subsectionIndex
      ].external_urls_videos.push({
        title: "",
        link: "",
      });
      return newSections;
    });
  };

  const deleteExternalUrl = (sectionIndex, subsectionIndex, urlIndex) => {
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      // const urlArray =
      newSections[sectionIndex].courseList[
        subsectionIndex
      ].external_urls_videos.splice(urlIndex, 1);
      // .split(",");
      // urlArray.splice(urlIndex, 1);
      // newSections[sectionIndex].courseList[
      //   subsectionIndex
      // ].external_urls_videos = urlArray.join(",");
      return newSections;
    });
  };

  const handleResourceLinkChange = (
    sectionIndex,
    subsectionIndex,
    resIndex,
    event
  ) => {
    const { name, value } = event.target;

    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      // const urlArray =
      newSections[sectionIndex].courseList[subsectionIndex].resource_list[
        resIndex
      ][name] = value;
      // .split(",");
      // urlArray[urlIndex] = value;
      // newSections[sectionIndex].courseList[
      //   subsectionIndex
      // ].external_urls_videos = urlArray.join(",");
      return newSections;
    });
  };

  const handleExternalUrlChange = (
    sectionIndex,
    subsectionIndex,
    urlIndex,
    event
  ) => {
    const { name, value } = event.target;
    setSectionsDetails((prevSections) => {
      const newSections = [...prevSections];
      // const urlArray =
      newSections[sectionIndex].courseList[
        subsectionIndex
      ].external_urls_videos[urlIndex][name] = value;
      // .split(",");
      // urlArray[urlIndex] = value;
      // newSections[sectionIndex].courseList[
      //   subsectionIndex
      // ].external_urls_videos = urlArray.join(",");
      return newSections;
    });
  };

  const handleChangeThumbnail = (e) => {
    const _files = Array.from(e.target.files);
    setImage(_files);
    const newImages = _files.map((file) => URL.createObjectURL(file));
    setThumbnailFiles(newImages);
  };

  // useEffect(() => {
  //   console.log("Form values: ", values);
  //   console.log("Section details: ", sectionDetails);
  // }, [values, sectionDetails]);

  const handleDeleteFile = (file) => {
    //add try catch
    console.log("thumbnailFiles", thumbnailFiles);
    setThumbnailFiles(thumbnailFiles.filter((items) => items !== file));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container maxW="100%" className={`${Styles?.mainContainer}`}>
        <Box
          className={Styles?.addProfileFooter}
          textAlign={"left"}
          onClick={() => setSelectedForm(2)}
        >
          <Button type="submit" alignSelf="self-start">
            Back
          </Button>
        </Box>
        <Tabs>
          <TabList>
            <Tab
              _selected={{
                borderBottom: "4px solid #6534e4",
                color: "#6534e4",
                fontWeight: "700",
                borderRadius: "0px",
              }}
              _focus={{ boxShadow: "md" }}
              borderColor="white"
              borderTop="3px solid transparent"
              fontWeight="500"
              marginRight={{ base: "10px", md: "20px" }}
            >
              Course Information
            </Tab>
            <Tab
              _selected={{
                borderBottom: "4px solid #6534e4",
                color: "#6534e4",
                fontWeight: "700",
                borderRadius: "0px",
              }}
              _focus={{ boxShadow: "md" }}
              borderColor="white"
              borderTop="3px solid transparent"
              fontWeight="500"
              marginRight={{ base: "10px", md: "20px" }}
            >
              Section
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box
                className={`${Styles?.addProfileHeader} ${Styles?.AlignLeft}`}
              >
                <Heading as={"h4"}>Course Information</Heading>
              </Box>

              <Box className={Styles?.addProfileBody}>
                <Box
                  className={Styles?.AlignLeft}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text width="100%" display="flex" alignItems="center">
                    Thumbnail
                  </Text>
                  <ThumbnailUploader
                    accept=".jpeg,.png,.jpg"
                    isThumbnail={true}
                    handleChangeUploader={handleChangeThumbnail}
                    name={"thumbnail"}
                    files={thumbnailFiles}
                    setFiles={setThumbnailFiles}
                    handleDeleteFile={handleDeleteFile}
                    // handleChange={(e) => {
                    //   handleChange(e);
                    //   handleChangeThumbnail(e);
                    // }}
                  />
                </Box>
                <Box
                  flex="1"
                  className={`${Styles?.flex} ${Styles?.AlignLeft}`}
                >
                  <Text width="40%" display="flex" alignItems="center">
                    Title
                  </Text>
                  <Input
                    name="course_title"
                    value={values.course_title}
                    placeholder="Title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="70%"
                  />
                </Box>
                {/* <Flex> */}
                <Box
                  flex="1"
                  className={`${Styles?.flex} ${Styles?.AlignLeft}`}
                >
                  <Text width="40%" display="flex" alignItems="center">
                    Course Type
                  </Text>
                  <Select
                    name="course_type"
                    value={values.course_type}
                    placeholder="Select Course Type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="70%"
                  >
                    <option value="online">IT and consultancy</option>
                    <option value="offline">Software Engineering</option>
                    <option value="hybrid">Finance and Business</option>
                    <option value="metaverse">Metaverse</option>
                  </Select>
                </Box>
                <Box
                  flex="1"
                  className={`${Styles?.flex} ${Styles?.AlignLeft}`}
                >
                  <Text width="40%" display="flex" alignItems="center">
                    Level
                  </Text>
                  <Select
                    name="level"
                    value={values.level}
                    placeholder="Level"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="70%"
                  >
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Professional</option>
                  </Select>
                </Box>
                {/* </Flex> */}
                {/* <Flex marginBottom="0px"> */}
                <Box
                  flex="1"
                  className={`${Styles?.flex} ${Styles?.AlignLeft}`}
                >
                  <Text width="40%" display="flex" alignItems="center">
                    Actual Price
                  </Text>
                  <InputGroup marginBottom="0px" width="70%">
                    <InputLeftAddon marginBottom="0px">USD</InputLeftAddon>

                    <Input
                      type="number"
                      name="actual_price"
                      value={values.actual_price}
                      placeholder="Actual Price"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                </Box>
                <Box
                  flex="1"
                  className={`${Styles?.flex} ${Styles?.AlignLeft}`}
                >
                  <Text width="40%" display="flex" alignItems="center">
                    Discounted Price
                  </Text>
                  <InputGroup marginBottom="0px" width="70%">
                    <InputLeftAddon marginBottom="0px">USD</InputLeftAddon>

                    <Input
                      type="number"
                      name="discounted_price"
                      value={values.discounted_price}
                      placeholder="Discounted Price"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>
                </Box>
                {/* </Flex> */}

                <Box className={`${Styles?.flex} ${Styles?.AlignLeft}`}>
                  <Text width="40%" display="flex" alignItems="center">
                    Course Description
                  </Text>
                  <Textarea
                    name="course_description"
                    value={values.course_description}
                    placeholder="Course Description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows="4"
                    width="70%"
                  />
                </Box>
              </Box>
            </TabPanel>
            <TabPanel>
              <CourseSection
                sectionDetails={sectionDetails}
                onSectionChange={handleSectionChange}
                onAddSection={addSection}
                onDeleteSection={deleteSection}
                onSubsectionChange={handleSubsectionChange}
                onAddSubsection={addSubsection}
                onDeleteSubsection={deleteSubsection}
                onAddResourceLink={addResourceLink}
                onDeleteResourceLink={deleteResourceLink}
                onResourceLinkChange={handleResourceLinkChange}
                onAddExternalUrl={addExternalUrl}
                onDeleteExternalUrl={deleteExternalUrl}
                onExternalUrlChange={handleExternalUrlChange}
                setSectionsDetails={setSectionsDetails}
                // onResourceTitleChange={onResourceTitleChange}

                // onExternalResource={handleExternalResources}
                // onhandleChangeVideos={handleVideos}
              />
              <Button type="submit" mt="20px" width="100%">
                {loading ? <BOLoading /> : "Submit"}
              </Button>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </form>
  );
};

export default CourseUpload;
