// ShareComponent.jsx

import React from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const ShareComponent = ({ isOpen, onClose, link }) => {
  const handleShare = (platform) => {
    // Logic to share content on the specified platform
    console.log(`Sharing on ${platform} with link: ${link}`);
    // Add your sharing logic here, such as API calls to share on social media platforms

    // Show notification
    toast.success(`Shared on ${platform}`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Share this link:</p>
        <input type="text" value={link} readOnly style={{ width: "100%" }} />
        <Button variant="primary" onClick={() => handleShare("Facebook")}>
          Share on Facebook
        </Button>
        <Button variant="info" onClick={() => handleShare("Twitter")}>
          Share on Twitter
        </Button>
        <Button variant="success" onClick={() => handleShare("LinkedIn")}>
          Share on LinkedIn
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ShareComponent;
