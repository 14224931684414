import React from 'react'
import giftico from './gift-icon.svg'
import { MDBIcon } from 'mdb-react-ui-kit';
import CardCourse from './CardShop'
import CardImage from '../../img/img4.jpg'
import Currency from './Currency.svg'
import {
    GridItem, Square, Box, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableContainer, Button, Input, Text
} from "@chakra-ui/react"
import ApiData from '../ApiData';
import Card from '../Card';
import {
    MDBCard,
    MDBCardTitle,
    MDBCardText,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol
} from 'mdb-react-ui-kit';
import { StarIcon } from "@chakra-ui/icons"
import { Flex } from 'antd';


const Shoping = () => {

    const [Apply, setApply] = React.useState(false)
    const handleClick = () => setApply(!Apply)

    return (
        <div>
            <GridItem mt="20px"><h1 style={{ fontWeight: "300px", marginLeft: "29px", color: "#6D31EDFF" }}>Shopping Cart</h1></GridItem>
            <Flex color='white'>
                <Box style={{ marginLeft: "30px", width: "900px" }}>
                    <style>
                        {`
            .custom-card::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%; /* Adjust the width as needed */
                width: 2px; /* Adjust the thickness of the line */
                background-color: #000; /* Adjust the color of the line */
                margine-left:"10px"
            }
        `}
                    </style>
                    <MDBCard className="custom-card">
                        <MDBRow className='g-0 w-12'>
                            <MDBCol md='4'>
                                <Box>
                                    <CardCourse
                                        imgsrc={CardImage}
                                    />
                                </Box>
                            </MDBCol>
                            <MDBCol md='8'>
                                <MDBCardBody>
                                    <Flex>
                                        <MDBCardTitle style={{ fontSize: "25px", color: "Black" }}>
                                            Introduction To React JS
                                        </MDBCardTitle>
                                        <Box marginLeft="240px" fontSize="25px" >
                                            <MDBIcon fas icon="heart" style={{ color: "red", marginLeft: "20px" }} />
                                            <MDBIcon far icon="times-circle" style={{ color: "black" }} />
                                        </Box>
                                    </Flex>
                                    <MDBCardText>
                                        This course provides a beginner's introduction to the powerful and ever-more popular React. js JavaScript framework.
                                    </MDBCardText>

                                    <Box as="span" color="gray.600" fontSize="sm">
                                        4.5
                                    </Box>
                                    <Box as="span" ml="2" color="gray.600" fontSize="sm">
                                        {Array(5).fill('').map((_, i) => (
                                            <StarIcon
                                                key={i}
                                                color="yellow.400"
                                            />
                                        ))}
                                    </Box>
                                    <Flex>
                                        <Box as="span" ml="0px">
                                            <img src={giftico} alt="Gift Icon" style={{ width: "24px", height: "24px" }} />
                                        </Box>
                                        <Box as="span" color="yellow.600" fontSize="lg" ml={1}>
                                            Benefits  out of  this course
                                        </Box>
                                    </Flex>
                                    <Box mt="15px">
                                        <Flex><span style={{ background: " #F5F1FEFF", borderRadius: "18px", color: " #6D31EDFF" }}>Mentors Support AnyTime <MDBIcon far icon="user" style={{ color: "blue" }} /></span><span style={{ borderRadius: "18px", background: " #F5F1FEFF", marginLeft: "20px", color: " #6D31EDFF" }}>Interestic Gifts For Learner Achievement <MDBIcon fas icon="gift" style={{ color: "blue" }} /></span></Flex>
                                    </Box>
                                    <Box mt="15px">
                                        <Flex><span style={{ background: " #F5F1FEFF", color: " #6D31EDFF", borderRadius: "18px" }}>Opportunity For InternShip</span><span style={{ background: " #F5F1FEFF", color: " #6D31EDFF", marginLeft: "35px", borderRadius: "18px" }}>Mystery Spin Wheel With Gifts <MDBIcon fas icon="dharmachakra" style={{ color: "blue" }} /></span></Flex>
                                    </Box>
                                </MDBCardBody>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                </Box>

                {/* //Right side Box */}

                <Square bg='white.500' size='300px' height="300px" mt="10px" ml="10px">
                    <Text alignSelf="flex-start" textAlign="start" color="#6D31EDFF" fontSize="23px" fontWeight="700" ml="10px">Order Summary
                        <TableContainer>
                            <Table size='sm'>
                                <Thead>
                                    <Tr>
                                        <Th mt="15px" mr="0px" display="flex" alignItems="start" size="70px" justifyContent="flex-start">
                                            <img src={Currency} alt='' />
                                            <span style={{ fontSize: "23px", textDecoration: "line-through", color: "red" }}>450</span>
                                            <img src={Currency} alt='' />
                                            <span style={{ fontSize: "23px" }}>190/Month</span>
                                        </Th>
                                        <span style={{ color: "red", fontSize: "10px", marginLeft: "24px" }}>After Offer</span>
                                    </Tr>
                                </Thead>
                                <Tbody >
                                    <Tr>
                                        <Button mt="20px" ml="20px" colorScheme='blue'>Checkout and Explore</Button>
                                    </Tr>
                                    <Tr style={{ marginTop: "10px" }}>
                                        <Td marginLeft="40px">Promo / Coupon Codes</Td>
                                    </Tr>

                                </Tbody>
                                <Tfoot>
                                    <Tr >
                                        <Input mt="10px" ml="20px" htmlSize={10} width='auto' placeholder='Enter Coupon' /><Button colorScheme='blue'>Apply</Button>
                                    </Tr>
                                </Tfoot>
                            </Table>
                        </TableContainer>
                    </Text>

                </Square>
            </Flex>

            {/* //footer boxes */}
            <GridItem ><h1 style={{ marginLeft: "30px", color: " #6D31EDFF",fontSize:"30px" }}>Related videos</h1></GridItem>

            <div className="container-fluid px-1 mb-2 mt-2" style={{ marginLeft: "0px", marginRight: "-15px" }}>
                <div className="row">
                    <div className="col-15">
                        <div className="row gy-5" style={{ marginLeft: "8px" }}>
                            {ApiData.map((value, index) => {
                                return (
                                    <Card
                                        key={index}
                                        imgsrc={value.imgsrc}
                                        title={value.title}
                                        profile={value.profile}
                                        name={value.name}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}
export default Shoping;