import React, { useState } from "react";
import Styles from "./courseUploader.module.css";
import { Container, Box, Flex } from "@chakra-ui/react";

const ThumbnailUploader = ({
  name,
  isThumbnail,
  accept,
  onExternalResource,
  onhandleChangeVideos,
  handleChangeUploader,
  files,
  setFiles,
  handleDeleteFile,
  subIndex = null,
}) => {
  // const [files, setFiles] = useState([]);

  // const handleThumbnailChange = (e) => {
  //   const { files } = e.target;
  //   const file = files[0];
  //   const fileUrl = URL.createObjectURL(file);
  //   setFiles([fileUrl]);
  // };

  // const handleFileChange = (e) => {
  //   const newFiles = Array.from(e.target.files).map((file) =>
  //     URL.createObjectURL(file)
  //   );
  //   setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  // };

  return (
    <Container maxW="100%" maxH="100%">
      <Box
        width={isThumbnail ? "60%" : "100%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor="#fafafb"
        borderRadius="10px"
        margin={0}
        cursor="pointer"
        className="dropZoneImages"
      >
        <Flex
          p="3"
          className={Styles?.uploadCertificateList}
          width={"100%"}
          margin={0}
          flexWrap={"wrap"}
        >
          {files?.map((image, index) => (
            <Box
              className={
                isThumbnail ? Styles?.uploadthumbnail : Styles?.uploadDivs
              }
              key={index}
            >
              <img
                src={image}
                style={{ width: "100%" }}
                alt={`upload-${index}`}
              />
              <i
                onClick={() => {
                  handleDeleteFile(image, name);
                }}
                className={`fas fa-xmark ${Styles?.crossButton}`}
              ></i>
            </Box>
          ))}

          {isThumbnail && files?.length > 0 ? (
            ""
          ) : (
            <Box className={Styles?.uploadDiv} flex="1">
              <i className="fas fa-plus"></i>
              <input
                type="file"
                accept={accept}
                multiple={!isThumbnail}
                name={name}
                onChange={handleChangeUploader}
                className={Styles?.inputCertificates}
              />
            </Box>
          )}
        </Flex>
      </Box>
    </Container>
  );
};

export default ThumbnailUploader;
