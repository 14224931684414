import React, { useState } from "react";
import { getUserData } from "../middleware/auth";
import { useNavigate } from "react-router-dom";

const styles = {
  container: {
    textAlign: "center",
    marginTop: "20vh",
  },
  heading: {
    fontSize: "3rem",
    color: "#FF5733",
  },
  paragraph: {
    fontSize: "1.2rem",
    color: "#777",
  },
};

function NotFoundComponent() {
  let [time, setTime] = useState(3);
  const navigate = useNavigate();
  if (getUserData().usertoken) {
    setTimeout(() => {
      window.location.replace("/dashboard");
    }, 3000);
    setInterval(() => {
      setTime(time - 1);
    }, 999);
    return (
      <div style={styles.container}>
        <h1 style={styles.heading}>404 - Not Found</h1>
        <p style={styles.paragraph}>
          The page you are looking for does not exist.
        </p>
        <p style={styles.paragraph}>
          you being redirected to Dashboard in {time}{" "}
        </p>
      </div>
    );
  } else {
    localStorage.clear();
    window.location.replace("/login");
  }
}

export default NotFoundComponent;
