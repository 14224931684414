import { Link } from "react-router-dom"
import "./newstyle.css"
import Arrow from "./arrow";
const Homepage = () => {
  let courselist = [
    {
      img: "assets/images/aws.svg",
      title: "MICROSOFT POWER BI CERTIFICATION TRAINING COURSE",
      review: 4.4,
      view: 51905,
      link: "/Innertechpage"
    },
    {
      img: "assets/images/aws.svg",
      title: "AWS Certification Training Course for Solutions Architect",
      review: 4.4,
      view: 51905,
      link: "/Innertechpage"
    },
    {
      img: "assets/images/aws.svg",
      title: "AWS Certification Training Course for Solutions Architect",
      review: 4.4,
      view: 51905,
      link: "/Innertechpage"
    },
    {
      img: "assets/images/aws.svg",
      title: "AWS Certification Training Course for Solutions Architect",
      review: 4.4,
      view: 51905,
      link: "/Innertechpage"
    },
    {
      img: "assets/images/aws.svg",
      title: "AWS Certification Training Course for Solutions Architect",
      review: 4.4,
      view: 51905,
      link: "/Innertechpage"
    },
    {
      img: "assets/images/aws.svg",
      title: "AWS Certification Training Course for Solutions Architect",
      review: 4.4,
      view: 51905,
      link: "/Innertechpage"
    },

  ]
  return (
    <div>
      <section className="section top-banner">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="banner-titles">
                <h1>Learn without limits</h1>
                <p>Advance your skills and knowledge. Build your<br /> future in the Our courses</p>
                <a href="/usertype" className="main-btn">Get Started <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.2 14.9"><path fill="#4B55A5" d="M0,6.8h16.3L11.6,1l0.8-1l5.7,7c0.2,0.3,0.2,0.7,0,1l-5.7,7l-0.8-1l4.7-5.8H0V6.8z" /></svg></a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <figure><img src="assets/images/banner-img.png" className="img-res"/></figure>
            </div>
          </div>
        </div>
      </section>
      <section className="section threebox">
        <div className="container">
          <div className="row threebox-row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="card banner-box">
                <h2>Become A <strong>Devops Engineer</strong></h2>
                <a href="" className="main-btn">Know More <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.4 9.3"><polygon points="0.8,0 5.4,4.7 0.8,9.3 0,8.5 3.9,4.6 0,0.8 " /></svg></a>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="card banner-box">
                <h2>Become A <strong>Cloud Architect</strong></h2>
                <a href="" className="main-btn">Know More <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.4 9.3"><polygon points="0.8,0 5.4,4.7 0.8,9.3 0,8.5 3.9,4.6 0,0.8 " /></svg></a>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="card banner-box">
                <h2>Become A <strong>Data Scientist</strong></h2>
                <a href="" className="main-btn">Know More <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.4 9.3"><polygon points="0.8,0 5.4,4.7 0.8,9.3 0,8.5 3.9,4.6 0,0.8 " /></svg></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section treandingCourse">
        <div className="container">
          <h2>Trending Courses</h2>
          <div className="wrap">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item"><a href="#tab1" className="nav-link active" data-bs-toggle="tab">All</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab1" data-bs-toggle="tab">Cloud Computing </a></li>
              <li className="nav-item"><a className="nav-link" href="#tab1" data-bs-toggle="tab">DevOps</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab1" data-bs-toggle="tab">BI and Visualization</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab5" data-bs-toggle="tab">Cyber Security</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab1" data-bs-toggle="tab">Data Science</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab1" data-bs-toggle="tab">Programming & Frameworks</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab1" data-bs-toggle="tab">Project Management</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab1" data-bs-toggle="tab">Software Testing</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab10" data-bs-toggle="tab">Big Data</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab11" data-bs-toggle="tab">Artificial Intelligence</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab12" data-bs-toggle="tab">Frontend Development</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab13" data-bs-toggle="tab">Databases</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab14" data-bs-toggle="tab">Robotic Process Automation</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab15" data-bs-toggle="tab">Data Warehousing and ETL</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab16" data-bs-toggle="tab">Digital Marketing</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab17" data-bs-toggle="tab">Operating Systems</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab18" data-bs-toggle="tab">Mobile Development</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab19" data-bs-toggle="tab">Architecture & Design Patterns</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab20" data-bs-toggle="tab">Blockchain</a></li>
              {/* <li className="nav-item"><a className="nav-link" href="#tab3" data-bs-toggle="tab">DevOps</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab4" data-bs-toggle="tab">BI and Visualization</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab5" data-bs-toggle="tab">Cyber Security</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab6" data-bs-toggle="tab">Data Science</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab7" data-bs-toggle="tab">Programming & Frameworks</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab8" data-bs-toggle="tab">Project Management</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab9" data-bs-toggle="tab">Software Testing</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab10" data-bs-toggle="tab">Big Data</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab11" data-bs-toggle="tab">Artificial Intelligence</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab12" data-bs-toggle="tab">Frontend Development</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab13" data-bs-toggle="tab">Databases</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab14" data-bs-toggle="tab">Robotic Process Automation</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab15" data-bs-toggle="tab">Data Warehousing and ETL</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab16" data-bs-toggle="tab">Digital Marketing</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab17" data-bs-toggle="tab">Operating Systems</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab18" data-bs-toggle="tab">Mobile Development</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab19" data-bs-toggle="tab">Architecture & Design Patterns</a></li>
              <li className="nav-item"><a className="nav-link" href="#tab20" data-bs-toggle="tab">Blockchain</a></li> */}
              <li className="nav-item right-header-nav-item tab-nav-item-has-submenu more-2 hiddenli dropdown" data-width="80"><a className="hvr-underline-from-center dropdown-toggle" href="#" data-bs-toggle="dropdown"><img src="assets/images/hamburger-icons.svg" /></a>
                <ul className="tab-nav-submenu dropdown-menu"></ul>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="tab1">
              <div className="panel-collapse">
                <div className="row">

                  {courselist && courselist.map((ival, i) => {
                    return (<>
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4">

                        <a className="card tc-card" href={ival.link}>
                          <div className="card-top">
                            {i == 0 ? <Arrow /> : ""}
                            <img src={ival.img} />
                            <h3>{ival.title}</h3>
                          </div>
                          <div className="card-body">
                            <h3>{ival.title}</h3>
                            <small>Reviews</small>
                            <ul className="star-review">
                              <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                                <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                              </svg></a></li>
                              <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                                <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                              </svg></a></li>
                              <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                                <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                              </svg></a></li>
                              <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                                <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                              </svg></a></li>
                              <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                                <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                              </svg></a></li>
                              <span>{ival.review} ({ival.view})</span>
                            </ul>
                          </div>
                        </a>
                      </div>
                    </>)
                  })}

                  {/* <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                    <div className="card tc-card">
                      <div className="card-top">
                        <img src="assets/images/aws.svg" /> <h3>AWS Certification Training Course for Solutions Architect</h3>
                      </div>
                      <div className="card-body">
                        <h3>AWS Certification Training<br /> Course for Solu..</h3>
                        <small>Reviews</small>
                        <ul className="star-review">
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                          </svg></a></li>
                          <span>4.4 (51905)</span>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                    <div className="card tc-card">
                      <div className="card-top">
                        <img src="assets/images/aws.svg" /> <h3>AWS Certification Training Course for Solutions Architect</h3>
                      </div>
                      <div className="card-body">
                        <h3>AWS Certification Training<br /> Course for Solu..</h3>
                        <small>Reviews</small>
                        <ul className="star-review">
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                          </svg></a></li>
                          <span>4.4 (51905)</span>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                    <div className="card tc-card">
                      <div className="card-top">
                        <img src="assets/images/aws.svg" /> <h3>AWS Certification Training Course for Solutions Architect</h3>
                      </div>
                      <div className="card-body">
                        <h3>AWS Certification Training<br /> Course for Solu..</h3>
                        <small>Reviews</small>
                        <ul className="star-review">
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                          </svg></a></li>
                          <span>4.4 (51905)</span>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                    <div className="card tc-card">
                      <div className="card-top">
                        <img src="assets/images/aws.svg" /> <h3>AWS Certification Training Course for Solutions Architect</h3>
                      </div>
                      <div className="card-body">
                        <h3>AWS Certification Training<br /> Course for Solu..</h3>
                        <small>Reviews</small>
                        <ul className="star-review">
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                          </svg></a></li>
                          <span>4.4 (51905)</span>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                    <div className="card tc-card">
                      <div className="card-top">
                        <img src="assets/images/aws.svg" /> <h3>AWS Certification Training Course for Solutions Architect</h3>
                      </div>
                      <div className="card-body">
                        <h3>AWS Certification Training<br /> Course for Solu..</h3>
                        <small>Reviews</small>
                        <ul className="star-review">
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http:/data-bs-toggletab-pane fade/www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                          </svg></a></li>
                          <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                            <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                          </svg></a></li>
                          <span>4.4 (51905)</span>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="btndiv"><a href="" className="main-btn">Browse all courses</a><span>Explore our wide range of courses</span></div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab2">
              <div className="panel-collapse"></div>
            </div>
            <div className="tab-pane fade" id="tab3">
              <div className="panel-collapse "></div>
            </div>
            <div className="tab-pane fade" id="tab4">
              <div className="panel-collapse "></div>
            </div>
            <div className="tab-pane fade" id="tab5">
              <div className="panel-collapse ">
                <div className="row">
                  <div className="col-12">
                    <div className="card tc-card">
                      <div className="card-body">
                        <h3>Coming soon</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab6">
              <div className="panel-collapse "></div>
            </div>
            <div className="tab-pane fade" id="tab7">
              <div className="panel-collapse "></div>
            </div>
          </div>
        </div>
      </section>
      <section className="section recent-additions grayBg">
        <div className="container">
          <h2>Recent Additions</h2>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <div className="card tc-card">
                <div className="card-top">
                  <img src="assets/images/aws.svg" /> <h3>AWS Certification Training Course for Solutions Architect</h3>
                </div>
                <div className="card-body">
                  <h3>AWS Certification Training<br /> Course for Solu..</h3>
                  <small>Reviews</small>
                  <ul className="star-review">
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                    </svg></a></li>
                    <span>4.4 (51905)</span>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <div className="card tc-card">
                <div className="card-top">
                  <img src="assets/images/aws.svg" /> <h3>AWS Certification Training Course for Solutions Architect</h3>
                </div>
                <div className="card-body">
                  <h3>AWS Certification Training<br /> Course for Solu..</h3>
                  <small>Reviews</small>
                  <ul className="star-review">
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                    </svg></a></li>
                    <span>4.4 (51905)</span>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <div className="card tc-card">
                <div className="card-top">
                  <img src="assets/images/aws.svg" /> <h3>AWS Certification Training Course for Solutions Architect</h3>
                </div>
                <div className="card-body">
                  <h3>AWS Certification Training<br /> Course for Solu..</h3>
                  <small>Reviews</small>
                  <ul className="star-review">
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                    </svg></a></li>
                    <span>4.4 (51905)</span>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <div className="card tc-card">
                <div className="card-top">
                  <img src="assets/images/aws.svg" /> <h3>AWS Certification Training Course for Solutions Architect</h3>
                </div>
                <div className="card-body">
                  <h3>AWS Certification Training<br /> Course for Solu..</h3>
                  <small>Reviews</small>
                  <ul className="star-review">
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                    </svg></a></li>
                    <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                      <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                    </svg></a></li>
                    <span>4.4 (51905)</span>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section course_categories">
        <div className="container">
          <h2>Discover Top Categories</h2>
          <ul className="row cc-list">
            <li className="col-6 col-sm-6 col-md-3 col-lg-2"><a href="" className="cc-box"><img src="assets/images/cloud-computing.svg" /><span>Cloud Computing</span></a></li>
            <li className="col-6 col-sm-6 col-md-3 col-lg-2"><a href="" className="cc-box"><img src="assets/images/devops.svg" /><span>DevOps</span></a></li>
            <li className="col-6 col-sm-6 col-md-3 col-lg-2"><a href="" className="cc-box"><img src="assets/images/bI-and-visualization.svg" /><span>BI and<br /> Visualization</span></a></li>
            <li className="col-6 col-sm-6 col-md-3 col-lg-2"><a href="" className="cc-box"><img src="assets/images/cyber-security.svg" /><span>Cyber Security</span></a></li>
            <li className="col-6 col-sm-6 col-md-3 col-lg-2"><a href="" className="cc-box"><img src="assets/images/data-science.svg" /><span>Data Science</span></a></li>
            <li className="col-6 col-sm-6 col-md-3 col-lg-2"><a href="" className="cc-box"><img src="assets/images/programming-frameworks.svg" /><span>Programming and<br /> Frameworks</span></a></li>
          </ul>
          <div className="btndiv"><a href="" className="viewmorebtn">View More</a></div>
        </div>
      </section>
      <section className="section reviewssec grayBg">
        <div className="container">
          <h2>Reviews</h2>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="card rv-card">
                <div className="userprofile">
                  <img src="assets/images/profile-img.png" />
                  <small><strong>Joe Frixon</strong> Technical Project Manager</small>
                </div>
                <h4>DevOps Certification Training</h4>
                <ul className="star-review">
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                  </svg></a></li>
                </ul>
                <p>I had enrolled for DevOPS certification course, the course content was covering majorit...</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="card rv-card">
                <div className="userprofile">
                  <img src="assets/images/profile-img.png" />
                  <small><strong>Joe Frixon</strong> Technical Project Manager</small>
                </div>
                <h4>DevOps Certification Training</h4>
                <ul className="star-review">
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                  </svg></a></li>
                </ul>
                <p>I had enrolled for DevOPS certification course, the course content was covering majorit...</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <div className="card rv-card">
                <div className="userprofile">
                  <img src="assets/images/profile-img.png" />
                  <small><strong>Joe Frixon</strong> Technical Project Manager</small>
                </div>
                <h4>DevOps Certification Training</h4>
                <ul className="star-review">
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#fd0" />
                  </svg></a></li>
                  <li><a href=""><svg xmlns="http://www.w3.org/2000/svg" width="16.43" height="15.619" viewBox="0 0 16.43 15.619">
                    <path id="Path_14" data-name="Path 14" d="M94.6-10.679c-1.357,1.1-2.706,2.205-4.076,3.288a.653.653,0,0,0-.244.83c.416,1.44.79,2.892,1.156,4.346.068.27.294.61,0,.841s-.555-.083-.794-.234C89.4-2.4,88.15-3.174,86.933-4A.82.82,0,0,0,85.848-4c-1.245.836-2.519,1.631-3.786,2.435-.22.139-.449.409-.728.19s-.078-.518-.015-.764c.375-1.48.753-2.959,1.174-4.425a.653.653,0,0,0-.244-.83c-1.369-1.082-2.719-2.189-4.076-3.288v-.493c1.717-.1,3.433-.237,5.152-.281a.961.961,0,0,0,1.025-.774c.5-1.385,1.043-2.752,1.582-4.122.085-.216.116-.528.416-.557.373-.035.4.324.5.557.554,1.393,1.123,2.781,1.616,4.2a.9.9,0,0,0,.993.694c1.718.06,3.435.185,5.152.285Z" transform="translate(-78.174 16.907)" fill="#DFDFDF" />
                  </svg></a></li>
                </ul>
                <p>I had enrolled for DevOPS certification course, the course content was covering majorit...</p>
              </div>
            </div>
          </div>

          <div className="btndiv"><a href="" className="viewmorebtn">View All Review</a></div>
        </div>
      </section>
      <section className="section bluebg actionSec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              <h2>Enroll, Learn, Grow, Repeat! Get ready to<br />
                achieve your learning goals </h2>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 btndiv">
              <a href="" className="main-btn">View All Courses</a>
            </div>
          </div>
        </div>
      </section>
      <section className="section blogpost grayBg">
        <div className="container">
          <h2>Latest Blog Posts</h2>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <div className="card bp-card">
                <div className="card-top">
                  <img src="assets/images/blog-post-01.png" />
                </div>
                <div className="card-body">
                  <small>August 9th</small>
                  <h3>AWS Certification Training<br /> Course for Solu..</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <div className="card bp-card">
                <div className="card-top">
                  <img src="assets/images/blog-post-01.png" />
                </div>
                <div className="card-body">
                  <small>August 9th</small>
                  <h3>AWS Certification Training<br /> Course for Solu..</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <div className="card bp-card">
                <div className="card-top">
                  <img src="assets/images/blog-post-01.png" />
                </div>
                <div className="card-body">
                  <small>August 9th</small>
                  <h3>AWS Certification Training<br /> Course for Solu..</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <div className="card bp-card">
                <div className="card-top">
                  <img src="assets/images/blog-post-01.png" />
                </div>
                <div className="card-body">
                  <small>August 9th</small>
                  <h3>AWS Certification Training<br /> Course for Solu..</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}


function Layout() {
  return (
    <div>
      <Homepage />
    </div>
  );
}
export default Layout;