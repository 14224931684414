import React from "react";
import DashboardAside from "../layout/DashboardAside";
import DashboardHeader from "../layout/DashboardHeader";

function DashboardLayout({ children }) {
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <DashboardAside />
        <div className="layout-page is-expanded">
          <DashboardHeader></DashboardHeader>
          {children}
        
        </div>
      
      </div>
    </div>
  );
}

export default DashboardLayout;
