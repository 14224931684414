// {
//     "baseURL": "https://elearning.sitecare.org"
// }
import axios from "axios";
import { getUserData } from "../middleware/auth";
import { toast } from "react-toastify";
// Create an Axios instance with a base URL
const axiosInstance = axios.create({
  // baseURL: 'https://alpha.tecdemy.com:9011',
  // baseURL: 'http://34.229.82.103:9011/',
  // baseURL: 'https://alpha.tecdemy.com/',
  baseURL: 'https://apialpha.tecdemy.com/',
  headers: { "x-api-key": getUserData().usertoken }
});

export const get = (url, params) => axiosInstance.get(url, { params });
export const post = (url, data) => {
  return axiosInstance.post(url, data);
};
export const CallAPI = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error(error.message)
  }
};
export const del = (url) => axiosInstance.delete(url);
export const patch = (url, data) => axiosInstance.patch(url, data);
