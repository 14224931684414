import React from "react";
import {
  Box,
  Flex,
  Grid,
  Text,
  VStack,
  List,
  ListItem,
  ListIcon,
  Button,
  HStack,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import ApiData from "../../../components/ApiData";

const BillingBook = () => {
  return (
    <Box>
      <Flex justifyContent="space-between" mb={5} ml={-42}>
        <Text fontFamily="Manrope" fontSize="18" fontWeight="1000" ml={20}>
          Subscriptions Details
        </Text>
      </Flex>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} px={8}>
        {/* First Subscription Box */}
        <Box
        textAlign={"center"}
          py={8}
          px={10}
          bg="white"
          borderRadius="8px"
          cursor="pointer"
          _hover={{ bg: "lightblue", color: "white" }}
          transition="background-color 0.3s ease, color 0.3s ease"
        >
          <Text
            fontWeight="500"
            fontSize="16"
            color="black"
            fontFamily="Manrope"
            _hover={{ transform: "scale(1.1)", color: "white" }}
            transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
          >
            OPTION # 2
          </Text>
          <Text
              fontWeight="700"
              fontSize="20"
              color="black"
              fontFamily="Lexend"
              _hover={{ transform: "scale(1.1)", color: "white" }}
              transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
            >
              Pay tecdemy.com
            </Text>

          <Flex justifyContent="space-around" alignItems="center">
          <Text
              fontSize="40"
              fontWeight="700"
              fontFamily="Lexend"
              color="black"
              _hover={{ transform: "scale(1.1)", color: "white" }}
              transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
            >
              $ 880
            </Text>
            <HStack spacing={2}>
              <Button
                borderRadius="50%"
                fontSize="16"
                fontWeight="400"
                color="white"
                fontFamily="Manrope"
                bg="green"
                h="10"
                _hover={{ transform: "scale(1.1)" }}
                transition="transform 0.2s ease-in-out"
              >
                Active
              </Button>
            </HStack>
          </Flex>
          <Text
            fontWeight="200"
            fontSize="10"
            fontFamily="Lexend"
            color="grey"
            _hover={{ transform: "scale(1.1)", color: "white" }}
            transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
          >
            Pay with 20 USD discount with Coupon Code
          </Text>
          <HStack justifyContent="center" my={4}>
          </HStack>
          <VStack bg="white" py={2} borderBottomRadius="xl" mt={4}>
            <List spacing={2}  px={12}>
              <VStack w="full" pt={0} spacing={4} mr={10}>
                <ListItem
                  cursor="pointer"
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <Text
                    fontSize={16}
                    fontWeight={700}
                    fontFamily="Lexend"
                    color="black"
                    _hover={{ transform: "scale(1.1)", color: "green" }}
                    transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    cursor="pointer"
                  >
                    Fees Details
                  </Text>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Final Fee Payable: 880 USD
                </ListItem>
                <ListItem
                  cursor="pointer"
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Promotional Discount-20 USD
                </ListItem>
                <ListItem
                  cursor="pointer"
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Coupon code TECDEMY20USD
                </ListItem>
                <ListItem
                  cursor="pointer"
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Pay through Tecdemy
                </ListItem>
              </VStack>
            </List>
          </VStack>
        </Box>

        {/* Second Subscription Box */}
        <Box
        textAlign={"center"}
          py={8}
          px={10}
          bg="white"
          borderRadius="8px"
          cursor="pointer"
          _hover={{ bg: "lightblue", color: "white" }}
          transition="background-color 0.3s ease, color 0.3s ease"
        >
          <Text
            fontWeight="500"
            fontSize="16"
            color="black"
            fontFamily="Manrope"
            _hover={{ transform: "scale(1.1)", color: "white" }}
            transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
          >
            OPTION # 1
          </Text>
          
            <Text
              fontWeight="700"
              fontSize="20"
              color="black"
              fontFamily="Lexend"
              _hover={{ transform: "scale(1.1)", color: "white" }}
              transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
            >
              Pay Professor
            </Text>
            <Text
              fontSize="40"
              fontWeight="700"
              fontFamily="Lexend"
              color="black"
              _hover={{ transform: "scale(1.1)", color: "white" }}
              transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
            >
              $ 900
            </Text>
         
          <Text
            fontWeight="200"
            fontSize="10"
            fontFamily="Lexend"
            color="grey"
            _hover={{ transform: "scale(1.1)", color: "white" }}
            transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
          >
            Pay directly to the professor with 60% discount
          </Text>
          <HStack justifyContent="center" my={2}>
            <Button
              outlineColor={"red"}
              fontSize="16"
              fontWeight="700"
              color="black"
              fontFamily="Manrope"
              bg="lightblue"
              w="full"
              h="12"
              _hover={{ bg: "darkblue", color: "white" }}
              transition="background-color 0.3s ease, color 0.3s ease"
            >
              Get Started
            </Button>
          </HStack>
          <VStack bg="white" py={2} borderBottomRadius="xl" mt={4}>
            <List spacing={2} textAlign="center" px={12}>
              <VStack w="full" pt={0} spacing={4}>
              <ListItem
                
                  cursor="pointer"
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <Text
                    fontSize={16}
                    fontWeight={700}
                    fontFamily="Lexend"
                    color="black"
                    _hover={{ transform: "scale(1.1)", color: "green" }}
                    transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    cursor="pointer"
                  >
                    Fees Details
                  </Text>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Final Fee Payable: 900 USD
                </ListItem>
                <ListItem
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Discount: 60%
                </ListItem>
                <ListItem
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  No Coupon Required
                </ListItem>
                <ListItem
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Pay through Professor
                </ListItem>
              </VStack>
            </List>
          </VStack>
        </Box>

        {/* Third Subscription Box */}
        <Box
         textAlign={"center"}
          py={8}
          px={10}
          bg="white"
          borderRadius="8px"
          cursor="pointer"
          _hover={{ bg: "lightblue", color: "white" }}
          transition="background-color 0.3s ease, color 0.3s ease"
        >
          <Text
            fontWeight="500"
            fontSize="16"
            color="black"
            fontFamily="Manrope"
            _hover={{ transform: "scale(1.1)", color: "white" }}
            transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
          >
            OPTION # 3
          </Text>
          
            <Text
              fontWeight="700"
              fontSize="20"
              color="black"
              fontFamily="Lexend"
              _hover={{ transform: "scale(1.1)", color: "white" }}
              transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
            >
              Pay via PayPal
            </Text>
            <Text
              
              fontSize="40"
              fontWeight="700"
              fontFamily="Lexend"
              color="black"
              _hover={{ transform: "scale(1.1)", color: "white" }}
              transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
            >
              $ 850
            </Text>
          <Text
            fontWeight="200"
            fontSize="10"
            fontFamily="Lexend"
            color="grey"
            _hover={{ transform: "scale(1.1)", color: "white" }}
            transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
          >
            Use PayPal for a safe transaction
          </Text>
          <HStack justifyContent="center" my={2}>
            <Button
             outlineColor={"red"}
              fontSize="16"
              fontWeight="700"
              color="black"
              fontFamily="Manrope"
              bg="lightblue"
              w="full"
              h="12"
              _hover={{ bg: "darkblue", color: "white" }}
              transition="background-color 0.3s ease, color 0.3s ease"
            >
              Get Started
            </Button>
          </HStack>
          <VStack bg="white" py={2} borderBottomRadius="xl" mt={4}>
            <List spacing={2} textAlign="center" px={10}>
              <VStack w="full" pt={0} spacing={4}>
              <ListItem
                  cursor="pointer"
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <Text
                    fontSize={16}
                    fontWeight={700}
                    fontFamily="Lexend"
                    color="black"
                    _hover={{ transform: "scale(1.1)", color: "green" }}
                    transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                    cursor="pointer"
                  >
                    Fees Details
                  </Text>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Final Fee Payable: 850 USD
                </ListItem>
                <ListItem
                mr={18}
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Discount: 50%
                </ListItem>
                <ListItem
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  No Coupon Required
                </ListItem>
                <ListItem
                  textAlign={"center"}
                  fontSize={12}
                  fontWeight={700}
                  fontFamily="Manrope"
                  color="black"
                  _hover={{ transform: "scale(1.1)", color: "green" }}
                  transition="transform 0.2s ease-in-out, color 0.2s ease-in-out"
                >
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Pay through PayPal
                </ListItem>
              </VStack>
            </List>
          </VStack>
        </Box>
      </Grid>

      {/* Card for Courses */}

      <Text mt={5} fontFamily="Manrope" fontSize="18" fontWeight="1000" ml={30} transition="transform 0.2s ease-in-out, color 0.2s ease-in-out">
          Recommended Courses
        </Text>
      <Box mt={"10"}>
        <div
          className="container-fluid ms-10"
          style={{ padding: 2, marginTop: "-20px" }}
        >
          <div className="row">
            <div className="col-12" style={{ marginLeft: "25px" }}>
              <div
                className="d-flex flex-row flex-nowrap overflow-auto"
                style={{ padding: 0, margin: 0 }}
              >
                {ApiData.map((value, index) => (
                  <div
                    className="card mx-1"
                    key={index}
                    style={{
                      transition:
                        "transform 0.3s, background-color 0.3s, color 0.3s",
                      padding: "2px",
                      backgroundColor: "white",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      overflow: "hidden",
                      width: "285px", // Set a fixed width for each card
                      flex: "0 0 auto", // Prevent the card from shrinking or growing
                    }}
                  >
                    <img
                      src={value.imgsrc}
                      alt={value.title}
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "auto",
                        display: "block",
                        borderRadius: "8px 8px 0 0"
                        ,transition:"transform 0.2s ease-in-out, color 0.2s ease-in-out"
                      }}
                    />
                    <div className="card-body" style={{ padding: "10px" }}>
                      <div
                        className="card-title"
                        style={{ fontSize: "0.9rem", margin: "6px 0" }}
                      >
                        {value.title}
                      </div>
                      <div
                        className="card-profile"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={value.profile}
                          alt={value.name}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                        <div
                          className="card-name"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {value.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default BillingBook;
